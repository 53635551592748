import React, { useState } from "react";
import style from './familyTree.module.css'
// import knob from "knob";

const FamilyMember = ({ employee, children, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    onClick && onClick(employee);
  };

  return (
    <div className={`family-member ${style.divCon}`} style={{clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)"}}>
      <div onClick={handleClick} ><button className={children?.length>0 ? style.active:style.inactive}>{employee}</button></div>
      {children && isOpen && (
        <ul>
          {children?.map((child) => {
           return <li key={`${child.employeeId}`}><FamilyMember key={`${child.employeeId}`} {...child} /></li>}
          )}
        </ul>
      )}
    </div>
  );
};

const FamilyTree = ({ data }) => {
  return (
    <div className="family-tree">
      <FamilyMember key={data.employeeId} {...data} />
    </div>
  );
};

export default FamilyTree;
