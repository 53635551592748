import React, { useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "../exitedEmployee/ExitedEmployeeList.module.scss";
import { useMutation, useQuery } from "react-query";
import {
  editExitEmployees,
  getAllExitEmployeeReport,
  getAllFnFStatus,
  getCountByFnfStatus,
  getInActiveEmployee,
} from "../../../../Utils/axios/umsApi";
import moment from "moment";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useHistory } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { MdEdit } from "react-icons/md";
import ConfirmPopup from "../viewUser/ConfirmPopup";
import { ToastContainer } from "react-toastify";
import { employeeId, errorToast, months, successToast } from "../../../../Utils/constants";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";
import { path } from "../../../../routes/PathNames";
import TotalCountUMS from "../commonComponent/TotalCountUMS";
import CommonExcelFileDownload from "../commonComponent/commonExcelFileDownload/AllEmployeeExcelFileDownload";
import Select from 'react-select';

const AllExitedEmployee = () => {
  const [showNotes, setShowNotes] = useState(false);
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [editNotes, setEditNotes] = useState("");
  const [empId, setEmpId] = useState(null);
  const [filter, setFilter] = useState({
    currentPage: 1,
    month: "",
    fnfStatusId: "",
    employeeId: ""
  });
  const { data, isLoading, isError, refetch } = useQuery(
    ["getAllExitEmployeeReport", filter?.currentPage, filter?.month, filter?.fnfStatusId, filter?.employeeId],
    () => getAllExitEmployeeReport(10, filter?.currentPage, filter?.month, filter?.fnfStatusId, filter?.employeeId)
  );
  const { data: fnfStatusOptions } = useQuery(
    ["getAllFnFStatus"],
    () => getAllFnFStatus(),
    {}
  );
  const { data: fnfStatusCount } = useQuery(
    ["getCountByFnfStatus"],
    () => getCountByFnfStatus(),
    {}
  );

  const { data: inactiveEmployee } = useQuery(
    ["getInActiveEmployee"],
    () => getInActiveEmployee(),
    {}
  )

  const getInActiveEmployeeList =
    inactiveEmployee?.length > 0
      ? inactiveEmployee?.map((i) => ({
        value: i.id,
        label: i.name,
      }))
      : [];

  const history = useHistory();
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter({ ...filter, currentPage: filter?.currentPage + 1 });
    } else if (status === "prev") {
      setFilter({ ...filter, currentPage: filter?.currentPage - 1 });
    }
  };
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_userDescription, { id });
    }, 0);
  };
  const mutation = useMutation(editExitEmployees, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        successToast(res?.responseData);
      }
      else {
        errorToast(res?.responseData?.message);
      }
      refetch();
    },
    onError: (err) => {
    },
  });

  const handleEdit = () => {
    mutation.mutate({
      changeByEmpId: employeeId,
      empId: empId,
      exitComment: editNotes,
      fnfStatusId: null,
      fnfStatusUpdatedOn: null,
    })
    setShowEditNotes(false)
    setEditNotes("");
  }

  const handleFnFStatus = (selectedOption, row) => {
    const currentDate = new Date();

    mutation.mutate({
      changeByEmpId: employeeId,
      empId: row?.id,
      exitComment: null,
      fnfStatusId: selectedOption?.key ? selectedOption?.key : null,
      fnfStatusUpdatedOn: currentDate
    })
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 150,
      margin: '0 auto'
    }),
    control: base => ({
      ...base,
      minHeight: 30,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the indicator separator
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px',
      width: 150,
      // justifyContent: 'flex-start'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      width: 150, // Set the width for the dropdown options
      backgroundColor: 'white', // Background color based on selection
      color: 'inherit',
      zIndex: '999999999999',
      '&:hover': {
        backgroundColor: '#289e98', // Background color on hover
        color: 'white'
      }
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      borderSize: 2,
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      zIndex: 9999
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '300px', // Set a fixed maximum height for the menuList
      overflowY: 'auto' // Enable vertical scrolling if the options exceed the maxHeight
    }),
    singleValue: (provided, state) => {
      const color = state.data.key === 4 ? '#00cc00' : 'inherit'; // Change color to green if the selected value is 4, otherwise red
      return { ...provided, color };
    }
  }

  return (
    <>
      <ToastContainer />
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.topFlex}>
            <div className={style.title}>
              <div> All Exited Employees</div>
              <TotalCountUMS totalCount={data?.allCount} />
              <CommonExcelFileDownload limit={data?.allCount} nameOfFile='Users' />
            </div>

            <div className={style.dropdownContainer1} style={{ width: '55%' }}>
              <div className={style.dropdownBox}>
                <CommonDropdown
                  color="#289e98"
                  value={
                    getInActiveEmployeeList.find((e) => e.value === filter.employeeId) ?? ""
                  }
                  onChange={(selectedOption) =>
                    setFilter({
                      ...filter,
                      employeeId: selectedOption.value,
                      currentPage: 1,
                    })
                  }
                  options={getInActiveEmployeeList}
                  placeholder="Inactive Employees"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      employeeId: "",
                    })
                  }
                />
              </div>
              <div className={style.dropdownBox}>
                <CommonDropdown
                  color="#289e98"
                  value={
                    fnfStatusOptions?.find((status) => status.key === filter.fnfStatusId) ?? ""
                  }
                  onChange={(selectedOption) =>
                    setFilter({
                      ...filter,
                      fnfStatusId: selectedOption.key,
                      currentPage: 1,
                    })
                  }
                  options={fnfStatusOptions}
                  placeholder="Status"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      fnfStatusId: "",
                    })
                  }
                />
              </div>
              <div className={style.dropdownBox}>
                <CommonDropdown
                  color="#289e98"
                  value={
                    months.find((month) => month.value === filter.month) ?? ""
                  }
                  onChange={(selectedOption) =>
                    setFilter({
                      ...filter,
                      month: selectedOption.value,
                      currentPage: 1,
                    })
                  }
                  options={months}
                  placeholder="Select Month"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      month: "",
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={style.fnf_count_container}>
            {fnfStatusCount?.map(item => (
              <p className={style.empDetails}>{item.label} FnF : <span className={style.countCircle}> {item.count}</span></p>)
            )}
          </div>
          <div className={style.UsersList}>
            <div className={style.th}>
              <p>Emp Code</p>
              <p>Name</p>
              <p>Resignation Date</p>
              <p>Exit Date</p>
              <p>FnF Due Date</p>
              {/* <p>Delay By</p>
              <p>FnF Status</p> */}
              <p>Exit Note</p>
              <p style={{
                fontWeight: '400',
                width: '2rem',
                fontSize: '14px',
                color: '#666666',
                cursor: 'pointer',
              }}></p>
            </div>
            {isLoading && <div style={{ textAlign: "center" }}>Loading...</div>}
            {isError && (
              <div style={{ textAlign: "center" }}>Something Went Wrong</div>
            )}
            {!isLoading && !isError ? (
              data?.employeeResponseModels?.length > 0 ? (
                data?.employeeResponseModels?.map((e, key) => {
                  return (
                    <div className={style.td} key={e.id}>
                      <p onClick={() => handleRedirect(e.empId)}>{e?.code}</p>
                      <p>{e?.name}</p>
                      <p>
                        {e?.resignationDate &&
                          moment(e?.resignationDate).format("DD MMM YY")}
                      </p>
                      <p>
                        {e?.lastWorkingDayDate &&
                          moment(e?.lastWorkingDayDate).format("DD MMM YY")}
                      </p>
                      <p>
                        {e?.fnfDueDate &&
                          moment(e?.fnfDueDate).format("DD MMM YY")}
                      </p>
                      {/* <p>
                        {e?.delayByDays}
                      </p>
                      <p>
                        <Select
                          value={(e?.fnfStatus !== null) ? fnfStatusOptions?.find(option => option.label === e?.fnfStatus) : null}
                          onChange={(option) => { handleFnFStatus(option, e) }}
                          options={fnfStatusOptions}
                          placeholder=""
                          styles={customStyles} // Apply custom styles
                        />
                      </p> */}
                      <p
                        onClick={() => {
                          setShowNotes(true);
                          setNotes(e?.exitComment);
                          setEmpId(e?.empId);
                        }}
                      >
                        {e?.exitComment && (
                          <CgNotes style={{ color: "#289e98", cursor: "pointer" }} />
                        )}
                      </p>
                      <p
                        onClick={() => {
                          setShowEditNotes(true);
                          setEditNotes(e?.exitComment);
                          setEmpId(e?.id);
                        }}
                        style={{
                          fontWeight: '400',
                          width: '2rem',
                          fontSize: '14px',
                          color: '#666666',
                          cursor: 'pointer',
                        }}>
                        <MdEdit style={{ marginLeft: "5px", color: "#239e98" }} />
                      </p>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>No Data</div>
              )
            ) : null}
          </div>


          <ConfirmPopup open={showNotes}>
            <div className={style.confirmPopup}>
              <div>
                <p>{notes}</p>
              </div>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <button
                  className={style.delete}
                  onClick={() => (setShowNotes(false), setNotes(""))}
                >
                  Done
                </button>

              </div>
            </div>
          </ConfirmPopup>
          <ConfirmPopup open={showEditNotes}>
            <div className={style.confirmPopup1}>
              <div>
                <p className={style.modalMainHeading}>Edit Exit Note</p>
                <textarea
                  className={style.textarea}
                  value={editNotes}
                  onChange={(e) => {
                    setEditNotes(e.target.value)
                  }
                  }
                ></textarea>
              </div>
              <div style={{ textAlign: "end", marginTop: "1rem" }}>
                <button
                  className={style.delete_btn}
                  onClick={() => (setShowEditNotes(false), setEditNotes(""))}
                >
                  Cancel
                </button>
                <button
                  className={style.delete}
                  onClick={handleEdit}
                >
                  Done
                </button>

              </div>
            </div>
          </ConfirmPopup>
          <div style={{ marginRight: "29px" }}>
            <Pagination
              pagesCount={data?.allCount / 10}
              onPageChange={handlePageChange}
              currentPage={filter?.currentPage}
              color="#289e98"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllExitedEmployee;
