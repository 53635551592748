import React, { useState, useEffect } from 'react';
import style from './groups.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import SideBar from '../commonComponent/SideBar';
import { useHistory } from 'react-router-dom';
import constants from '../../../Utils/constants';
import axios from 'axios';
import loaderImg from "./../../../assets/loader-green2.gif";
import cookie from 'react-cookies';
import { ToastifyError } from '../../../App';
import { employeeId } from '../../../Utils/constants';

const Groups = () => {
  const url2 = constants.apiBaseUrl2;

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [GroupsList, setgroupList] = useState([]);

  const viewListHandler = (groupId,group,count) => {
    history.push({
      pathname: "/GroupWiseList",
      state: {
        groupId:groupId,
        Groups:group,
        count:count
      },
    });
  };

  const getGroupDashboardList = () => {
    setIsLoaded(true);
    axios
      .get(url2 + `/groupDashboard?empId=${employeeId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: ` ${cookie.load('token')}`,
        },
      })
      .then((res) => {
        if(res.data.responseData?.message){
          ToastifyError(res.data.responseData?.message)
          setIsLoaded(false);
        }else{
          setgroupList(res.data.responseData);
          setIsLoaded(false);
        }
       

      })
      .catch(() => {});
  };

  useEffect(() => {
    getGroupDashboardList();
  }, []);

  return (
    <>
      <div className={style.mainSection}>
        <SideBar />

        <div className={style.rightSection}>
          <div className={style.grpus}> Groups</div>
          <div className={style.taskAssigned}>
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            ""
          )}
            {GroupsList?.length===0 && !isLoaded ? (
              <p className={style.noDataFound} >No Data Available.</p>
            ) : (
              <Container fluid>
                <Row className={style.rowAlign}>
                  {GroupsList?.map((group) => (
                    <Col
                      key={group.groupId}
                      sm={6}
                      md={3}
                      className={style.customCol}
                      onClick={() => viewListHandler(group.groupId,group?.group,group.count)}
                    >
                      <div className={style.droupsData}>
                        {group.group} ({group.count})
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Groups;
