import React, {useEffect, useState} from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./companyHierarchy.module.css";
import { useQuery } from "react-query";
import { getHierarchy} from "../../../../Utils/axios/umsApi";

import { path } from "../../../../routes/PathNames";
import FamilyTree from "./familyTree/familyTree";

const CompanyHierarchy = () => {
  const [reporteeData,setReporteeData]=useState({employee:"InnovationM",
  employeesUnder:[]});
    const { data: hierarchyData, isLoading: hierarchyDataLoading, isError: hierarchyDataError } = useQuery(
        ["getHierarchy"],
        () => getHierarchy(),
        {
            refetchOnWindowFocus: false,
        }
    );
    useEffect(() => {
        setReporteeData({employee:"InnovationM",
    children: hierarchyData})
    }, [hierarchyData]);
    return (
        <div className={style.main_client_body}>
            <SideBar />
            <div className={style.main_create}>
                <div className={style.dashboard_container}>
                    <div>
                        <p className={style.heading}>Reporting Hierarchy</p>
                        <div className={style.count_card_container}>
                        <div>
                       {reporteeData?.children?.length>0 ? <FamilyTree data={reporteeData}/> : 'Loading...'}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CompanyHierarchy;
