import React from "react";
import style from "./Sidebar.module.css";
import PriceRevisionImg from "../../../../assets/pricelistingapplication/Pricing_Image.png";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className={style.main_div}>
      <div className={style.small_div}>
        <div className={style.img_div}>
          <img
            className={style.price_revision_img}
            src={PriceRevisionImg}
            alt="img"
          />
        </div>
        <p className={style.div_text}>Price Revision</p>
      </div>

      <div
        className={
          location?.pathname?.includes("/revision_dues")
            ? style.filled_div
            : style.white_div
        }
        onClick={() => history.push("/revision_dues")}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            history.push("/revision_dues");
          }
        }}
        tabIndex={0}
      >
        <p className={style.div_text2}>Revision Dues</p>
      </div>

      <div
        className={
          location?.pathname?.includes("/price_listing") ||
          location.pathname?.includes("/resource_details")
            ? style.filled_div
            : style.white_div
        }
        onClick={() => history.push("/price_listing")}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            history.push("/price_listing");
          }
        }}
        tabIndex={0}
      >
        <p className={style.div_text2}>Dashboard</p>
      </div>
    </div>
  );
};

export default Sidebar;
