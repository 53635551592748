import React, { useEffect, useRef } from "react";
import style from "./ViewPosition.module.css";
import "./viewposition.css";
import downld from "../../../../assets/dwnldIcon.png";
import "react-responsive-modal/styles.css";
import dropdownIcon from "../../../../assets/downIcon.png";
import { IoMdDownload } from "react-icons/io";
import { Modal as ReactModal } from "react-responsive-modal";
import {
  getPositionById,
  getPositionHistoryDetails,
  deletePositionByPositionId,
  changePositionStatus,
  downloadPositionInformation,
  getInActiveReason,
} from "../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useState } from "react";
import moment from "moment";
import BreadCrum from "../../breadcrums/BreadCrum";
import DatePicker from "react-datepicker";
import ConfirmPopup from "../../../userManegment/pageLayout/viewUser/ConfirmPopup";
import calender from "../../../../assets/calblue.png";
import Loader from "../../../../GenericComponent/Loader/Loader";
import { customStyles } from "../../utils/style";
import Select from "react-select";
import ValidationEmpAccess from "../validationEmpAccess/ValidationEmpAccess";
import { accessCode } from "../../../../Utils/constants";
import { lazyLoad } from "../../../../GenericComponent/lazyLoad/LazyLoadCommon";
import {
  getPositionStatus,
  addPositionStatus,
} from "../../../../Utils/axios/api";
import NoteCandidate from "../createCandidate/NoteCandidate";
import ATSCard from "./../../customTooltip/ATSCard";
import { checkFileType } from "../../utils/ATSCommonLogicFun";
import { employeeId } from "../../../../Utils/constants";
import CommentHistory from "../../commonComponent/CommentNew/CommentHistory/CommentHistory";
import { handleAddComment, getCommentForPosition } from '../../commonComponent/CommentCommon'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const ViewPosition = ({
  setNextScreen,
  prevData,
  setPrevData,
  validateAccess,
}) => {
  const Modal = lazyLoad(() => import('../../commonComponent/Modal/Modal'))
  const [isModalOpen1, setModalOpen1] = useState(false);
  const openModal1 = () => setModalOpen1(true);
  const closeModal1 = () => setModalOpen1(false);
  const { positionId } = useParams();
  const [show, setShow] = useState(false);
  const [myDate, setMyDate] = useState();
  const [active, setActive] = useState(false);
  const [pastHistory, setPastHistory] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDesc, setShowDesc] = useState(false);
  const [descData, setDescData] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [validate, setValidate] = useState(false);
  const [close, setClose] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [myPositionName, setMyPositionName] = useState(null);
  const [myClientName, setMyClientName] = useState(null)
  const [formData, setFormData] = useState({
    note: "",
    positionId: prevData?.positionId || positionId,
  });
  const [fileName, setFileName] = useState("");
  const [showChangesPostion, setShowChangePosition] = useState(false);
  const [statusData, setStatusData] = useState({
    positionId: prevData?.positionId || positionId,
    inActiveDate: null,
    positionStatus: null,
    note: "",
    inactiveReasonId: null,
  });
  const bottomRef = useRef(null);

  const { data, refetch: positionById } = useQuery(["getPositionById"], () =>
    getPositionById(prevData?.positionId || positionId)
  );
  useEffect(() => {
    setFileName(data?.jdurl ? data?.jdurl.split("@")[1] : "");
    setFormData((prev) => ({
      ...prev,
      ...data,
    }));
  }, [data]);
  useEffect(() => {
    showNote && bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [showNote]);

  const { data: getHistory, refetch } = useQuery(
    ["getPositionHistoryDetails", currentPage],
    () => getPositionHistoryDetails(10, currentPage, prevData?.positionId || positionId),
    { refetchOnWindowFocus: false }
  );
  const { data: status } = useQuery(["getPositionStatus"], getPositionStatus);
  const { data: inActiveReasons } = useQuery(
    ["getInActiveReason"],
    getInActiveReason
  );

  const statusOption =
    status?.length > 0
      ? status?.map((e) => {
        return { value: e?.id, label: e?.name };
      })
      : [];
  const inactiveReasonOption =
    inActiveReasons?.length > 0
      ? inActiveReasons?.map((e) => {
        return { value: e?.id, label: e?.name };
      })
      : [];
  const closeOption =
    status?.length > 0
      ? status
        ?.map((e) => {
          return { value: e?.id, label: e?.name };
        })
        .filter(
          (e) => e.label === "Closed By Client" || e.label === "Closed By Us"
        )
      : [];
  const mutation = useMutation(deletePositionByPositionId, {
    onSuccess: (res) => {
      DeleteError(res.message);
      if (res.message) {
        setShow(false);
      } else {
        Success(res);
        setShow(false);
        setTimeout(() => {
          setNextScreen("positionList");
          setPrevData({
            ...prevData,
            sidebar: "positionList",
            page: "positionList",
            page1: "positionList",
            page2: null,
            page3: null,
            active: "positionList",
          });
        }, 1000);
      }
      setDisabled(false);
    },
    onError: () => {
      setDisabled(false);
    },
  });
  const statusMuntation = useMutation(addPositionStatus, {
    onSuccess: (res) => {
      setValidate(false);
      Success(res);
      setShowChangePosition(false);
      setStatusData((prev) => ({
        ...prev,
        inActiveDate: null,
        positionStatus: null,
        remark: "",
      }));
      positionById();
    },
  });

  const downloadPosition = useMutation(downloadPositionInformation, {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        `InnovationM - ${data?.coreSkillName} - Job Description.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  });
  const changeStatus = useMutation(changePositionStatus, {
    onSuccess: (res) => {
      setActive(false);
      setClose(false);
      positionById();
      setStatusData((prev) => ({
        ...prev,
        statusDate: null,
        positionStatus: null,
        remark: "",
      }));
      setNextScreen("positionList");
      setPrevData({
        ...prevData,
        sidebar: "positionList",
        page: "positionList",
        page1: "positionList",
        page2: null,
        page3: null,
        active: "positionList",
      });
      prevData.func(res.changePositionSuccesfully);
      setDisabled(false);
    },
    onError: (error) => {
      setDisabled(false);
    },
  });
  const DeleteError = (res) =>
    toast.error(res, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const Success = (res) =>
    toast.success(res, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          style={{
            paddingLeft: "10px",
            textAlign: "start",
            width: "100%",
          }}
          ref={ref}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          alt="calendar"
          style={{ position: "absolute", right: "5%", top: "13%" }}
        />
      </div>
    );
  });
  const PositionDescripton = (value) => {
    let data = value?.split("\n");
    return data?.map((e) => {
      return (
        <>
          <span className={style.vp_field_value}>{e}</span>
          <br />
        </>
      );
    });
  };
  const inActiveHandle = () => {
    if (compareDates(myDate, data?.positionOpenedOn)) {
      return;
    }
    if (
      statusData?.inActiveDate &&
      statusData?.positionStatus &&
      statusData?.inactiveReasonId
    ) {
      changeStatus.mutate({
        ...statusData,
        status: false,
        employeeId: employeeId,
      });
      setDisabled(true);
      setValidate(false);
    } else {
      setValidate(true);
    }
  };

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt="dropdownIcon"
        />
      </span>
    );
  };
  const handleStatusPosition = () => {
    if (statusData?.inActiveDate && statusData?.positionStatus) {
      statusMuntation.mutate({ ...statusData, status: false });
      setValidate(false);
    } else {
      setValidate(true);
    }
  };

  const checkStatus = (value) => {
    if (data?.status === "In-Active") {
      if (value === "Closed By Client" || value === "Closed By Us") {
        return false;
      }
      if (value === "Hold By Client" || value === "Hold By Us") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function compareDates(date1, date2) {
    // Convert date strings to Moment objects
    const moment1 = moment(date1, "ddd MMM DD YYYY HH:mm:ss Z");
    const moment2 = moment(date2);

    return moment1.isBefore(moment2);
  }
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setNextScreen={setNextScreen}
        setPrevData={setPrevData}
      ></BreadCrum>
      {disabled ? <Loader /> : null}

      <div className={style.vp_main} style={{ paddingBotton: "3rem" }}>
        <div className={style.vp_inner}>
          <div className={style.vp_main_card}>
            <div className={style.vp_header}>
              <p></p>
              <p style={{ fontSize: "1.1rem" }}>Position Information</p>
              <div>
                {ValidationEmpAccess(
                  validateAccess,
                  accessCode?.position_download
                ) && (
                    <IoMdDownload
                      style={{ color: "black", cursor: "pointer", marginRight: "20px" }}
                      onClick={() =>
                        downloadPosition.mutate({
                          description: data?.positionDescription,
                          education: data?.education
                            ?.map((e) => e.educationName)
                            .join(", "),
                          mandatorySkill: data?.mandatorySkill
                            ?.map((e) => e.mandatorySkillName)
                            .join(", "),
                          preferredSkill: data?.preferredSkill
                            ?.map((e) => e.skillName)
                            .join(", "),
                          role: data?.roleName,
                          skill: data?.coreSkillName,
                          yearOfExperience: `${data.minExperience} to ${data.maxExperience}`,
                          location: data.location,
                        })
                      }
                    />

                    // <img
                    //   src={downld}
                    //   alt="dwnld btn"
                    //   className={style.vp_img}
                    //   width="25rem"
                    //   height="25rem"
                    //   onClick={() =>
                    //     downloadPosition.mutate({
                    //       description: data?.positionDescription,
                    //       education: data?.education
                    //         ?.map((e) => e.educationName)
                    //         .join(", "),
                    //       mandatorySkill: data?.mandatorySkill
                    //         ?.map((e) => e.mandatorySkillName)
                    //         .join(", "),
                    //       preferredSkill: data?.preferredSkill
                    //         ?.map((e) => e.skillName)
                    //         .join(", "),
                    //       role: data?.roleName,
                    //       skill: data?.coreSkillName,
                    //       yearOfExperience: `${data.minExperience} to ${data.maxExperience}`,
                    //       location: data.location,
                    //     })
                    //   }
                    // />
                  )}
              </div>
            </div>

            {data && (
              <div className={style.vp_inner_card}>
                {/* row 1 */}
                {data?.status === "In-Active" && (
                  <div className={style.vp_field} style={{ color: "red" }}>
                    <div className={style.vp_inner_field}>
                      <p style={{ color: "red" }}>Position Status</p>
                      <p className={style.vp_field_value}>{data?.status}</p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>In-Active Date</p>
                      <p className={style.vp_field_value}>
                        {moment(data?.positionInActiveOn).format("DD MMM YY")}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>In-Active Reason</p>
                      <p className={style.vp_field_value}>
                        {data?.inActiveReason}
                      </p>
                    </div>
                  </div>
                )}
                {/* row 2 */}
                {data?.status === "Active" && (
                  <div className={style.vp_field}>
                    {/* <div
                      className={style.vp_inner_field}
                      style={{ color: "red" }}
                    >
                      <p>Reason</p>
                      <p className={style.vp_field_value}>
                        {data?.positionStatus}
                      </p>
                    </div> */}
                    <div className={style.vp_inner_field}>
                      <p style={{ color: "black" }}>Position Status</p>
                      <p className={style.vp_field_value}>{data?.status}</p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Position Code</p>
                      <p className={style.vp_field_value}>
                        {data.positionCode}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Years of Experience/Month</p>
                      <p className={style.vp_field_value}>
                        {data.minExperience} to {data.maxExperience}
                      </p>
                    </div>
                  </div>
                )}
                {/* row 2 */}
                {data?.status === "In-Active" && (
                  <div className={style.vp_field}>
                    <div
                      className={style.vp_inner_field}
                      style={{ color: "red" }}
                    >
                      <p>In-Active Status</p>
                      <p className={style.vp_field_value}>
                        {data?.positionStatus}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Position Code</p>
                      <p className={style.vp_field_value}>
                        {data.positionCode}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Years of Experience/Month</p>
                      <p className={style.vp_field_value}>
                        {data.minExperience} to {data.maxExperience}
                      </p>
                    </div>
                  </div>
                )}

                {
                  <div className={style.vp_field}>
                    <div className={style.vp_inner_field}>
                      <p>Core Skill</p>
                      <p className={style.vp_field_value}>
                        {data.coreSkillName}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Role</p>
                      <p className={style.vp_field_value}>
                        {data?.roleName ?? "N/A"}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Education</p>
                      <p className={style.vp_field_value}>
                        {data?.education?.length > 0
                          ? data?.education
                            ?.map((y) => {
                              return y.educationName;
                            })
                            .join(", ")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                }
                {
                  <div className={style.vp_field}>
                    <div className={style.vp_inner_field}>
                      <p>Budget (LPA)</p>
                      <p className={style.vp_field_value}>
                        {data.minBudget}
                        &nbsp; to &nbsp;
                        {data.maxBudget}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Mandatory Skills</p>
                      <p className={style.vp_field_value}>
                        {data?.mandatorySkill
                          ?.map((y) => {
                            return y.mandatorySkillName;
                          })
                          .join(", ")}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Preferred Skills</p>
                      <p className={style.vp_field_value}>
                        {data?.preferredSkill?.length > 0
                          ? data?.preferredSkill
                            ?.map((y) => {
                              return y.skillName;
                            })
                            .join(", ")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                }
                {
                  <div className={style.vp_field}>
                    <div className={style.vp_inner_field}>
                      <p>Number of Position</p>
                      <p className={style.vp_field_value}>
                        {data?.noOfPositions}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Position Type</p>
                      <p className={style.vp_field_value}>
                        {data?.positionType}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}>
                      <p>Client Name</p>
                      <p className={style.vp_field_value}>
                        {data?.clientName ? data?.clientName : "N/A"}
                      </p>
                    </div>
                  </div>
                }
                <div className={style.vp_field}>
                  <div className={style.vp_inner_field}>
                    <p>Position Title</p>
                    <p className={style.vp_field_value}>
                      {data?.positionTitle}
                    </p>
                  </div>
                  <div className={style.vp_inner_field}>
                    <p>Position Owner</p>
                    <p className={style.vp_field_value}>
                    {data?.positionOwner}
                    </p>
                  </div>
                  <div className={style.vp_inner_field}>
                    <p>Position Opened On</p>
                    <p className={style.vp_field_value}>{data?.positionOpenedOn &&
                        moment(data?.positionOpenedOn).format("DD MMM YYYY")}</p>
                  </div>
                </div>
                <div className={style.vp_field}>
                  <div className={style.vp_inner_field}>
                    <p>Priority</p>
                    <p className={style.vp_field_value}>{data.priority}</p>
                  </div>
                  <div className={style.vp_inner_field}>
                    <p>Work Mode</p>
                    <p className={style.vp_field_value}>
                      {data.workMode}
                    </p>
                  </div>
                  <div className={style.vp_inner_field}>
                    <p>Employment Mode</p>
                    <p className={style.vp_field_value}>
                      {data?.employmentModeId === 2 && data.employmentMode}
                    </p>
                  </div>
                </div>
                <div className={style.vp_field}>
                  <div className={style.vp_inner_field}>
                    <p>Project Duration</p>
                    <p className={style.vp_field_value}>{data.projectDuration}</p>
                  </div>
                  <div className={style.vp_inner_field}>
                    <p>Location</p>
                    <p className={style.vp_field_value}>{data.location}</p>
                  </div>
                  <div className={style.vp_inner_field}>
                  <p>Working Shift</p>
                  <p className={style.vp_field_value}>{data?.shift}</p>
                  </div>
                </div>

                <div className={style.vp_field}>
                  <div className={style.vp_inner_field}>
                    <p>Evaluation Process</p>
                    <p className={style.vp_field_value}>{data.evaluation}</p>
                  </div>
                  <div className={style.vp_inner_field}>
                    <p>Working Days</p>
                    <p className={style.vp_field_value}>{data.workDays}</p>
                  </div>
                  <div style={{overflowWrap: "break-word"}} className={style.vp_inner_field}>
                    <p>Office Address</p>
                    <p className={style.vp_field_value}>{data?.officeAddress}</p>
                  </div>
                </div>

                {data?.status === "In-Active" && (
                  <div className={style.vp_field}>
                    <div className={style.vp_inner_field}>
                      <p>Remarks</p>
                      <p className={style.vp_field_value}>
                        {data?.statusRemark}
                      </p>
                    </div>
                    <div className={style.vp_inner_field}></div>
                    <div className={style.vp_inner_field}></div>
                  </div>
                )}

                <div className={style.vp_field}>
                  {data?.jdurl && (
                    <div className={style.vp_inner_field}>
                      <p style={{ color: "black", fontWeight: "600" }}>
                        JD Uploaded
                      </p>
                      <div className={style.Cu_resume}>
                        {data?.jdurl ? (
                          <p className={style.rmpdf}>
                            <a
                              href={data?.jdurl}
                              target="_blank"
                              style={{ color: "green" }}
                              rel="noopener noreferrer"
                            >
                              {fileName
                                ? checkFileType(fileName?.split(".")[1])
                                : "No file selected"}
                            </a>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div></div>
                </div>
                <div className={style.vp_field1}>
                  <div className={style.vp_full_width}>
                    <p style={{ color: "black", fontWeight: "600" }}>
                      Description of Position
                    </p>
                    {PositionDescripton(data?.positionDescription)}
                  </div>
                </div>
                <div className={style.vp_field1}>
                  <div className={style.vp_inner_field}>
                    <p style={{ color: "black", fontWeight: "600" }}>Notes</p>
                    {PositionDescripton(data?.note)}
                  </div>
                </div>
              </div>
            )}
          </div>
          <ATSCard>
            <div className={style.vp_btn_last1}>
              <div className={style.left_btn}>
                <div>
                  {ValidationEmpAccess(
                    validateAccess,
                    accessCode?.jd_share
                  ) && (
                      <button
                        className={style.vp_addNotes}
                        onClick={() => {
                          setNextScreen("JDShare");
                          setPrevData((prevData) => {
                            const pages = [
                              prevData.page1,
                              prevData.page2,
                              prevData.page3,
                              prevData.page4,
                              prevData.page5,
                              prevData.page6,
                            ].filter(page => page !== null);
                            const newPageKey = `page${pages.length + 1}`;
                            return {
                              ...prevData,
                              jdSharePositionId: data?.id,
                              sidebar: prevData?.sidebar,
                              jdPositionCode: data.positionCode,
                              jdCoreSkillName: data.coreSkillName,
                              [newPageKey]: "JDShare",
                              active: "JDShare",
                            };
                          })
                          window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth",
                          });
                        }}
                      >
                        JD Share
                      </button>
                    )}
                </div>
              </div>
              <div className={style.right_btn}>
                <div>
                  {ValidationEmpAccess(
                    validateAccess,
                    accessCode?.add_notes
                  ) && (
                      <button
                        className={style.vp_addComments}
                        onClick={() => {
                          openModal1()
                          setShowCommentModal(true);
                          setMyPositionName(data.positionCode)
                          // setMyClientName(clientName)
                        }}
                      >
                        Add Comment
                      </button>
                    )}
                </div>
                <div>
                  {ValidationEmpAccess(
                    validateAccess,
                    accessCode?.add_notes
                  ) && (
                      <button
                        className={style.vp_addNotes}
                        onClick={() => {
                          setShowNote(!showNote);
                          window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Add Notes
                      </button>
                    )}
                </div>
                <div>
                  {ValidationEmpAccess(validateAccess, accessCode?.delete) && (
                    <button
                      className={style.vp_cancel}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div>
                  {ValidationEmpAccess(validateAccess, accessCode?.edit) && (
                    <button
                      className={style.vp_edit}
                      onClick={() => {
                        setNextScreen("editPosition");
                        setPrevData((prevData) => ({
                          ...prevData,
                          page1: "positionList",
                          page2: "viewPosition",
                          page3: "editPosition",
                          active: "editPosition",
                        }));
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div>
                  {ValidationEmpAccess(
                    validateAccess,
                    accessCode?.allocate
                  ) && (
                      <button
                        className={style.vp_allocation}
                        onClick={() => {
                          setNextScreen("Allocation");
                          setPrevData({
                            ...prevData,
                            positionData: {
                              positionCode: data?.positionCode,
                              coreSkillName: data?.coreSkillName,
                              roleName: data?.roleName,
                            },
                            positionId: data.id,
                            sidebar: "CurrentAllocation",
                            page1: "positionList",
                            page2: "viewPosition",

                            page3: data?.positionCode + "-" + data?.coreSkillName,
                            active:
                              data?.positionCode + "-" + data?.coreSkillName,
                          });
                        }}
                      >
                        Allocate
                      </button>
                    )}
                </div>
                <div>
                  {ValidationEmpAccess(
                    validateAccess,
                    accessCode?.view_change_history
                  ) && (
                      <button
                        className={style.vp_history}
                        onClick={() => {
                          refetch();
                          setPastHistory(true);
                        }}
                      >
                        View Change History
                      </button>
                    )}
                </div>
                <div>
                  {ValidationEmpAccess(
                    validateAccess,
                    accessCode?.mark_as_inactive
                  ) && (
                      <div>
                        {data?.status === "In-Active" ? (
                          <button
                            className={style.vp_inactive}
                            onClick={() => setActive(true)}
                          >
                            Mark as Active
                          </button>
                        ) : (
                          <button
                            className={style.vp_inactive}
                            onClick={() => setActive(true)}
                          >
                            Mark as In-Active
                          </button>
                        )}
                      </div>
                    )}
                </div>
                {checkStatus(data?.positionStatus) && (
                  <div>
                    {" "}
                    <button
                      className={style.close}
                      onClick={() => setClose(true)}
                    >
                      Mark as Close
                    </button>
                  </div>
                )}
              </div>
            </div>
          </ATSCard>
          {/* Add Notes */}
          <div ref={bottomRef}>
            {showNote && (
              <NoteCandidate
                setShowNote={setShowNote}
                showNote={showNote}
                setFormData={setFormData}
                formData={formData}
                showUpdate={false}
                showNotePosition={true}
                refetchData={positionById}
              />
            )}
          </div>
          {/*Change Position Status*/}
          {data && (
            <ConfirmPopup open={showChangesPostion}>
              <div
                className={style.confirmPopup}
                style={{ paddingTop: "1rem" }}
              >
                <div>
                  <p
                    className={style.heading}
                    style={{ paddingBottom: "0.3rem" }}
                  >
                    Date
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.csd}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      onChange={(date) => {
                        setStatusData((prev) => ({
                          ...prev,
                          statusDate: date,
                        }));
                      }}
                      selected={
                        statusData?.statusDate
                          ? moment(statusData?.statusDate).toDate()
                          : null
                      }
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <p
                    className={
                      validate && !statusData?.statusDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Date
                  </p>
                  <p
                    className={style.heading}
                    style={{ paddingBottom: "0.3rem" }}
                  >
                    Status <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.statuss}>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setStatusData((prev) => ({
                          ...prev,
                          positionStatus: e?.value,
                        }));
                      }}
                      options={statusOption}
                    />
                  </div>
                  <p
                    className={
                      validate && !statusData?.positionStatus
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Status
                  </p>
                  <p
                    className={style.heading}
                    style={{ paddingBottom: "0.3rem" }}
                  >
                    Remarks
                  </p>
                  <div className={style.textArea}>
                    <textarea
                      style={{ outline: "none" }}
                      value={statusData?.remark}
                      onChange={(e) =>
                        setStatusData((prev) => ({
                          ...prev,
                          remark: e?.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className={style.M_button} style={{ marginTop: "1rem" }}>
                    <div className={style.button}>
                      <button
                        className={style.w_inactive}
                        onClick={() => {
                          setShowChangePosition(false);
                          setStatusData((prev) => ({
                            ...prev,
                            statusDate: null,
                            positionStatus: null,
                            remark: "",
                          }));
                        }}
                      >
                        No
                      </button>

                      <button
                        className={style.b_inactive}
                        onClick={() => handleStatusPosition()}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ConfirmPopup>
          )}
          {/*Delete Position*/}
          <ReactModal
            open={show}
            center
            onClose={() => setShow(false)}
            showCloseIcon={false}
            classNames={{
              modal: "reactmodal",
            }}
          >
            <div className={style.modal_container}>
              <div>
                <p className={style.textM}>
                  Do you want to delete this position?
                </p>
              </div>
              <div className={style.M_button}>
                <div className={style.button}>
                  <button
                    className={style.w_inactive}
                    onClick={() => setShow(false)}
                  >
                    No
                  </button>
                  <button
                    className={style.b_inactive}
                    onClick={() => {
                      mutation.mutate(prevData?.positionId || positionId);
                      setDisabled(true);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
          {/*Mark as Inactive and Active*/}
          {data && (
            <ConfirmPopup open={active}>
              <div className={style.confirmPopup}>
                <h5 className={style.confirmPopupTitle1}>
                  {data?.status === "In-Active"
                    ? "Do you want to mark this position as Active? "
                    : "Do you want to mark this position as Inactive?"}
                </h5>
                {data?.status === "In-Active" ? null : (
                  <div>
                    <p
                      className={style.heading}
                      style={{ paddingBottom: "0.3rem" }}
                    >
                      Date
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.csd}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        onChange={(date) => {
                          setMyDate(date);
                          setStatusData((prev) => ({
                            ...prev,
                            inActiveDate: date,
                          }));
                        }}
                        selected={
                          statusData?.inActiveDate
                            ? moment(statusData?.inActiveDate).toDate()
                            : null
                        }
                        customInput={<CustomInputClg />}
                        style={{ backgroundColor: "#F5F6FA" }}
                      />
                    </div>
                    <p
                      className={
                        validate && !statusData?.inActiveDate
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Date
                    </p>
                    {compareDates(myDate, data?.positionOpenedOn) ? (
                      <p className={style.errorText}>
                        Date should not be less than{" "}
                        {moment(data?.positionOpenedOn).format("DD MMM YY")}
                      </p>
                    ) : (
                      <p></p>
                    )}

                    {data?.status !== "In-Active" && (
                      <>
                        <p
                          className={style.heading}
                          style={{ paddingBottom: "0.3rem" }}
                        >
                          In-Active Reason{" "}
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <div className={style.statuss}>
                          <Select
                            styles={customStyles}
                            classNamePrefix={"create_position"}
                            components={{
                              DropdownIndicator: () => CustomInput(),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e) => {
                              setStatusData((prev) => ({
                                ...prev,
                                inactiveReasonId: e?.value,
                              }));
                            }}
                            options={inactiveReasonOption}
                          />
                        </div>
                        <p
                          className={
                            validate && !statusData?.inactiveReasonId
                              ? style.errorText
                              : style.errorTextHidden
                          }
                        >
                          Please Select In-Active Reason
                        </p>
                      </>
                    )}
                    <p
                      className={style.heading}
                      style={{ paddingBottom: "0.3rem" }}
                    >
                      Status <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.statuss}>
                      <Select
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          setStatusData((prev) => ({
                            ...prev,
                            positionStatus: e?.value,
                          }));
                        }}
                        options={statusOption}
                      />
                    </div>
                    <p
                      className={
                        validate && !statusData?.positionStatus
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Status
                    </p>
                    <p
                      className={style.heading}
                      style={{ paddingBottom: "0.3rem" }}
                    >
                      Remarks
                    </p>
                    <div className={style.textArea}>
                      <textarea
                        style={{ outline: "none" }}
                        value={statusData?.note}
                        onChange={(e) =>
                          setStatusData((prev) => ({
                            ...prev,
                            note: e?.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                )}

                <div className={style.M_button}>
                  <div className={style.button}>
                    <button
                      className={style.w_inactive}
                      onClick={() => {
                        setActive(false);
                        setStatusData((prev) => ({
                          ...prev,
                          statusDate: null,
                          positionStatus: null,
                          remark: "",
                        }));
                      }}
                    >
                      No
                    </button>
                    {data?.status === "In-Active" ? (
                      <button
                        className={style.b_inactive}
                        onClick={() => {
                          if (compareDates(myDate, data?.positionOpenedOn)) {
                            return;
                          }
                          changeStatus.mutate({
                            ...statusData,
                            status: true,
                            employeeId: employeeId,
                          });
                          setDisabled(true);
                        }}
                      >
                        Yes
                      </button>
                    ) : (
                      <button
                        className={style.b_inactive}
                        // onClick={() => {
                        //   changeStatus.mutate({
                        //     positionId: prevData.positionId,
                        //     status: false,
                        //     inActiveDate: date,
                        //   });
                        //   setDisabled(true);
                        // }}
                        onClick={() => inActiveHandle()}
                      >
                        Yes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </ConfirmPopup>
          )}
          {/*Mark as close */}
          {data && (
            <ConfirmPopup open={close}>
              <div className={style.confirmPopup}>
                <h5 className={style.confirmPopupTitle1}>
                  {data?.status === "In-Active"
                    ? "Do you want to mark this position as Close? "
                    : null}
                </h5>
                {data?.status === "In-Active" ? (
                  <div>
                    <p
                      className={style.heading}
                      style={{ paddingBottom: "0.3rem" }}
                    >
                      Date
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.csd}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        onChange={(date) => {
                          setStatusData((prev) => ({
                            ...prev,
                            inActiveDate: date,
                          }));
                        }}
                        selected={
                          statusData?.inActiveDate
                            ? moment(statusData?.inActiveDate).toDate()
                            : null
                        }
                        customInput={<CustomInputClg />}
                        style={{ backgroundColor: "#F5F6FA" }}
                      />
                    </div>
                    <p
                      className={
                        validate && !statusData?.inActiveDate
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Date
                    </p>
                    <p
                      className={style.heading}
                      style={{ paddingBottom: "0.3rem" }}
                    >
                      Status <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.statuss}>
                      <Select
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          setStatusData((prev) => ({
                            ...prev,
                            positionStatus: e?.value,
                          }));
                        }}
                        options={closeOption}
                      />
                    </div>
                    <p
                      className={
                        validate && !statusData?.positionStatus
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Status
                    </p>
                    <p
                      className={style.heading}
                      style={{ paddingBottom: "0.3rem" }}
                    >
                      Remarks
                    </p>
                    <div className={style.textArea}>
                      <textarea
                        style={{ outline: "none" }}
                        value={statusData?.note}
                        onChange={(e) =>
                          setStatusData((prev) => ({
                            ...prev,
                            note: e?.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                ) : null}

                <div className={style.M_button}>
                  <div className={style.button}>
                    <button
                      className={style.w_inactive}
                      onClick={() => {
                        setClose(false);
                        setStatusData((prev) => ({
                          ...prev,
                          statusDate: null,
                          positionStatus: null,
                          remark: "",
                        }));
                      }}
                    >
                      No
                    </button>

                    <button
                      className={style.b_inactive}
                      // onClick={() => {
                      //   changeStatus.mutate({
                      //     positionId: prevData.positionId,
                      //     status: false,
                      //     inActiveDate: date,
                      //   });
                      //   setDisabled(true);
                      // }}
                      onClick={() => inActiveHandle()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </ConfirmPopup>
          )}
          {/* history */}
          <div className={style.history}>
            {/* table */}
            {pastHistory ? (
              <>
                <div className={style.PositionList}>
                  <div className={style.vp_header_history}>
                    <p>History</p>
                  </div>

                  <div className={style.table}>
                    {getHistory && getHistory?.getAllData?.length > 0 ? (
                      <div className={style.th}>
                        <p className={style.tableContent}> Date Modified</p>
                        <p className={style.tableContent}>Updated By</p>
                        <p className={style.tableContent}>Fields</p>
                        <p className={style.tableContent}>Changes</p>
                      </div>
                    ) : (
                      <div className={style.NoData}>No Data</div>
                    )}
                    <div>
                      {getHistory?.getAllData?.map((item, index) => {
                        return (
                          <div className={style.td}>
                            <p
                              className={style.tableContent}
                              style={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              {moment(item?.modifiedDate).format("DD MMM YY")}
                            </p>
                            <p
                              className={style.tableContent}
                              style={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              {item?.changeBy}
                            </p>
                            <p
                              className={style.tableContent}
                              style={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              {item?.fields}
                            </p>

                            <p
                              className={style.tableContent}
                              style={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              {item?.nextValue?.length > 60 ||
                                item?.previousValue?.length > 60 ? (
                                <span
                                  className={style.Modaldata}
                                  onClick={() => {
                                    setShowDesc(true);
                                    setDescData(
                                      item?.previousValue +
                                      "-->" +
                                      item?.nextValue
                                    );
                                  }}
                                >
                                  {`${item?.nextValue} -->${item?.previousValue}`.substr(
                                    0,
                                    60
                                  ) + "..."}
                                </span>
                              ) : (
                                <span className={style.data}>
                                  {item?.previousValue} {"-->"}
                                  {item?.nextValue}
                                </span>
                              )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className={style.pagination}>

                  <Pagination
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    pagesCount={getHistory?.positionCount / 10}
                    color="#65A15E"
                    color2="white"
                  />
                </div>
              </>
            ) : null}

            <Modal
              open={showDesc}
              center
              showCloseIcon={false}
              onClose={() => setShowDesc(false)}
              className={style.modal}
              style={{ borderRedius: "10px" }}
            >
              <div className={style.descContainer}>
                <div>{PositionDescripton(descData.split("-->")[0])}</div>
                <div>
                  -------------------------------------------------------------------
                  --------------------------------------------------
                </div>
                <div>{PositionDescripton(descData.split("-->")[1])}</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className={style.vp_desc}
                    onClick={() => setShowDesc(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal>
            {/* Add comment */}
            <Modal isOpen={isModalOpen1} onClose={closeModal1}>
              <div className={style.modal_container_watchlist2}>
                <div className={style.watchlist_inner_container}>
                  <CommentHistory
                    candidateId={prevData?.candidateId}
                    closeModal={closeModal1}
                    setShowCommentModal={setShowCommentModal}
                    positionId={prevData?.positionId || positionId}
                    positionName={myPositionName}
                    myClientName={prevData?.clientName}
                    handleAddComment={handleAddComment}
                    getCommentForPosition={getCommentForPosition}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewPosition;
