import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import BreadCrum from "../../breadcrums/BreadCrum";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import "react-responsive-modal/styles.css";
import style from "./EmployeeFeedbackList.module.css";
import Tooltip from "react-simple-tooltip";
import { useMutation, useQuery } from "react-query";
import {
  getEmployeePerformanceByUserId,
  getEmployeeDetailsByEmployeeId,
  getFeedbackCountOfEmployee,
  addNote,
  deleteNote,
  getAllNotesOfAnEmployee,
} from "../../utils/api";
import { Table } from "react-bootstrap";
import Note from "../../note/Note";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import { employeeId } from "../../../../Utils/constants";
import NotesTable from "../../notesTable/NotesTable";
import Pagination from "../../pagination/Pagination";


function EmployeeFeedbackList({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
  isReviewActive,
}) {
  const [show2, setShow2] = useState(false);
  const [table, setTable] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [notesPage, setNotesPage] = useState(1);
  const [isNote, setIsNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [noteData, setNoteData] = useState({
    userId: "",
    note: "",
    noteByEmpId: employeeId,
    noteId: null
  })
  const location = useLocation();
  let foreignState = location.state;
  const { data, isError, isLoading } = useQuery(
    ["getEmployeePerformanceByUserId"],
    () => getEmployeePerformanceByUserId(prevData?.empId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );
  const { data: profileData } = useQuery(
    ["  getEmployeeDetailsByEmployeeId"],
    () => getEmployeeDetailsByEmployeeId(prevData?.empId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );
  const { data: ReviewCasesNotes, refetch: refetchNotes } = useQuery(["getAllNotesOfAnEmployee",notesPage], () =>
    getAllNotesOfAnEmployee(prevData?.empId, notesPage)
  );


  const { data: feedbackCount } = useQuery(
    ["  getFeedbackCountOfEmployee"],
    () => getFeedbackCountOfEmployee(prevData?.empId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );
  const addNoteMutation = useMutation(addNote, {
    onSuccess: (data) => {
      notifySuccess(data);
      setIsNote(false);
      refetchNotes();
      setNoteData({
        userId: profileData.empId,
        note: "",
        noteByEmpId: employeeId,
        noteId: null
      })
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "auto", // Add smooth scrolling behavior if you prefer
      });
    },
    onError: (error) => {
      notifyError("Error adding note.", error);
    },
  });
  const deleteNoteMutation = useMutation(deleteNote, {
    onSuccess: (data) => {
      notifySuccess(data);
      refetchNotes();
    },
    onError: (error) => {
      notifyError("Error deleting note.", error);
    },
  });
  const handleAddNote = () => {
    addNoteMutation.mutate(noteData);
  }
  const handleNoteEdit = (editNote) => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    setIsNote(true);
    setIsEditNote(true);
    setNoteData({
      employeeId: profileData.empId,
      note: editNote.text,
      noteByEmpId: employeeId,
      noteId: editNote.noteId,
    })
  }
  const handleNoteDelete = (deleteNote) => {
    deleteNoteMutation.mutate(deleteNote);
  }
  const handlePageChange = (page, status) => {
    if (status == "next") {
      setNotesPage(page + 1);
    } else if (status == "prev") {
      setNotesPage(page - 1);
    }
  };
  useEffect(()=>{
    if(location.state){
      location.state = null;
    }
  },[])
  useEffect(() => {
    if (profileData) {
      setNoteData({
        ...noteData,
        userId: profileData.empId,
      })
    }
  }, [profileData])

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        PlusComponent={
          prevData.page === "CurrentAllocation" ? null : (
            <Tooltip
              customCss={`
                          white-space: nowrap;
                          // position: absolute;
                        `}
              radius={5}
              padding={5}
              background="#001426"
              content="Add New"
              className={style.tooltip}
            >
              <div
                className={style.add}
                onClick={() => {
                  setNextScreen("EmployeeFeedback");
                  setPrevData((prevData) => ({
                    ...prevData,
                    empId: prevData?.empId,
                    page: "EmployeeFeedback",
                    page3: "EmployeeFeedback",
                    page4: null,
                    active: "EmployeeFeedback",
                  }));
                }}
              >
                <p className={style.plus}>+</p>
              </div>
            </Tooltip>
          )
        }
      ></BreadCrum>
      {/* first */}
      <div className={style.card}>
        <p className={style.title}>Employee Details</p>

        {/* 1 */}
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <p className={style.value}>
              <span
                onClick={() => setTable(1)}
                className={style.employee_name}
              >{` ${profileData?.name} (${profileData?.empCode})`}</span>
            </p>
          </div>

          {feedbackCount?.map(
            (item) =>
              item?.totalCount > 0 && (
                <div className={style.cu_col1}>
                  <p className={style.value}>
                    <span
                      onClick={() => {
                        setTableData(item?.performanceReviews);
                        setTable(2);
                      }}
                      className={style.span_cursor}
                    >
                      {item?.reviewName === "Client"
                        ? item?.type === null
                          ? "Client Interview"
                          : "Client Feedback"
                        : item?.reviewName}
                      {item?.type === null
                        ? ` (${item?.passedCount}/${item?.totalCount})`
                        : item?.type === "status"
                          ? ` (${item?.totalCount})`
                          : ` : ${item?.totalCount}`}
                    </span>
                  </p>
                  {item?.reviewName === "Client" &&
                    item?.type === "feedback" && (
                      <p className={style.overall_rating}>
                        Overall Rating :{" "}
                        {`${item?.overallRating?.toFixed(1)} / (${item?.ratingCriteria
                          })`}
                      </p>
                    )}

                  {item?.reviewName === "Reporting Manager" && (
                    <p className={style.overall_rating}>
                      Overall Rating :{" "}
                      {`${item?.overallRating?.toFixed(1)} / (${item?.ratingCriteria
                        })`}
                    </p>
                  )}
                </div>
              )
          )}
        </div>
      </div>
      {/* 2 */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "2vw",
        }}
      >
        {isReviewActive &&
          <button
            className={style.btn}
            onClick={() => {
              setIsNote(!isNote)
              setIsEditNote(false);
            }}
          >
            Review Note
          </button>
        }
        <button
          className={style.btn}
          onClick={() => {
            setNextScreen("viewFeedback");
            setPrevData((prevData) => ({
              ...prevData,
              empId: profileData?.empId,
              sidebar: "EmployeePerformanceReview",
              page: "viewFeedback",
              page1: "EmployeePerformanceReview",
              page2: "EmployeeFeedbackList",
              page3: "viewFeedback",
              page4: null,
              active: "viewFeedback",
              showAllFeedback: true,
            }));
          }}
        >
          View All
        </button>
      </div>
      {/* {isNote && <Note isEditNote={false} setIsNote={setIsNote} />} */}
      {isNote &&
        <Note
          isEditNote={isEditNote}
          setIsNote={setIsNote}
          noteData={noteData}
          setNoteData={setNoteData}
          handleAddNote={handleAddNote}
        />}
      {/* second */}
      <div className={style.card}>
        <p className={style.title}>Feedback</p>
        <div className={style.PositionList}>
          <div className={style.table}>
            <div className={style.th}>
              <p className={style.tableContent}>
                Review<br></br> Date
              </p>
              <p className={style.tableContent}>
                Review<br></br> Month
              </p>
              <p className={style.tableContent}>
                Review<br></br> Type
              </p>
              <p className={style.tableContent}>
                Review<br></br> Source Type
              </p>
              <p className={style.tableContent}>
                Online<br></br> Platform
              </p>
              <p className={style.tableContent}>
                Client<br></br> Name
              </p>
              <p className={style.tableContent}>Status</p>
              <p className={style.tableContentStatus}>Reviewed By</p>
              <p className={style.tableContent}>Updated By</p>
            </div>
            {table === 1 ? (
              <div>
                {isLoading && <p className={style.no_data}>Loading .....</p>}
                {!isLoading && !isError ? (
                  data?.length > 0 ? (
                    typeof data !== "string" &&
                    data?.map((item) => {
                      return (
                        <div className={style.td} key={item?.positionId}>
                          <p
                            className={`${style.tableContent} ${style.link}`}
                            onClick={() => {
                              setNextScreen("viewFeedback");
                              setPrevData((prevData) => ({
                                ...prevData,
                                empId: item?.empId,
                                pReId: item?.performanceReviewId,
                                sidebar: "EmployeePerformanceReview",
                                page: "viewFeedback",
                                page1: "EmployeePerformanceReview",
                                page2: "EmployeeFeedbackList",
                                page3: "viewFeedback",
                                page4: null,
                                active: "viewFeedback",
                                showAllFeedback: false,
                              }));
                            }}
                          >
                            {moment(item.reviewDate).format("DD MMM YY")}
                          </p>
                          <p className={style.tableContent}>
                            {moment(item.reviewDate).format("MMM")}
                          </p>
                          <p className={style.tableContent}>
                            {item?.reviewType}
                          </p>
                          <p className={style.tableContent}>
                            {item?.reviewSourceType}
                          </p>
                          <p className={style.tableContent}>
                            {item?.onlinePlatform}
                          </p>
                          <p
                            className={style.tableContent}
                            title={
                              item?.clientName?.length > 10 && item?.clientName
                            }
                          >
                            {item?.clientName}
                          </p>
                          <p className={style.tableContent}>
                            {item?.interviewResult}
                          </p>
                          <p className={style.tableContent}>
                            {item?.interviewerName}
                          </p>
                          <p className={style.tableContent}>
                            {item?.updatedBy}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={style.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            ) : tableData?.length > 0 ? (
              tableData?.map((item) => (
                <div className={style.td} key={item?.positionId}>
                  <p
                    className={`${style.tableContent} ${style.link}`}
                    onClick={() => {
                      setNextScreen("viewFeedback");
                      setPrevData((prevData) => ({
                        ...prevData,
                        empId: item?.empId,
                        pReId: item?.performanceReviewId,
                        sidebar: "EmployeePerformanceReview",
                        page: "viewFeedback",
                        page1: "EmployeePerformanceReview",
                        page2: "EmployeeFeedbackList",
                        page3: "viewFeedback",
                        page4: null,
                        active: "viewFeedback",
                        showAllFeedback: false,
                      }));
                    }}
                  >
                    {moment(item.reviewDate).format("DD MMM YY")}
                  </p>
                  <p className={style.tableContent}>
                    {moment(item.reviewDate).format("MMM")}
                  </p>
                  <p className={style.tableContent}>{item?.reviewType}</p>
                  <p className={style.tableContent}>{item?.reviewSourceType}</p>
                  <p className={style.tableContent}>{item?.onlinePlatform}</p>
                  <p className={style.tableContent}>{item?.clientName}</p>
                  <p className={style.tableContent}>{item?.interviewResult}</p>
                  <p className={style.tableContent}>{item?.interviewerName}</p>
                  <p className={style.tableContent}>{item?.updatedBy}</p>
                </div>
              ))
            ) : (
              <p className={style.no_data}>No Data Found</p>
            )}
          </div>
          <></>
        </div>
      </div>
      {/* Notes */}
      {isReviewActive &&
        <div className={style.card}>
          <p className={style.title}>Review Notes</p>
          <NotesTable
            data={ReviewCasesNotes}
            handleNoteEdit={handleNoteEdit}
            handleNoteDelete={handleNoteDelete}
          />
          <div className={style.pagination}>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={notesPage}
              pagesCount={ReviewCasesNotes?.count / 15}
              color="#65A15E"
              color2="white"
            />
          </div>
        </div>
      }
      <Modal
        open={show2}
        center
        onClose={() => setShow2(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          <div>
            <p className={style.textM}>Data not found!</p>
          </div>
          <div className={style.M_button}>
            <div className={style.button}>
              <button
                className={style.b_inactive}
                onClick={() => {
                  setNextScreen("EmployeePerformanceReview");
                  setPrevData((prevData) => ({
                    ...prevData,
                    sidebar: "EmployeePerformanceReview",
                    page: "EmployeePerformanceReview",
                    page1: "EmployeePerformanceReview",
                    page2: null,
                    page3: null,
                    active: "EmployeePerformanceReview",
                  }));
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmployeeFeedbackList;
