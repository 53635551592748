import React, { useEffect, useState } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import style from './CopyEmail.module.css'
let timeoutId;

const CopyEmail = ({email}) => {
    const [copyPop, setCopyPop] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const copyEmail = ()=>{
        navigator.clipboard.writeText(email);
        setShowCopy(true);
    }
    
    const displayPopup = ()=>{
        timeoutId = setTimeout(() => {
            setCopyPop(true)
        }, 500);
        
    }
    const removePopup = ()=>{
        clearTimeout(timeoutId)
        setCopyPop(false);
        setTimeout(() => {
            setShowCopy(false)
        }, 200);
    }
    // useEffect(()=>{
    //     if(copyPop){
    //         const timer = setTimeout(() => {
    //             setCopyPop(false)
    //         }, 1000);

    //         return ()=>clearTimeout(timer);
    //     }
        
    // },[copyPop])
  return (
    <>{email && <span style={{cursor: "pointer", position: "absolute"}}>
        <p className={style.copyIcon}><FaRegCopy onClick={()=>copyEmail()} onMouseOver={()=>displayPopup()} onMouseOut={()=>removePopup()}/></p><br/>
        <span className={style.popup} style={{opacity: `${copyPop ? '1' : 0}`, width: `${showCopy ? '94px' : '85px'}`}}>{showCopy ? 'Email Copied' : 'Copy Email'}</span>
    </span>}</>
  )
}

export default CopyEmail