import React, { useEffect, useState } from "react";
import classes from "./PartnerList.module.css";
import { Col, Container, Row } from "react-bootstrap";
import searchIcon from "../../../../assets/Icon-search.png";
import Select from "react-select";
import { customSearch } from "../../utils/style";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import CustomTooltip from "../../../PartnerManagement/customTooltip/CustomTooltip.js";
import { MdOutlineInsertComment } from "react-icons/md";
import { Modal } from "react-responsive-modal";



import {
  getPartnerName,
  PartnerListData,
  getSKillName,
  GetStatusType,
  validateEmployeeAccess,
  VendorListData,
  getPanNumberData,
  getVendorData,
  getGSTData,
  getCityList,
  mailSendToPartners,
  getPartnerTypeList,
} from "../../../../Utils/axios/PortalManagement.js";
import { getPartnerRecruiterList1 } from "../../../../Utils/axios/api";
import Pagination from "../../CommonComponent/pagination/pagination.js";
import TotalCount from "./../../TotalCount/TotalCount";
import ExcelFileDownloadPartner from "../ExcelFileDownloadPartner/ExcelFileDownloadPartner";
import ExcelFileDownloadVendor from "../ExcelFileDownloadPartner/ExcelFileDownloadVendor";
import ClientList from "../ClientManagement/ClientList/ClientList";
import { employeeId } from "../../../../Utils/constants";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton.js";
import ConfirmPopup from "../../../Projects_and_Resource_Master/Projects/component/projecDescription/ConfirmPopup.jsx";
import ImageDropzone from "../../../atsDashboard/pageLayout/viewPosition/ImageDropzone.js";
import { deleteExpInvFile } from "../../../../Utils/axios/expenceApi.js";
import { Toastify, ToastifyError } from "../../../../App.js";
import CommentHistory from "../../CommonComponent/CommentNew/CommentHistory/CommentHistory.js";


const PartnerList = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [myPositionId, setMyPositionId] = useState(null);
  const [myPartnerName, setMyPartnerName] = useState(null)
  const [myCandidateId, setMyCandidateId] = useState(null);
  const [showMT, setShowMT] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [images, setImages] = useState([]);
  const [validation, setValidation] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [emailSend, setEmailSend] = useState({
    addNote: "",
    mailByEmpId: null,
    partnerId: [],
    subject: `Wishing you all `,
    urls: [],
  });

  const [radioChecked, setRadioChecked] = useState(
    prevData?.radioChecked === 1
      ? 1
      : prevData?.radioChecked === 3
        ? 3
        : prevData?.radioChecked === 2
          ? 2
          : 1
  );
  useEffect(() => {
    setPrevData((prev) => ({ ...prev, radioChecked: radioChecked }));
    resetEmailSend();
  }, [radioChecked]);

  const [filter, setFilter] = useState(
    prevData?.PMFilter
      ? prevData?.PMFilter
      : {
        empId: 0,
        partnerId: "",
        specializationId: "",
        partnerTypeId: "",
        statusId: "",
        ratingId: "",
        recuiterId: "",
        cityId: "",
        panId: "",
        venderId: "",
        gstInId: "",
        tdsApplicable: "",
      }
  );
  const { data: getValidateEmp } = useQuery(["validateEmployeeAccess"], () =>
    validateEmployeeAccess(parseInt(employeeId))
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  useEffect(() => {
    setPrevData((prevData) => ({
      ...prevData,
      PMFilter: filter,
    }));
  }, [filter]);
  const [filterName, setFilterName] = useState({});
  const onSearchHandler = (e, option) => {
    setCurrentPage(1);
    if (e || e === "") {
      switch (option) {
        case "partnerId":
          setFilter({ ...filter, partnerId: e?.value });
          break;
        case "specializationId":
          setFilter({ ...filter, specializationId: e?.value });
          break;

        case "statusId":
          setFilter({ ...filter, statusId: e?.value });
          break;
        case "ratingId":
          setFilter({ ...filter, ratingId: e?.value });
          break;
        case "panId":
          setFilter({ ...filter, panId: e?.value });
          break;
        case "venderId":
          setFilter({ ...filter, venderId: e?.value });
          break;
        case "gstInId":
          setFilter({ ...filter, gstInId: e?.value });
          break;
        case "tdsApplicable":
          setFilter({ ...filter, tdsApplicable: e?.label });
          break;
        case "cityId":
          setFilter({ ...filter, cityId: e?.value });
          break;
        case "recuiterId":
          setFilter({ ...filter, recuiterId: e?.value });
          break;
        case "partnerTypeId":
          setFilter({ ...filter, partnerTypeId: e?.value })
        default:
      }
    } else {
      setFilter({
        partnerId: "",
        specializationId: "",
        statusId: "",
        ratingId: "",
        panId: "",
        venderId: "",
        gstInId: "",
        tdsApplicable: "",
        partnerTypeId: ""
      });
      setFilterName({
        partnerId: "",
        specializationId: "",
        statusId: "",
        ratingId: "",
        panId: "",
        gstInId: "",
        venderId: "",
        tdsApplicable: "",
        partnerTypeId: ""
      });
    }
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["PartnerListData", { currentPage, filter }],
    () => PartnerListData(10, currentPage, filter)
  );

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data.totalCount / 10);
      const newSelectData = Array(totalPages).fill({
        isSelectAll: false,
        selected: [],
      });
      if (!selectedData?.data) {
        setSelectedData({ data: newSelectData, totalSelected: [] });
      }
    }
  }, [data]);

  const {
    data: vendorData,
    isLoading1,
    isError1,
  } = useQuery(["VendorListData", { currentPage, filter }], () =>
    VendorListData(10, currentPage, filter)
  );

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handleSelectAll = () => {
    if (!selectedData.data[currentPage - 1].isSelectAll) {
      let item = data?.allPartners.map((entry) => entry.id);
      const tempData = selectedData.data;
      tempData[currentPage - 1] = {
        isSelectAll: true,
        selected: [...item],
      }
      const tempSelectedData = tempData.reduce((acc, curr) => {
        return [...acc, ...curr.selected];
      }, [])
      setSelectedData({
        ...selectedData,
        data: tempData,
        totalSelected: tempSelectedData,
      });
    }
    else {
      const tempData = selectedData.data;
      tempData[currentPage - 1] = {
        isSelectAll: false,
        selected: [],
      }
      const tempSelectedData = tempData.reduce((acc, curr) => {
        return [...acc, ...curr.selected];
      }, [])

      setSelectedData({
        ...selectedData,
        data: tempData,
        totalSelected: tempSelectedData,
      });
    }
  };

  const handleSelection = (value) => {
    const tempData = selectedData.data;
    if (selectedData?.data?.[currentPage - 1].selected?.includes(value)) {
      let index = selectedData?.data?.[currentPage - 1].selected?.indexOf(value);
      tempData[currentPage - 1].selected?.splice(index, 1);
      tempData[currentPage - 1] = {
        isSelectAll: selectedData.data[currentPage - 1].isSelectAll,
        selected: tempData[currentPage - 1].selected,
      }
    }
    else {
      tempData[currentPage - 1] = {
        isSelectAll: selectedData.data[currentPage - 1].isSelectAll,
        selected: [...tempData[currentPage - 1].selected, value],
      }
    }
    let item = data?.allPartners?.map((entry) => entry.id);
    let isAllSelected = item?.every((element) => tempData[currentPage - 1].selected.includes(element));
    if (isAllSelected !== tempData?.[currentPage - 1].isSelectAll) {
      tempData[currentPage - 1].isSelectAll = isAllSelected;
    }


    const tempSelectedData = tempData.reduce((acc, curr) => {
      return [...acc, ...curr.selected];
    }, [])
    setSelectedData({
      ...selectedData,
      data: tempData,
      totalSelected: tempSelectedData,
    });
  };
  useEffect(() => {
    setEmailSend({
      ...emailSend,
      urls: images.map(image => image.preview)
    }
    )
  }, [images]);

  const mutation = useMutation(mailSendToPartners, {
    onSuccess: (res) => {
      Toastify(res);
      refetch();
      resetEmailSend();
      if (data) {
        debugger;
        const totalPages = Math.ceil(data.totalCount / 10);
        const newSelectData = Array(totalPages).fill({
          isSelectAll: false,
          selected: [],
        });
        setSelectedData({ data: newSelectData, totalSelected: [] });
      }
    },
    onError: () => {
      ToastifyError("Something went wrong");
      resetEmailSend();
    },
  });

  const removeImages = () => {
    images?.map((image) => deleteExpInvFile(image.preview));
    setImages([]);
  }
  const resetEmailSend = () => {
    setEmailSend({
      addNote: "",
      mailByEmpId: null,
      partnerId: [],
      subject: `Wishing you all `,
      urls: [],
    })
  }
  const handleSubmit = () => {
    setShowMT(false);
    setShowPreview(false);
    setImages([]);
    if (emailSend?.subject) {
      mutation?.mutate({
        addNote: emailSend?.addNote,
        mailByEmpId: employeeId,
        partnerId: selectedData?.totalSelected,
        subject: emailSend?.subject,
        urls: emailSend?.urls,
        // positionId: prevData?.positionId,
        // signature: emailSend?.signature,
        // ccMail: localStorageGetItem("email"),
      });
    } else {
      setValidation(true);
    }
  };
  const handlePreview = () => {
    if (emailSend?.subject && (emailSend?.addNote || emailSend?.urls.length)) {
      setShowMT(false);
      setShowPreview(true);
    } else {
      setValidation(true);
    }
  };

  const { data: PartnerData } = useQuery(["getPartnerName"], getPartnerName);
  PartnerData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const { data: getPartnerTypeData } = useQuery(
    ["getPartnerTypeList"],
    getPartnerTypeList
  );

  const partnerOption = PartnerData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const getPartnerType = getPartnerTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: SkillData } = useQuery(["getSKillName"], getSKillName);

  const { data: recruiterList } = useQuery(
    ["getPartnerRecruiterList1"],
    getPartnerRecruiterList1
  );

  const recruiterOptions = recruiterList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));


  SkillData?.sort((a, b) =>
    a.specialization?.toUpperCase() > b.specialization?.toUpperCase() ? 1 : -1
  );

  const SkillDataOption = SkillData?.map((e) => ({
    value: e.specializationId,
    label: e.specialization,
  }));
  const dataRating = [
    {
      id: 1,
      Rating: "1",
    },
    {
      id: 2,
      Rating: "2",
    },
    {
      id: 3,
      Rating: "3",
    },
    {
      id: 4,
      Rating: "4",
    },
    {
      id: 5,
      Rating: "5",
    },
  ];
  const TDSOption = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 2,
      label: "No",
    },
  ];

  const GetTDSOption = TDSOption?.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const GetRatingOption = dataRating?.map((e) => ({
    value: e.id,
    label: e.Rating,
  }));

  const { data: GetStatusTypeData } = useQuery(
    ["GetStatusType"],
    GetStatusType
  );

  GetStatusTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const GetStatusTypeDataOption = GetStatusTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: GetPanData } = useQuery(["getPanNumberData"], getPanNumberData);

  const GetStatusPanOption = GetPanData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: GetVendor } = useQuery(["getVendorData"], getVendorData);

  const GetVendorOption = GetVendor?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: GetGST } = useQuery(["getGSTData"], getGSTData);

  const GetGSTOption = GetGST?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getCity } = useQuery(["getCity"], getCityList);

  const cityOption = getCity?.map((e) => ({
    value: e?.id,
    label: e?.name,
  }));
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  return (
    <>
      <div className={classes.VenderType}>
        <div className={classes.VenderRadio}>
          <div>
            <p className={classes.manage}>Vendor Type :</p>
          </div>
          <input
            style={{ marginLeft: "20px" }}
            type="radio"
            id="Yes"
            name="radio-group"
            value={1}
            checked={radioChecked === 1}
            onChange={() => setRadioChecked(1)}
          />
          <label htmlFor="Yes" className={classes.resourceType}>
            Resource
          </label>
          <input
            type="radio"
            id="no"
            name="radio-group"
            value={2}
            checked={radioChecked === 2}
            onChange={() => setRadioChecked(2)}
            style={{ marginLeft: "20px" }}
          />
          <label htmlFor="Other" className={classes.resourceType}>
            Other
          </label>
        </div>
      </div>

      {radioChecked === 1 ? (
        <>
          <Container fluid>
            <div className={classes.partnertop}>
              <div className={classes.topBar}>
                <div style={{ position: "relative" }} className={classes.s1}>
                  {filter?.statusId === "" ? (
                    <span
                      style={{
                        right: "6%",
                        top: "14%",
                        position: "absolute",
                      }}
                      className={classes.cp_status}
                    >
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_status}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          statusId: "",
                        });
                        setFilterName({ ...filterName, statusId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Status"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.statusId
                        ? GetStatusTypeDataOption?.find(
                          (e) => e?.value === filter?.statusId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "statusId")}
                    options={GetStatusTypeDataOption}
                  />
                </div>
              </div>
              <div className={classes.topBarRating}>
                <div className={classes.s1}>
                  {filter?.ratingId === "" ? (
                    <span
                      className={classes.cp_serchIcon1}
                      style={{ marginLeft: "75%" }}
                    >
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_serchIcon1}
                      style={{ marginLeft: "75%" }}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          ratingId: "",
                        });
                        setFilterName({ ...filterName, ratingId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Rating"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.ratingId
                        ? GetRatingOption?.find(
                          (e) => e?.value === filter?.ratingId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "ratingId")}
                    options={GetRatingOption}
                  />
                </div>
              </div>
              <div className={classes.topBarSKill}>
                <div className={classes.s1}>
                  {filter?.specializationId === "" ? (
                    <span className={classes.cp_Skill}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_Skill}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          specializationId: "",
                        });
                        setFilterName({ ...filterName, specializationId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Skill Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.specializationId
                        ? SkillDataOption?.find(
                          (e) => e?.value === filter?.specializationId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "specializationId")}
                    options={SkillDataOption}
                  />
                </div>
              </div>
              <div className={classes.topBarName}>
                <div className={classes.sEmpName}>
                  {filter?.partnerId === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          partnerId: "",
                        });
                        setFilterName({ ...filterName, partnerId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Partner Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.partnerId
                        ? partnerOption?.find(
                          (e) => e?.value === filter?.partnerId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "partnerId")}
                    options={partnerOption}
                  />
                </div>
              </div>

              <div className={classes.topBarName}>
                <div className={classes.sEmpName}>
                  {filter?.partnerTypeId === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          partnerTypeId: "",
                        });
                        setFilterName({ ...filterName, partnerTypeId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"position_type"}
                    placeholder="Partner Type"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.partnerTypeId
                        ? getPartnerType?.find(
                          (e) => e?.value === filter?.partnerTypeId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "partnerTypeId")}
                    options={getPartnerType}
                  />
                </div>
              </div>

              <div className={classes.topBarName}>
                <div className={classes.sEmpName}>
                  {filter?.cityId === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          cityId: "",
                        });
                        setFilterName({ ...filterName, cityId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Location"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.cityId
                        ? cityOption?.find((e) => e?.value === filter?.cityId)
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "cityId")}
                    options={cityOption}
                  />
                </div>
              </div>

              <div className={classes.topBarName}>
                <div className={classes.sEmpName}>
                  {filter?.recuiterId === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          recuiterId: "",
                        });
                        setFilterName({ ...filterName, recuiterName: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Recruiter Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.recuiterId
                        ? recruiterOptions?.find(
                          (e) => e?.value === filter?.recuiterId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "recuiterId")}
                    options={recruiterOptions}
                  />
                </div>
              </div>
            </div>
            <Row>
              <Col md={12} style={{ marginTop: "8px" }}>
                <div className={classes.partnerlist}>
                  <h6 className={classes.manage}>Partner List</h6>
                  {/* {getVData?.some((e) => {
                    return e?.label === "329X7157D19173YX";
                  }) && (
                      <div className={classes.createPartner}>
                        <div
                          onClick={() => {
                            setNextScreen("CreatePartner");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "CreatePartner",
                              page3: null,
                              page4: null,
                              active: "CreatePartner",
                            }));
                          }}
                          className={classes.add_btn}
                        >
                          +
                        </div>
                      </div>
                    )} */}
                  <TotalCount totalCount={data?.totalCount} />
                  <div>
                    <ExcelFileDownloadPartner id={1} />
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <InvoiceContainer styles={{ overflow: "auto" }}>
                  <div className={classes.table}>
                    <div className={classes.th}>
                      <p className={classes.list} title="Select All">
                        <input
                          type="checkbox"
                          className={classes.vh}
                          id={"key"}
                          checked={selectedData && selectedData.data && selectedData.data[currentPage - 1]?.isSelectAll || false}
                          onChange={() => handleSelectAll()}
                        />
                        <label
                          htmlFor={"key"}
                          style={checkboxStyle}
                          className={classes.label}
                        ></label>
                      </p>
                      <p>
                        Partner <br /> Code
                      </p>
                      <p>Partner Name</p>
                      <p></p>
                      <p>Location</p>
                      <p>Name</p>
                      <p>Email Id</p>
                      <p>
                        Current <br /> Rating
                      </p>
                      <p>Mobile Number</p>
                      <p>On Boarding Status</p>
                      <p>Partner Type</p>
                      <p>Recruiter Name</p>
                    </div>
                    <div>
                      {isLoading && (
                        <p className={classes.no_data}>Loading .....</p>
                      )}
                      {!isLoading && !isError ? (
                        data?.allPartners?.length > 0 ? (
                          typeof data?.allPartners !== "string" &&
                          data?.allPartners?.map((item, index) => {
                            return (
                              <div
                                className={classes.td}
                                key={item?.positionId}
                                style={{ position: "relative" }}
                              >
                                <p className={classes.list} key={index}>
                                  <input
                                    type="checkbox"
                                    className={classes.vh}
                                    id={index}
                                    checked={selectedData?.data?.[currentPage - 1].selected?.includes(item?.id) || false}
                                    onChange={() => handleSelection(item?.id)}
                                    style={
                                      false
                                        ? {
                                          ...checkboxStyle,
                                          cursor: "not-allowed",
                                        }
                                        : {
                                          ...checkboxStyle,
                                        }
                                    }

                                  />
                                  <label
                                    htmlFor={index}
                                    style={
                                      false
                                        ? {
                                          ...checkboxStyle,
                                          cursor: "not-allowed",
                                        }
                                        : {
                                          ...checkboxStyle,
                                        }
                                    }
                                    className={classes.label}
                                  ></label>
                                </p>
                                <p
                                  className={`${classes.tableContent} ${classes.link}`}
                                  onClick={() => {
                                    setNextScreen("ViewPartner");
                                    setPrevData((prevData) => ({
                                      ...prevData,
                                      page2: "ViewPartner",
                                      page3: null,
                                      page4: null,
                                      active: "ViewPartner",
                                      page1: "PartnerList",
                                      partnerId: item?.id,
                                    }));
                                  }}
                                >
                                  {item?.partnerCode}
                                </p>
                                <CustomTooltip
                                  value={item?.partnerName}
                                  limit={20}
                                  index={`partnerName${index}`}
                                />
                                <p>
                                  <MdOutlineInsertComment
                                    style={{ cursor: "pointer", height: "13.5px", marginLeft: "1px", color: "black" }}
                                    onClick={() => {
                                      setShowCommentModal(true);
                                      setMyPositionId(item?.id);
                                      setMyPartnerName(item?.partnerName)
                                    }}
                                    title={" Add Comment For Partner"}
                                  />
                                </p>
                                <p>{item?.city}</p>
                                <CustomTooltip
                                  value={item?.primaryContactName}
                                  limit={10}
                                  index={`primaryContactName${index}`}
                                />
                                <CustomTooltip
                                  value={item?.primaryContectEmail}
                                  limit={15}
                                  index={`primaryContectEmail${index}`}
                                />
                                <p>{item?.currentRating}</p>
                                <p>{item?.primaryMobileNumber}</p>
                                <p>{item?.status}</p>
                                <CustomTooltip
                                  value={item?.partnerType}
                                  limit={15}
                                  index={`partnerType${index}`}
                                />
                                <CustomTooltip
                                  value={item?.recuiterName}
                                  limit={15}
                                  index={`recuiterName${index}`}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <p className={classes.no_data}>No Data Found</p>
                        )
                      ) : null}
                    </div>
                  </div>
                </InvoiceContainer>
              </Col>
            </Row>
          </Container>
          <div className={classes.pagination}>
            <InvoiceButton
              onClick={() => {
                // setShowComment(!showComment);
                // setIsNoteEdit(false);
                // setNote("");
                setShowMT(true);
              }}
              disabled={selectedData?.totalSelected?.length > 0 ? false : true}
            >
              Send Email
            </InvoiceButton>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={currentPage}
              pagesCount={data?.totalCount / 10}
              color="#C3B14B"
              color2="white"
            />
          </div>
        </>
      ) : radioChecked === 2 ? (
        <>
          <Container fluid>
            <div className={classes.partnertop}>
              <div className={classes.topPanBar}>
                <div className={classes.s1}>
                  {filter?.panId === "" ? (
                    <span className={classes.cp_status}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_status}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          panId: "",
                        });
                        setFilterName({ ...filterName, panId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="PAN"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.panId
                        ? GetStatusPanOption?.find(
                          (e) => e?.value === filter?.panId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "panId")}
                    options={GetStatusPanOption}
                  />
                </div>
              </div>
              <div className={classes.topVendor}>
                <div className={classes.s1}>
                  {filter?.venderId === "" ? (
                    <span className={classes.cp_serchIcon2}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_serchIcon2}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          venderId: "",
                        });
                        setFilterName({ ...filterName, venderId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Vendor Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.venderId
                        ? GetVendorOption?.find(
                          (e) => e?.value === filter?.venderId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "venderId")}
                    options={GetVendorOption}
                  />
                </div>
              </div>
              <div className={classes.topGST}>
                <div className={classes.s1}>
                  {filter?.gstInId === "" ? (
                    <span className={classes.cp_Skill}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_Skill}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          gstInId: "",
                        });
                        setFilterName({ ...filterName, gstInId: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="GST"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.gstInId
                        ? GetGSTOption?.find(
                          (e) => e?.value === filter?.gstInId
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "gstInId")}
                    options={GetGSTOption}
                  />
                </div>
              </div>
              <div className={classes.topBarTDS}>
                <div className={classes.sEmpName}>
                  {filter?.tdsApplicable === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          tdsApplicable: "",
                        });
                        setFilterName({ ...filterName, tdsApplicable: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="TDS applicable"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.tdsApplicable
                        ? GetTDSOption?.find(
                          (e) => e?.label === filter?.tdsApplicable
                        )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "tdsApplicable")}
                    options={GetTDSOption}
                  />
                </div>
              </div>

              {getVData?.some((e) => {
                return e?.label === "329X7157D19173YX";
              }) && (
                  <div className={classes.createPartner}>
                    <button
                      className={classes.buttonAdd}
                      onClick={() => {
                        setNextScreen("CreateVendor");
                        setPrevData((prevData) => ({
                          ...prevData,
                          page2: "CreateVendor",
                          page3: null,
                          page4: null,
                          active: "CreateVendor",
                        }));
                      }}
                    >
                      Create Vendor
                    </button>
                  </div>
                )}
            </div>
            <Row>
              <Col md={12} style={{ marginTop: "8px" }}>
                <div className={classes.partnerlist}>
                  <h6 className={classes.manage}>Vendor List</h6>
                  <TotalCount totalCount={vendorData?.totalCount} />
                  <div>
                    <ExcelFileDownloadVendor id={2} />
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <InvoiceContainer styles={{ width: "auto", overflow: "auto" }}>
                  <div className={classes.table}>
                    <div className={classes.th1}>
                      <p>
                        Vendor <br /> Code
                      </p>
                      <p>Vendor Name</p>
                      <p>PAN</p>
                      <p>GSTIN</p>
                      <p>Contact Name</p>
                      <p>Contact Number</p>
                      <p>
                        GST <br /> Applicable
                      </p>
                      <p >
                        TDS <br /> Applicable
                      </p>
                    </div>
                    <div>
                      {isLoading1 && (
                        <p className={classes.no_data}>Loading .....</p>
                      )}
                    </div>
                    {!isLoading1 && !isError1 ? (
                      vendorData?.allPartners?.length > 0 ? (
                        typeof vendorData?.allPartners !== "string" &&
                        vendorData?.allPartners?.map((item, index) => {
                          return (
                            <div
                              className={classes.td1}
                              key={item?.positionId}
                              style={{ position: "relative" }}
                            >
                              <p
                                className={`${classes.tableContent} ${classes.link}`}
                                onClick={() => {
                                  setNextScreen("ViewVendor");
                                  setPrevData((prevData) => ({
                                    ...prevData,
                                    page2: "ViewVendor",
                                    page3: null,
                                    page4: null,
                                    active: "ViewVendor",
                                    page1: "PartnerList",
                                    venderId: item?.venderId,
                                  }));
                                }}
                              >
                                {item?.venderCode}
                              </p>
                              <CustomTooltip
                                value={item?.venderName}
                                limit={15}
                                index={`venderName${index}`}
                              />
                              <p>{item?.pan}</p>
                              <CustomTooltip
                                value={item?.gstin}
                                limit={10}
                                index={`gstin${index}`}
                              />{" "}
                              <CustomTooltip
                                value={item?.primaryContactName}
                                limit={15}
                                index={`primaryContactName${index}`}
                              />
                              {/* <p>{item?.primaryContactName}</p> */}
                              <p>{item?.primaryMobileNumber}</p>
                              <p>{item?.gstApplicable}</p>
                              <p>{item?.tdsApplicable}</p>
                            </div>
                          );
                        })
                      ) : (
                        <p className={classes.no_data}>No Data Found</p>
                      )
                    ) : null}
                  </div>
                </InvoiceContainer>
              </Col>
            </Row>
          </Container>
          <div className={classes.pagination}>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={currentPage}
              pagesCount={vendorData?.totalCount / 10}
              color="#C3B14B"
              color2="white"
            />
          </div>
        </>
      ) : radioChecked === 3 ? (
        <ClientList
          setNextScreen={setNextScreen}
          prevData={prevData}
          setPrevData={setPrevData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        ""
      )}

      <ConfirmPopup open={showMT}>
        <div className={classes.container}>
          <div className={classes.mt_container}>
            <p className={classes.text}>
              Subject <span style={{ color: "red" }}>*</span>
            </p>
            <input
              className={classes.subject}
              value={emailSend?.subject}
              onChange={(e) =>
                setEmailSend((prev) => ({ ...prev, subject: e?.target?.value }))
              }
            />
            <p
              className={
                validation && !emailSend?.subject
                  ? classes.textError
                  : classes.textHidden
              }
            >
              Please Enter Subject
            </p>
            <p className={classes.text}>Email Content</p>
            <textarea
              className={classes.bgvInput}
              value={emailSend?.addNote}
              onChange={(e) =>
                setEmailSend((prev) => ({ ...prev, addNote: e?.target?.value }))
              }
            />
            <ImageDropzone images={images} setImages={setImages} />
            <p
              className={
                validation && !(emailSend?.addNote || emailSend?.urls.length)
                  ? classes.textError
                  : classes.textHidden
              }
            >
              Please Enter Content or Drop an Image
            </p>
            {/* <p className={classes.text}>Signature</p>
            <textarea
              className={classes.subject}
              value={emailSend?.signature}
              style={{ minHeight: "4rem" }}
              onChange={(e) =>
                setEmailSend((prev) => ({
                  ...prev,
                  signature: e?.target?.value,
                }))
              }
            /> */}
            <div className={classes.btn_container}>
              <InvoiceButton
                type="cancel1"
                onClick={() => {
                  setShowMT(false);
                  setValidation(false);
                  removeImages();
                  resetEmailSend();
                }}
              >
                Cancel
              </InvoiceButton>
              <InvoiceButton
                onClick={() => {
                  handlePreview();
                }}
              >
                Preview
              </InvoiceButton>
            </div>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={showPreview}>
        <div className={classes.preview_container}>
          {/* <div className={classes.jd_container} style={{ width: "70rem" }}> */}
          <p className={classes.textBold}>Subject</p>
          <p className={classes.value}>{emailSend?.subject}</p>
          <br />
          {emailSend?.addNote?.split("\n")?.map((e) => (
            <p className={classes.value}>{e ? e : <br />} </p>
          ))}

          <br />

          {/* images */}
          <div className={classes.imageContainer} style={{ textAlign: 'center' }}>
            {images?.map((image) => (
              <>
                <img src={image.preview} style={{ margin: '10px', width: '512px' }} />
                <br />
              </>
            ))}
          </div>
          <br />

          {/* signature */}
          {/* <div>
            {emailSend?.signature?.split("\n")?.map((e) => (
              <p className={classes.value}>{e ? e : <br />} </p>
            ))}
          </div> */}
          <div className={classes.btn_container}>
            <InvoiceButton
              type="cancel1"
              onClick={() => {
                setShowPreview(false);
                refetch();
                removeImages();
                resetEmailSend();
                setValidation(false);
              }}
            >
              Cancel
            </InvoiceButton>
            <InvoiceButton
              onClick={() => {
                setShowMT(true);
                setShowPreview(false);
              }}
            >
              Edit
            </InvoiceButton>
            <InvoiceButton onClick={() => handleSubmit()}>Submit</InvoiceButton>
          </div>
          {/* </div> */}
        </div>
      </ConfirmPopup>
      <Modal
        open={showCommentModal}
        onClose={() => {
          setShowCommentModal(false);
        }}
        showCloseIcon={true}
        center
      >
        <div className={classes.modal_container_watchlist2}>
          <div className={classes.watchlist_inner_container}>
            <CommentHistory
              setShowCommentModal={setShowCommentModal}
              partnerId={myPositionId}
              partnerName={myPartnerName}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PartnerList;
