import React from "react";
import BreadCrum from "../../../breadcrums/BreadCrum";
import style from "./reportNewDashboard.module.css";
import ValidationEmpAccess from "../../validationEmpAccess/ValidationEmpAccess";
import { accessCode } from "../../../../../Utils/constants";
import ATSCard from "./../../../customTooltip/ATSCard";
const ReportNewDashboard = ({
  prevData,
  setPrevData,
  setNextScreen,
  validateAccess,
}) => {
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      ></BreadCrum>
      {/* <ATSCard>
        <h6>Sourcing Report</h6>
        <div className={style.dashboardOptions}>
          {ValidationEmpAccess(validateAccess, accessCode?.sourcing_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("sourcingReportNew");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "sourcingReportNew",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "sourcingReportNew",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Sourcing Report</p>
            </div>
          )}
        </div>
      </ATSCard> */}

      <ATSCard>
        <h6>Sourcing Report</h6>
        <div className={style.dashboardOptions}>
          {/* {ValidationEmpAccess(validateAccess, accessCode?.sourcing_report) && ( */}
          {/* // <div className={style.dashboardOptions}>  */}
          {ValidationEmpAccess(validateAccess, accessCode?.pipeline_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("positionWise");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "positionWise",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "positionWise",
                  name: "",
                }));
              }}
            >
              Pipeline Report <br /> (Position wise)
            </div>
          )}
          {ValidationEmpAccess(validateAccess, accessCode?.sourcing_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("sourcingReportSummary");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "sourcingReportSummary",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "sourcingReportSummary",
                  name: "",
                }));
              }}
              onKeyDown={() => {
                setNextScreen("sourcingReportSummary");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "sourcingReportNew",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "sourcingReportSummary",
                  name: "",
                }));
              }}
              tabIndex={0}
            >
              <p className={style.optionsText}>Sourcing Report Summary</p>
            </div>
          )}
          {/* <<<<<<< HEAD */}

          {ValidationEmpAccess(validateAccess, accessCode?.sourcing_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("ActiveRecruiterPipelineReportNew");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "ActiveRecruiterPipelineReportNew",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "ActiveRecruiterPipelineReportNew",
                  name: "",
                }));
              }}
              onKeyDown={() => {
                setNextScreen("ActiveRecruiterPipelineReportNew");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "ActiveRecruiterPipelineReportNew",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "ActiveRecruiterPipelineReportNew",
                  name: "",
                }));
              }}
              tabIndex={0}
            >
              <p className={style.optionsText}>Active Recruiter <br /> Pipeline Report</p>
            </div>
          )}
          {ValidationEmpAccess(validateAccess, accessCode?.sourcing_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("DetailedSourcingReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "DetailedSourcingReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "DetailedSourcingReport",
                  name: "",
                }));
              }}
              onKeyDown={() => {
                setNextScreen("DetailedSourcingReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "DetailedSourcingReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "DetailedSourcingReport",
                  name: "",
                }));
              }}
              tabIndex={0}
            >
              <p className={style.optionsText}>Detailed Sourcing Report</p>
            </div>
          )}
          {ValidationEmpAccess(
            validateAccess,
            accessCode?.sourcing_performance_report
          ) && (
              <div
                className={style.taskTracker}
                onClick={() => {
                  setNextScreen("sourcingPerformanceMatrixNew");
                  setPrevData((prevData) => ({
                    ...prevData,
                    page: "positionALL",
                    sidebar: "reportNew",
                    page1: "reportNew",
                    page2: "sourcingPerformanceMatrixNew",
                    page3: null,
                    page4: null,
                    page5: null,
                    page6: null,
                    prevFilterData: null,
                    prevFilter: null,
                    active: "sourcingPerformanceMatrixNew",
                    name: "",
                  }));
                }}
              >
                <p className={style.optionsText}>Sourcing Performance Report</p>
              </div>
            )}
          {/*{ValidationEmpAccess(validateAccess, accessCode?.closure_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("closureReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "closureReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "closureReport",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Closure Report</p>
            </div>
          )}
          {ValidationEmpAccess(
            validateAccess,
            accessCode?.detailed_sourcing_report
          ) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("ActiveRecruiterPipelineReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "ActiveRecruiterPipelineReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "ActiveRecruiterPipelineReport",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Active Recruiter <br/> Pipeline Report</p>
            </div>
          )} */}

          {ValidationEmpAccess(validateAccess, accessCode?.closure_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("closureReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "closureReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "closureReport",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Closure Report</p>
            </div>
          )}
        </div>
      </ATSCard>
      <ATSCard>
        <h6>Account Management Report</h6>
        <div className={style.dashboardOptions}>
          {ValidationEmpAccess(
            validateAccess,
            accessCode?.position_health_report
          ) && (
              <div
                className={style.taskTracker}
                onClick={() => {
                  setNextScreen("positionHealthReportNew");
                  setPrevData((prevData) => ({
                    ...prevData,
                    page: "positionALL",
                    sidebar: "reportNew",
                    page1: "reportNew",
                    page2: "positionHealthReportNew",
                    page3: null,
                    page4: null,
                    page5: null,
                    page6: null,
                    prevFilterData: null,
                    prevFilter: null,
                    active: "positionHealthReportNew",
                    name: "",
                  }));
                }}
              >
                <p className={style.optionsText}>Position Health Report</p>
              </div>
            )}

          {ValidationEmpAccess(validateAccess, accessCode?.position_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("positionReport ");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "positionReport ",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "positionReport ",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Position Report</p>
            </div>
          )}

          <div
            className={style.taskTracker}
            onClick={() => {
              setNextScreen("InActivePositionsReport");
              setPrevData((prevData) => ({
                ...prevData,
                page: "positionALL",
                sidebar: "reportNew",
                page1: "reportNew",
                page2: "InActivePositionsReport",
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                prevFilterData: null,
                prevFilter: null,
                active: "InActivePositionsReport",
                name: "",
              }));
            }}
          >
            <p className={style.optionsText}>In-Active Positions Report</p>
          </div>

          {ValidationEmpAccess(
            validateAccess,
            accessCode?.sourcing_vs_share
          ) && (
              <div
                className={style.taskTracker}
                onClick={() => {
                  setNextScreen("sourcingVsShared");
                  setPrevData((prevData) => ({
                    ...prevData,
                    page: "positionALL",
                    sidebar: "reportNew",
                    page1: "reportNew",
                    page2: "sourcingVsShared",
                    page3: null,
                    page4: null,
                    page5: null,
                    page6: null,
                    prevFilterData: null,
                    prevFilter: null,
                    active: "sourcingVsShared",
                    name: "",
                  }));
                }}
              >
                <p className={style.optionsText}>Sourcing Vs Shared</p>
              </div>
            )}

          {ValidationEmpAccess(
            validateAccess,
            accessCode?.deployment_report
          ) && (
              <div
                className={style.taskTracker}
                onClick={() => {
                  setNextScreen("deploymentReport");
                  setPrevData((prevData) => ({
                    ...prevData,
                    page: "positionALL",
                    sidebar: "reportNew",
                    page1: "reportNew",
                    page2: "deploymentReport",
                    page3: null,
                    page4: null,
                    page5: null,
                    page6: null,
                    prevFilterData: null,
                    prevFilter: null,
                    active: "deploymentReport",
                    name: "",
                  }));
                }}
              >
                <p className={style.optionsText}>Deployment Report </p>
              </div>
            )}
        </div>
      </ATSCard>
      {/* <ATSCard>
        <h6>Sourcing Report Test</h6>
        <div className={style.dashboardOptions}> 
           {ValidationEmpAccess(validateAccess, accessCode?.sourcing_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("sourcingReportTest");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "sourcingReportTest",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "sourcingReportNew",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Sourcing Report Test</p>
            </div>
          )} 
         </div>
      </ATSCard> */}



      {/* </div> */}
      {/* </ATSCard> */}
      {/* <ATSCard>
        <h6>Account Management Report</h6>
        <div className={style.dashboardOptions}>
          {ValidationEmpAccess(
            validateAccess,
            accessCode?.position_health_report
          ) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("positionHealthReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "positionHealthReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "positionHealthReport",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Position Health Report</p>
            </div>
          )}

          {ValidationEmpAccess(validateAccess, accessCode?.position_report) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("positionReport ");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "positionReport ",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "positionReport ",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Position Report</p>
            </div>
          )}

          {ValidationEmpAccess(
            validateAccess,
            accessCode?.sourcing_vs_share
          ) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("sourcingVsShared");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "sourcingVsShared",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "sourcingVsShared",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Sourcing Vs Shared</p>
            </div>
          )}
          {ValidationEmpAccess(
            validateAccess,
            accessCode?.deployment_report
          ) && (
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("deploymentReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "deploymentReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "deploymentReport",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>Deployment Report </p>
            </div>
          )}

          
            <div
              className={style.taskTracker}
              onClick={() => {
                setNextScreen("InActivePositionsReport");
                setPrevData((prevData) => ({
                  ...prevData,
                  page: "positionALL",
                  sidebar: "reportNew",
                  page1: "reportNew",
                  page2: "InActivePositionsReport",
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  prevFilterData: null,
                  prevFilter: null,
                  active: "InActivePositionsReport",
                  name: "",
                }));
              }}
            >
              <p className={style.optionsText}>In-Active Positions Report</p>
            </div>
        
        </div>
      </ATSCard>
      <ATSCard>
        <h6>Partner Report</h6>
        <div className={style.dashboardOptions}>
          <div
            className={style.taskTracker}
            onClick={() => {
              setNextScreen("partnerPipelineReport");
              setPrevData((prevData) => ({
                ...prevData,
                page: "positionALL",
                sidebar: "reportNew",
                page1: "reportNew",
                page2: "partnerPipelineReport",
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                prevFilterData: null,
                prevFilter: null,
                active: "partnerPipelineReport",
                name: "",
              }));
            }}
          >
            <p className={style.optionsText}>Partner Pipeline Report</p>
          </div>
        </div>
      </ATSCard> */}

      <ATSCard>
        <h6>Partner Report</h6>
        <div className={style.dashboardOptions}>
          <div
            className={style.taskTracker}
            onClick={() => {
              setNextScreen("partnerPipelineReport");
              setPrevData((prevData) => ({
                ...prevData,
                page: "positionALL",
                sidebar: "reportNew",
                page1: "reportNew",
                page2: "partnerPipelineReport",
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                prevFilterData: null,
                prevFilter: null,
                active: "partnerPipelineReport",
                name: "",
              }));
            }}
          >
            <p className={style.optionsText}>Partner Pipeline Report</p>
          </div>
        </div>
      </ATSCard>
    </>
  );
};

export default ReportNewDashboard;
