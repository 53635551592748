import React, { useState } from "react";
import style from "./EmployeeExitEdit.module.css";
import SideBar from "../../CommonComponents/Sidebar/SideBar";
import { NavLink } from "react-router-dom";
import EmployeeDetails from "../EmployeeExitDetails/EmployeeDetails";
import InvoiceContainer from "../../../Employee_Induction/CommonComponent/InvoiceContainer/InvoiceContainer";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useMutation } from "react-query";
import { updateSubcategoriesStatus } from "../../../../Utils/axios/efAPI";
import { notifySuccess } from "../../../atsDashboard/utils/notify";
import moment from "moment";
import { BiSolidCommentEdit } from "react-icons/bi";

import { MdEdit } from "react-icons/md";
import ConfirmPopup from "../../../userManegment/pageLayout/viewUser/ConfirmPopup";

const EmployeeExitEdit = () => {
  const { empId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [editNotes, setEditNotes] = useState("");
  const { checklistEmployee, formality, emp } = location.state;
  const [formalityObj, setFormalityObj] = useState({
    hrManualExitPolicyStatusId: formality?.hrManualExitPolicyStatusId,
    fnfStatusId: formality?.fnfStatusId,
    exitNote: formality?.exitComment ? formality.exitComment : "",
    fnfStatusUpdatedOn: formality?.fnfStatusUpdatedOn,
    resignationAcceptanceMailDate: formality?.resignationAcceptanceMailDate
      ? formality.resignationAcceptanceMailDate
      : null,
  });
  const [updateData, setUpdateData] = useState(
    checklistEmployee?.map((item) => {
      return {
        statusId: item.statusId,
        value: item.value,
        categoryId: item?.categoryId,
        categoryName: item.categoryName,
        subCategoryName: item.subCategoryName,
        subCategoryId: item.subCategoryId,
        empId: item.empId,
        id: item.id,
      };
    })
  );
  const commonCardDesign = (categoryId) => {
    return (
      <>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label}>Status</p>
          </div>
        </div>
        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === categoryId) {
            return (
              <div className={style.Container} key={index}>
                <div className={style.payrollName}>
                  {<p>{element?.subCategoryName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done${index}`}
                        name={`ExitActivity${index}`}
                        value={1}
                        checked={updateData[index]?.statusId === 1}
                        onChange={() => {
                          let data = [...updateData];
                          data[index].statusId = 1;
                          data[index].value = "Done";
                          setUpdateData([...data]);
                        }}
                      />
                      <label htmlFor={`Done${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`IP${index}`}
                        name={`ExitActivity${index}`}
                        value={2}
                        checked={updateData[index]?.statusId === 2}
                        onChange={() => {
                          let data = [...updateData];
                          data[index].statusId = 2;
                          data[index].value = "In-Progress";
                          setUpdateData([...data]);
                        }}
                      />
                      <label htmlFor={`IP${index}`}>In-Progress</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`ExitActivity${index}`}
                        value={3}
                        checked={updateData[index]?.statusId === 3}
                        onChange={() => {
                          let data = [...updateData];
                          data[index].statusId = 3;
                          data[index].value = "N/A";
                          setUpdateData([...data]);
                        }}
                      />
                      <label htmlFor={`NA${index}`}>N/A</label>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };
  const commonFormalities = (formality) => {
    return (
      <>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> General</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label}>Status</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.payrollName2}>
            <p>Resignation Received</p>
            <p>Resignation Acceptance Mail with LWD</p>
            <p>Share HR Manual - Exit Policy</p>
            <p>FnF Status</p>
          </div>

          <div className={style.payrollStatus2}>
            <div className={style.statusContainer}>
              <div>
                {formality?.resignationDate
                  ? moment(formality?.resignationDate).format("DD MMM YYYY")
                  : "-"}
              </div>
            </div>
            <div className={style.statusContainer}>
              <div className={style.blueCalendar}>
                <input
                  className={style.inputDate}
                  type="date"
                  value={
                    formalityObj?.resignationAcceptanceMailDate
                      ? formalityObj.resignationAcceptanceMailDate
                      : null
                  }
                  onChange={(e) => {
                    setFormalityObj((prev) => {
                      return {
                        ...prev,
                        resignationAcceptanceMailDate: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={style.statusContainer}>
              <div className={style.selectItem}>
                <select
                  className={style.selectTag}
                  id="hrStatus"
                  name="hrStatus"
                  value={formalityObj?.hrManualExitPolicyStatusId}
                  onChange={(e) => {
                    setFormalityObj((prev) => {
                      return {
                        ...prev,
                        hrManualExitPolicyStatusId: e.target.value,
                      };
                    });
                  }}
                >
                  <option value={1}>Done</option>
                  <option value={2}>In-Progress</option>
                  <option value={3}>Not Applicable</option>
                </select>
              </div>
            </div>
            <div className={style.statusContainer}>
              <div className={style.selectItem}>
                <select
                  className={style.selectTag}
                  id="fnfStatus"
                  name="fnfStatus"
                  value={formalityObj?.fnfStatusId}
                  onChange={(e) => {
                    let date = moment(new Date()).format("YYYY-MM-DD");
                    setFormalityObj((prev) => {
                      if (e.target.value !== formalityObj?.fnfStatusId)
                        return {
                          ...prev,
                          fnfStatusId: e.target.value,
                          fnfStatusUpdatedOn: date,
                        };
                    });
                  }}
                >
                  <option value={4}>Done</option>
                  <option value={2}>In-Progress</option>
                  <option value={3}>Pending</option>
                  <option value={1}>Not Applicable</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const updateSubcategoriesStatusMutation = useMutation(
    updateSubcategoriesStatus,
    {
      onSuccess: (res) => {
        if (res.responseStatus.statusCode === 0)
          notifySuccess(res.responseData);
        history.push(`/ef_employeeExit/${empId}/employeeDetails`);
      },
    }
  );
  const handleEdit = () => {
    setFormalityObj((prev) => {
      return { ...prev, exitNote: editNotes };
    });
    setShowEditNotes(false);
  };
  const handleSubmit = () => {
    let sendData = {
      employeeDetailsUpdateRequest: formalityObj,
      employeeSubcategoryStatusUpdateRequests: updateData,
    };
    updateSubcategoriesStatusMutation.mutate(sendData);
  };
  return (
    <div className={style.mainSection}>
      <div style={{ width: "20%" }}>
        <SideBar />
      </div>
      <div className={style.rightSide}>
        <div className={style.upper}>
          <div className={style.upperLeft}>
            <span>
              <NavLink to={`/ef_employeeExit`}>
                {" "}
                <span style={{ color: "blue" }}>Employee Exit </span>
              </NavLink>{" "}
              {">"} Employee Exit Edit
            </span>
          </div>
          <div className={style.upperRight}>
            {/* <p
              onClick={() => {
                setShowEditNotes(true);
                setEditNotes(formalityObj?.exitNote);
                //   setEmpId(e?.id);
              }}
              title="Add or Edit Comment"
              style={{
                fontWeight: "400",
                width: "2rem",
                fontSize: "25px",
                color: "#4B59C3",
                cursor: "pointer",
              }}
            >
              <BiSolidCommentEdit
                style={{ marginLeft: "5px", color: "#4B59C3" }}
              />
            </p> */}
            <button
              onClick={() => {
                setShowEditNotes(true);
                setEditNotes(formalityObj?.exitNote);
                //   setEmpId(e?.id);
              }}
              title="Add or Edit Comment"
              style={{
                border: "1px solid #4B59C3",
                backgroundColor: "#4B59C3",
                cursor: "pointer",
              }}
            >Exit Comment</button>
          </div>
        </div>
        <div className={style.body}>
          <EmployeeDetails emp={emp} />
          <div className={style.editables}>
            <div className={style.employeeExitProcess}>
              {/* <div className={style.heading}>Employee Exit Process</div> */}
              <InvoiceContainer>
                {commonFormalities(formality)}
              </InvoiceContainer>
            </div>
            <div className={style.employeeExitProcess}>
              <div className={style.heading}>IT Team</div>
              <InvoiceContainer>{commonCardDesign(1)}</InvoiceContainer>
            </div>
            <div className={style.employeeExitProcess}>
              <div className={style.heading}>Hr Operations ( On Exit Day )</div>
              <InvoiceContainer>{commonCardDesign(2)}</InvoiceContainer>
            </div>
            <div className={style.employeeExitProcess}>
              <div className={style.heading}>Hr Operations After Exit ( After Exit )</div>
              <InvoiceContainer>{commonCardDesign(3)}</InvoiceContainer>
            </div>
            <div className={`${style.employeeExitProcess} ${style.action}`}>
              <button
                className={style.button}
                onClick={() =>
                  history.push(`/ef_employeeExit/${empId}/employeeDetails`)
                }
              >
                Cancel
              </button>
              <button className={style.button} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmPopup open={showEditNotes}>
        <div className={style.confirmPopup1}>
          <div>
            <p className={style.modalMainHeading}>Edit Exit Note</p>
            <textarea
              className={style.textarea}
              value={editNotes}
              onChange={(e) => {
                setEditNotes(e.target.value);
              }}
            ></textarea>
          </div>
          <div style={{ textAlign: "end", marginTop: "1rem" }}>
            <button
              className={style.delete_btn}
              onClick={() => (
                setShowEditNotes(false), setEditNotes(formalityObj?.exitNote)
              )}
            >
              Cancel
            </button>
            <button className={style.delete} onClick={handleEdit}>
              Add
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

export default EmployeeExitEdit;
