import React, { useState } from "react";
import Header from "../component/Header/Header";
import style from "./ResourcePriceListing.module.css";
import Sidebar from "../component/Sidebar/Sidebar";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";
import Pagination from "../../ClientQuestionnaire/CommonComponent/Pagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getClientList,
  getEndMonthList,
  getPriceList,
  getStartMonthList,
  getSalesRepList,
} from "../../../Utils/axios/priceRevisionApi";
import { useQuery } from "react-query";
import moment from "moment";
import { checkIfEmpty, encrypted_value } from "../../../Utils/constants";
import Loading from "../component/Loading/Loading";
import ReasonImg from "../../../assets/Reason.png";
import { Modal } from "react-bootstrap";

const ResourcePriceListing = (props) => {
  const [filterData, setFilterData] = useState(
    props?.history?.location?.state?.allData
      ? props?.history?.location?.state?.allData
      : {
          revised: "",
          client: "",
          clientId: "",
          contractEndMonth: "",
          contractEndMonthId: "",
          resourceStartMonth: "",
          resourceStartMonthId: "",
          salesRep: "",
          salesRepId: "",
        }
  );

  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");

  const history = useHistory();

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const { data, isLoading } = useQuery(
    ["getPriceList", { page, filterData }],
    () =>
      getPriceList(
        10,
        page,
        filterData?.revised,
        filterData?.clientId,
        filterData?.contractEndMonthId,
        filterData?.resourceStartMonthId,
        filterData?.salesRepId
      )
  );

  const revisedOption = [
    { id: 1, label: "Done" },
    { id: 2, label: "Defer" },
  ];

  const { data: clientList } = useQuery(["getClientList"], () =>
    getClientList()
  );

  const clientListOption = Array.isArray(clientList)
    ? clientList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: startMonthList } = useQuery(["getStartMonthList"], () =>
    getStartMonthList()
  );

  const startMonthOption = Array.isArray(startMonthList)
    ? startMonthList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: endMonthList } = useQuery(["getEndMonthList"], () =>
    getEndMonthList()
  );

  const endMonthOption = Array.isArray(endMonthList)
    ? endMonthList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: getSalesRepListData } = useQuery(["getSalesRepList"], () =>
    getSalesRepList()
  );

  const salesRepOption = Array.isArray(getSalesRepListData)
    ? getSalesRepListData?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <div className={style.price_listing_main_div}>
        <div className={style.sidebar_div}>
          <Sidebar />
        </div>
        <div className={style.main_div}>
          <div className={style.main_div_heading}>
            <div className={style.main_left_div}>
              <h1 className={style.price_heading}>Resource Pricing List</h1>
            </div>
            <p className={style.price_count}>{data?.resourcesCount}</p>
            <div className={style.main_right_div}>
              <div className={style.dropdown_div1}>
                <CommonDropdown
                  options={revisedOption}
                  color="#00979d"
                  placeholder="Revised"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      revised: e?.label,
                    })
                  }
                  value={checkIfEmpty(filterData?.revised, {
                    label: filterData?.revised,
                  })}
                  customInputOnClick={() =>
                    setFilterData({ ...filterData, revised: "" })
                  }
                />
              </div>

              <div className={style.dropdown_div}>
                <CommonDropdown
                  options={clientListOption}
                  color="#00979d"
                  placeholder="Client"
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      client: e?.label,
                      clientId: e?.id,
                    });
                  }}
                  value={checkIfEmpty(filterData?.clientId, {
                    id: filterData?.clientId,
                    label: filterData?.client,
                  })}
                  customInputOnClick={() =>
                    setFilterData({ ...filterData, client: "", clientId: "" })
                  }
                />
              </div>

              <div className={style.dropdown_div}>
                <CommonDropdown
                  color="#00979d"
                  placeholder="Cont. End M"
                  options={endMonthOption}
                  value={checkIfEmpty(filterData?.contractEndMonthId, {
                    id: filterData?.contractEndMonthId,
                    label: filterData?.contractEndMonth,
                  })}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      contractEndMonthId: e?.id,
                      contractEndMonth: e?.label,
                    });
                  }}
                  customInputOnClick={() =>
                    setFilterData({
                      ...filterData,
                      contractEndMonthId: "",
                      contractEndMonth: "",
                    })
                  }
                />
              </div>

              <div className={style.dropdown_div2}>
                <CommonDropdown
                  color="#00979d"
                  placeholder="Emp Start M"
                  options={startMonthOption}
                  value={checkIfEmpty(filterData?.resourceStartMonthId, {
                    id: filterData?.resourceStartMonthId,
                    label: filterData?.resourceStartMonth,
                  })}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      resourceStartMonthId: e?.id,
                      resourceStartMonth: e?.label,
                    });
                  }}
                  customInputOnClick={() =>
                    setFilterData({
                      ...filterData,
                      resourceStartMonth: "",
                      resourceStartMonthId: "",
                    })
                  }
                />
              </div>

              <div className={style.dropdown_div2}>
                <CommonDropdown
                  color="#00979d"
                  placeholder="Sales Rep"
                  options={salesRepOption}
                  value={checkIfEmpty(filterData?.salesRepId, {
                    id: filterData?.salesRepId,
                    label: filterData?.salesRep,
                  })}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      salesRepId: e?.id,
                      salesRep: e?.label,
                    });
                  }}
                  customInputOnClick={() =>
                    setFilterData({
                      ...filterData,
                      salesRep: "",
                      salesRepId: "",
                    })
                  }
                />
              </div>
            </div>
          </div>

          {data?.resourcesCount > 0 ? (
            <div className={style.table_div}>
              <table className={style.table}>
                <thead className={style.table_heading}>
                  <tr>
                    <th
                      className={`${style.table_head} ${style.sticky_head_code}`}
                    >
                      Code
                    </th>
                    <th
                      className={`${style.table_head} ${style.sticky_head_col1}`}
                    >
                      Name
                    </th>
                    <th
                      className={`${style.table_head} ${style.sticky_head_col2}`}
                    >
                      Resource
                      <br /> Start Date
                    </th>
                    <th className={style.table_head}>Start Month</th>
                    <th className={style.table_head}>Client</th>
                    <th className={style.table_head}>Sales Rep</th>
                    <th className={style.table_head}>
                      Contract
                      <br />
                      (Start Date)
                    </th>
                    <th className={style.table_head}>
                      Contract
                      <br />
                      (End Date)
                    </th>
                    <th className={style.table_head}>
                      Last Revision
                      <br /> Date
                    </th>
                    <th className={style.table_head}>
                      Last Client
                      <br /> Rate
                    </th>
                    <th className={style.table_head}>
                      Current Client
                      <br /> Rate
                    </th>
                    <th className={style.table_head}>Period </th>
                    <th className={style.table_head}>Revised</th>
                    <th className={style.table_head}>Reason</th>
                    <th className={style.table_head}>Approved By</th>
                    <th className={style.table_head}>
                      Rate Revision <br /> Due/Review Date
                    </th>
                    <th className={style.table_head}>Contract URL</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  {data?.resources?.map((item, idx) => (
                    <TableRow
                      key={idx}
                      item={item}
                      setShow={setShow}
                      setReason={setReason}
                      filterData={filterData}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={style.no_data_div}>No Data Found</div>
          )}
          {data?.resourcesCount > 10 && (
            <div className={style.pagination_div}>
              <Pagination
                onPageChange={handlePageChange}
                currentPage={page}
                pagesCount={data?.resourcesCount / 10}
                color="#00979D"
                color2="white"
              />
            </div>
          )}
        </div>
      </div>
      <Modal show={show} centered size="md" onHide={handleClose}>
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#00979d" }}>Reason</h6>
        </Modal.Header>
        <div className={style.modal_para_div}>
          {reason
            ? reason?.split("\n")?.map((line, index) => (
                <>
                  {line}
                  {index !== reason?.split("\n")?.length - 1 && <br />}
                </>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};

const TableRow = ({ item, filterData, setShow, setReason }) => {
  const history = useHistory();
  const handleHistory = () => {
    history.push({
      pathname: `/resource_details`,

      search: `?clientId=${encrypted_value(
        item?.clientId
      )}&resourceId=${encrypted_value(item?.resourceId)}`,

      state: {
        revised: filterData?.revised,
        client: filterData?.client,
        clientId: filterData?.clientId,
        contractEndMonth: filterData?.contractEndMonth,
        contractEndMonthId: filterData?.contractEndMonthId,
        resourceStartMonth: filterData?.resourceStartMonth,
        resourceStartMonthId: filterData?.resourceStartMonthId,
        salesRepId: filterData?.salesRepId,
        salesRep: filterData?.salesRep,
      },
    });
  };
  return (
    <tr>
      <td className={`${style.table_column} ${style.sticky_column_code}`}>
        <p title={item?.resourceCode} className={style.code_para}>
          {item?.resourceCode}
        </p>
      </td>
      <td className={`${style.table_column} ${style.sticky_column1}`}>
        <p title={item?.resourceName} className={style.resource_para}>
          <span
            onClick={() => handleHistory()}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleHistory();
              }
            }}
            style={{ cursor: "pointer", fontSize: "0.8rem" }}
          >
            {item?.resourceName}
          </span>
        </p>
      </td>
      <td className={`${style.table_column} ${style.sticky_column2}`}>
        {item?.resourceStartDate
          ? moment(item?.resourceStartDate)?.format("DD MMM YY")
          : "NA"}
      </td>
      <td className={style.table_column}>{item?.startMonth || "NA"}</td>
      <td className={style.table_column}>{item?.clientName || "NA"}</td>
      <td className={style.table_column}>{item?.salesRep || "NA"}</td>
      <td className={style.table_column}>
        {item?.contractStartDate
          ? moment(item?.contractStartDate)?.format("DD MMM YY")
          : "NA"}
      </td>
      <td className={style.table_column}>
        {item?.ongoingStatus
          ? "Ongoing"
          : item?.contractEndDate
          ? moment(item?.contractEndDate)?.format("DD MMM YY")
          : "NA"}
      </td>
      <td className={style.table_column}>
        {item?.lastRevisionDate
          ? moment(item?.lastRevisionDate)?.format("DD MMM YY")
          : "NA"}
      </td>
      <td className={style.table_column}>{item?.lastClientRate || "NA"}</td>
      <td className={style.table_column}>{item?.currentClientRate || "NA"}</td>
      <td className={style.table_column}>{`${item?.period}` || "NA"}</td>
      <td className={style.table_column}>
        {item?.isRevised !== null
          ? item?.isRevised === true
            ? "Done"
            : "Defer"
          : "NA"}
      </td>
      <td className={style.table_column}>
        {item?.reason ? (
          <img
            onClick={() => {
              setShow(true);
              setReason(item?.reason);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setShow(true);
                setReason(item?.reason);
              }
            }}
            className={style.reason_img}
            src={ReasonImg}
            alt="img"
          />
        ) : (
          "NA"
        )}
      </td>
      <td className={style.table_column}>
        {item?.approvedByName ? item?.approvedByName : "NA"}
      </td>
      <td
        className={style.table_column}
        style={
          item?.isRateReviewDateManually
            ? { color: "#00979d", fontWeight: "500" }
            : null
        }
      >
        {item?.rateRevisionDueDate
          ? moment(item?.rateRevisionDueDate)?.format("DD MMM YY")
          : "NA"}
      </td>
      <td className={style.table_column}>
        {item?.contractUrl ? (
          <a href={item?.contractUrl} target="_blank" rel="noreferrer">
            Contract info
          </a>
        ) : null}
      </td>
    </tr>
  );
};

export default ResourcePriceListing;
