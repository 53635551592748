//AllIssueComp
import React, { useState, useEffect, useRef } from "react";
import style from "./groupList.module.css";
import SideBar from "../../commonComponent/SideBar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Filter from "../../myTasks/component/Filter";
import Moment from "moment";
import constants from "../../../../Utils/constants";
import cookie from "react-cookies";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import eyeIcon from '../../../../assets/visible.svg';

// import Pagination from "../../../commonComponent/Pagination";
import sortDownarrowImg from "./../../../../assets/Down.png";
import sortUparrowImg from "./../../../../assets/Up.png";
import loaderImg from "./../../../../assets/loader-green2.gif";
import Polygon from "./../../../../assets/Polygon.png";
import Iconmetrocross from "../../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import filterimg from "./../../../../assets/filter-results-button.png";
import Iconfeatherfilter from "../../../../assets/Iconfeatherfilter.png";
import Pagination from "../../commonComponent/Pagination";
import { employeeId } from "../../../../Utils/constants";
import ConfirmPopup from "../../taskAssignedByMe/component/ConfirmPopup";
// import { FaPeopleGroup } from "react-icons/fa6";
import { IoMdPeople } from "react-icons/io";
import { customSearch } from "../../../atsDashboard/utils/style";
// import {
//   employeeId,
//   localStorageGetItem,
// } from "../../../../../Utils/constants";
import { AiOutlineClose } from "react-icons/ai";
import searchIcon from "../../../../assets/Icon-search.png";

export const GetAllGroupList = (props) => {
  const location = useLocation();
  const [GroupsList, setgroupList] = useState([1, 2]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [applied, setApplied] = useState(false);
  const [priority, setPriority] = useState([]);
  const [data, setData] = useState();
  const [assigned, setAssigned] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalIssue, settotalIssue] = useState(0);
  const [status, setStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [members,setMembers] = useState([])
  const [allData, setAllData] = useState({
    assignedBy: [],
    assignedTo: [],
    priority: [],
    status: ["Assigned", "Done", "Onhold"],
    creationDateOrder: null,
  });
  const [showAll, setShowAll] = useState(false)
  const [label, setLabel] = useState({ raiseBy: "" });
  const runOnceRef = useRef(true)
  // onSearchHandler
  const limit = 10;
  const url2 = constants.apiBaseUrl2;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleTask = () => {
    history.push({
      pathname: "/taskTracker/createTask",
      state: {
        groupId: location?.state?.groupId,
      },
    });
  };
  const getaskAssignedByFilterData = () => {
    axios
      .get(url2 + `/assignedByByGroupId?groupId=${location?.state?.groupId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.responseData);
      })
      .catch(() => { });
  };
  const viewGroupDetailsByIdData = () => {
    axios
      .get(url2 + `/viewGroupDetailsById?groupId=${location?.state?.groupId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMembers(res.data.responseData.groupMembers);
      })
      .catch(() => { });
  };
  const handleTaskDetail = (arg) => {
    history.push({
      pathname: "/taskAssignedByMeDetails",
      state: {
        task: arg,
        activePage: 2,
      },
    });
  };
  const handleBack = () => {
    history.push("/Groups");
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSorting = () => {
    setSortOrder(
      sortOrder === null ? "ASC" : sortOrder === "ASC" ? "DESC" : "ASC"
    );
  };

  const getGroupDashboardList = () => {
    setIsLoading(true);
    axios
      .post(
        url2 +
        `/taskListByGroupId?groupId=${location?.state?.groupId
        }&limit=${10}&page=${currentPage}`,
        allData // Include allData in the request body
      )
      .then((res) => {
        setgroupList(res.data.responseData);
        setIsLoading(false);
        setpageCount(res.data.responseData.totalTasks / 10);
        settotalIssue(res.data.responseData.totalTasks);
      })
      .catch(() => {  
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setAllData({
      ...allData,
      creationDateOrder:
        sortOrder == "ASC" ? true : sortOrder == "DESC" ? false : null,
    });
  }, [sortOrder]);
  useEffect(() => {
    if(showAll) {
        setAllData(prev => ({...prev, status: ["Assigned", "Done", "Onhold", "Closed"]}))
        setApplied(true)
    }else {
      if(allData?.assignedBy?.length === 0 && allData?.assignedTo?.length === 0 && allData?.priority?.length === 0 && !allData?.creationDateOrder) {
        setApplied(false)
      }
      setApplied(true)
      if(allData?.status && allData?.status.includes("Assigned")  && allData?.status.includes("Done")  && allData?.status.includes("Onhold") ) {
        setAllData(prev => ({...prev, status: ["Assigned", "Done", "Onhold",]}))
      }
      if(runOnceRef.current) {
        setAllData(prev => ({...prev, status: ["Assigned", "Done", "Onhold",]}))
        runOnceRef.current = false
      }
    }
  }, [showAll])

  const BlackTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#fff",
      fontSize: "13px",
      whiteSpace: "pre-line",
      padding: "0.5rem",
    },
  }))(Tooltip);
  useEffect(() => {
    getGroupDashboardList();
    getaskAssignedByFilterData();
    viewGroupDetailsByIdData();
  }, [currentPage, allData]);

  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          assignedBy: [],
          priority: [],
          status: [],
          assignedTo: [],
        }
        : null;
    });

    setShowFilter(false);
    // setApplied(false);
      setShowAll(true)
      setAllData(prev => ({...prev, status: ["Assigned", "Done", "Onhold", "Closed"]}))
  };
  const handleApplied = (arg) => {
    setCurrentPage(1);
    if (
      allData?.assignedBy.length !== 0 ||
      allData?.assignedTo.length !== 0 ||
      allData?.priority.length !== 0 ||
      allData?.status.length !== 0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    getGroupDashboardList();
    if(status?.length === 4 || status.length === 0) {
      if(showAll){
        setAllData(prev => ({...prev, status: ["Assigned", "Done", "Onhold", "Closed"]}))
      }
      setShowAll(true)
    }else {
      setShowAll(false)
    }
  };

  const handleShowAll = (e) => {
    setShowAll(e.target.checked)
  }
  return (
    <>
      <div className={style.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
          <div className={style.main}>
            <div className={style.header}>
              <div className={style.alignGroupIssue}>
                <span>{location?.state?.Groups}</span>
                <span className={style.circle}>{totalIssue}</span>
                <span onClick={() => setShowModal(true)} style={{marginLeft:"5px",cursor:"pointer"}} title="View Group Members"><IoMdPeople style={{ fontSize: "30px",color: "#65a15e"}} /></span>
              </div>
              <div className={style.checkboxContainer}>
              <input className={style.showAllCheckBox} id="showAll" type="checkbox" style={{visibility: "visible", accentColor:"#65A15E"}}
          checked={showAll}
          onChange={handleShowAll} />
                <span className={style.label}>Show All</span>
              </div>
              <button className={style.filter} onClick={handleFilter}>
                {applied && (
                  <img
                    className={style.appliedcheck}
                    src={Iconionicmdcheckbox}
                  />
                )}
                <img src={filterimg} />
                <span className={style.filterColor}>Filter</span>
              </button>
              {showFilter && <img className={style.polygon} src={Polygon} />}
              <div className={style.s1} style={{marginTop: "0"}}>
              {/* 1 */}
              {!allData?.title ? (
                <span className={style.cp_serchIcon}>
                  <img src={searchIcon} alt={"searchIcon"} />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon}
                  onClick={() => {
                    setAllData(prev => ({...prev, title: ""}))
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <input
                className={style.coreSkillInput}
                placeholder="Search Title"
                value={allData?.title}
                onChange={(e) => {
                  setCurrentPage(1);
                  setAllData(prev => ({...prev, title: e.target.value}))
                }}
              />
            </div>
              <div>
                <button onClick={handleTask} className={style.createTask}>
                  <img
                    style={{ width: "15px", marginRight: "10px" }}
                    alt="Iconmetro"
                    src={Iconmetrocross}
                  />
                  <span>Create Task</span>
                </button>
              </div>
            </div>
            <div className={style.IssueList}>
              {isLoading ? (
                <div>
                  <img src={loaderImg} alt="loader" className={style.loader} />
                  <p className={style.loaderPara}>Loading...</p>
                </div>
              ) : (
                <>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr className={style.th}>
                        <th
                          style={{ width: "4rem", padding: "4px" }}
                        >Task<br /> ID</th>
                        <th
                          style={{ width: "7.5rem" }}
                        >
                          Creation
                          Date  
                          <img
                            alt=""
                            style={{
                              cursor: "pointer",
                              position: "relative",
                              bottom: "0px",
                              left: "5px",
                            }}
                            src={
                              sortOrder === "ASC"
                                ? sortUparrowImg
                                : sortDownarrowImg
                            }
                            onClick={handleSorting}
                          />
                        </th>
                        <th className={style.tableContent}>Task Title</th>
                        <th className={style.tableContent} style={{ paddingLeft: "4px" }}>Assigned To</th>
                        <th className={style.tableContent}>Assigned By</th>
                        <th className={style.tableContent}>Status</th>

                        <th className={style.tableContent}>Priority</th>
                        <th className={style.tableContent}>
                          Planned <br />
                          End Date
                        </th>
                      </tr>
                    </thead>

                    {/* {allIssue?.issues?.length === 0 ? ( */}
                    {GroupsList?.tasks?.length === 0 ? (
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <p className={style.noData}>No Data Available</p>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody
                        style={{ height: "20px" }}
                      >
                        {GroupsList?.tasks?.map((task) => (
                          <tr key={task.taskId} className={style.td}>
                            <td
                              style={{ width: "4rem", padding: "4px" }}
                            >
                              {task.taskId}
                            </td>
                            <td
                              className={style.tableContent}
                              style={{ width: "7.5rem" }}
                            >
                              {Moment(task.created).format("DD MMM")}
                            </td>

                            {/* {task.title.length >= 40 ? (
                              <td
                                className={[
                                  style.tableContent,
                                  style.taskTitle,
                                ].join(" ")}
                                onClick={() => handleTaskDetail(task)}
                                style={{width:"9.3rem"}}
                              >
                                <BlackTooltip title={task?.title}>
                                  <p className={style.tooltips}>
                                    {task?.title}...
                                  </p>
                                </BlackTooltip>
                              </td>
                            ) : (
                              <td
                                className={[
                                  style.tableContent,
                                  style.taskTitle,
                                ].join(" ")}
                                onClick={() => handleTaskDetail(task)}
                              >
                                {task.title}
                              </td>
                            )} */}
                            <td

                              className={style.tabletaskTitle}
                              onClick={() => handleTaskDetail(task)}
                            >
                              <b>{task.title}</b>
                            </td>
                            <td className={style.tableContent} style={{ paddingLeft: "4px" }}>{task.name}</td>
                            <td className={style.tableContent}>
                              {task.assignedBy}
                            </td>
                            <td
                              className={
                                task.status === "Assigned"
                                  ? style.asigned_color
                                  : task.status === "Done"
                                    ? style.done_color
                                    : task.status === "Onhold"
                                      ? style.onhold_color
                                      : style.Closed_color
                              }
                            >
                              {task.status === "Closed"
                                ? "Closed"
                                : task.status === "Onhold"
                                  ? "On Hold"
                                  : task.status}
                            </td>
                            <td className={style.tableContent}>
                              {task.priority}
                            </td>

                            <td className={style.tableContent}>
                              {Moment(task.planedEndDate).format("DD MMM")}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </>
              )}
            </div>
            <div className={style.paginationalign}>
              <button className={style.goBack} onClick={() => handleBack()}>
                Back
              </button>
              {totalIssue > limit && pageCount > 0 && (
                <div
                  className={style.paginationmobileContainer}
                  style={{ marginTop: "20px" }}
                >
                  <Pagination
                    pagesCount={pageCount}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          userLists={userLists}
          filterData={allData}
          setassigned={setAssigned}
          setpriority={setPriority}
          setstatus={setStatus}
          data={data}
          groupId={location?.state?.groupId}
        />
      )}
      <ConfirmPopup open={showModal}>
        <div className={style.modal_group_member}>
          <div className={style.upperPortion}>
          <p className={style.modalHeading}>Group Members</p>
          <p className={style.circle}>{members?.length}</p>
          </div>
          <ol start="1">
           {members?.map((item)=>{
            return <li key={item?.id}>{item?.name}</li>
           })}
          </ol>
          <div className={style.modal_btn}>
            <div className={style.button}>
              <button
                className={style.w_inactive}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>

      </ConfirmPopup>
    </>
  );
};
