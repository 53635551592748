import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../constants";
const apiKey = process.env.REACT_APP_ATS_APP_URL;
const url1 = process.env.REACT_APP_EXIT_FORMALITIES_URL;
const url2 = process.env.REACT_APP_UMS_APP_URL;



const url = process.env.REACT_APP_UMS_APP_URL;

export const getEmpRoleByEmpId = async () => {
  const response = await axios.get(`${apiKey}/getEmpRoleByEmpId?empId=${employeeId}`);
  return response.data.responseData;
};

export const getAllUsersName = async () => {
  const response = await axios.get(url + `/getAllUsersName`);
  return response.data.responseData;
};
export const getAllTechnology = async () => {
  const response = await axios.get(url + `/getAllTechnology`);
  return response.data.responseData;
};

export const getAllTechnologyforFilter = async (filterForExited) => {
  const response = await axios.get(url + `/getTechnologyForFilter?filterForExited=${filterForExited}`);
  return response.data.responseData;

};
// export const getType = async () => {
//   const response = await axios.get(url + `/getAllType`);
//   return response.data.responseData;
// };

export const getRole = async () => {
  const response = await axios.get(url + `/getAllEmployeeRole`);
  return response.data.responseData;
};

export const createUser = async (data) => {
  const response = await axios.post(url + "/submitUser", data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data;
};
export const assignLeadToEmployee = async ({ empId, leadEmpId }) => {
  const response = await axios.post(
    url + `/assignLeadToEmployee?empId=${empId}&leadEmpId=${leadEmpId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    }
  );
  return response.data;
};
export const getAllUsersFilterByStatus = async (data) => {
  const response = await axios.post(url + `/getAllUsersFilterByStatus`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data;
};

export const viewUserDetailsById = async (id) => {
  const response = await axios.get(url + `/getUserById?useId=${id}`);
  return response.data.responseData;
};
export const getAllType = async (id) => {
  const response = await axios.get(url + `/getAllType`);
  return response.data.responseData;
};

export const getAllUsersWithFilter = async (limit, page, filter) => {
  const response = await axios.post(
    url + `/getAllUsersWithFilter?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const getSalaryRange = async () => {
  const response = await axios.get(url + `/getSalaryRange`);
  return response?.data?.responseData;
};
export const getAllPartnerList = async () => {
  const response = await axios.get(url + `/getAllPartnerList`);
  return response?.data?.responseData;
};
export const getAllFunctionalArea = async () => {
  const response = await axios.get(url + `/getAllFunctionalArea`);
  return response?.data?.responseData;
};

export const getAllLeads = async () => {
  const response = await axios.get(url + `/get-all-leads`);
  return response?.data?.responseData;
};
export const markAsExit = async (data) => {
  const response = await axios.post(url + `/markAsExit`, data);
  return response;
};
export const addSkill = async ({ skill }) => {
  const response = await axios.post(url + `/addSkill?skill=${skill}`);
  return response?.data?.responseData;
};
export const markAsRetain = async (data) => {
  const response = await axios.post(url + `/markAsRetain`, data);
  return response?.data?.responseData;
};
export const deleteEmployee = async ({ employeeId }) => {
  const response = await axios.delete(
    url + `/deleteEmployee?employeeId=${employeeId}`
  );
  return response?.data?.responseData;
};
export const getConfirmationPeriod = async () => {
  const response = await axios.get(url + `/getConfirmationPeriod`);
  return response?.data?.responseData;
};
export const addConfirmation = async (data) => {
  const response = await axios.post(url + `/add-confirmation`, data);
  return response?.data?.responseData;
};
export const getClientList = async () => {
  const response = await axios.get(url + `/getClientList`);
  return response?.data?.responseData;
};
export const getClientListForFilter = async (filterForExited) => {
  const response = await axios.get(url + `/get-client-list-for-filter?filterForExited=${filterForExited}`);
  return response?.data?.responseData;
};
export const assignClientToEmployee = async (data) => {
  const response = await axios.post(
    url + `/assignClientToEmployee?clientId`,
    data
  );
  return response?.data?.responseData;
};
export const getReportNew = async (year) => {
  const response = await axios.get(url + `/getReportNew?year=${year}`);
  return response?.data?.responseData;
};
export const getAllTeams = async () => {
  const response = await axios.get(url + `/get-all-teams`);
  return response?.data?.responseData;
};
export const assignTeamToEmployee = async ({ empId, teamId }) => {
  const response = await axios.get(
    url + `/assign-team-to-employee?empId=${empId}&teamId=${teamId}`
  );
  return response?.data?.responseData;
};
export const getNextMonthAnniversaries = async () => {
  const response = await axios.get(url + `/get-next-month-anniversaries`);
  return response?.data?.responseData;
};
export const getPendingExit = async (limit, page, typeId) => {
  const response = await axios.get(
    url + `/get-pending-exit?limit=${limit}&page=${page}&typeId=${typeId}`
  );
  return response?.data?.responseData;
};
export const editExitComment = async (data) => {
  const response = await axios.put(url + `/edit-exit-comment`, data);
  return response?.data?.responseData;
};

export const getConfirmationList = async (page) => {
  const response = await axios.get(
    url + `/get-confirmation-list?limit=10&page=${page}`
  );
  return response?.data?.responseData;
};
export const updateConfirmationComment = async ({ comments, empId }) => {
  const response = await axios.post(
    url + `/update-confirmation-comment?comment=${comments}&empId=${empId}`
  );
  return response?.data?.responseData;
};
export const createBGV = async (data) => {
  const response = await axios.post(url + `/createBGV`, data);
  return response?.data?.responseData;
};
export const getBGVByEmployee = async (id) => {
  const response = await axios.get(url + `/getBGVByEmployee?empId=${id}`);
  return response?.data?.responseData;
};

export const getNewJoiners = async (page, month, type) => {
  let baseUrl = url + `/get-new-joiners?limit=10&page=${page}&type=${type}`;
  if (month) {
    baseUrl += `&month=${month}`;
  }
  const response = await axios.get(baseUrl);
  return response?.data?.responseData;
};

export const getLastThreeMonthExited = async (page, month, typeId) => {
  let baseUrl =
    url + `/get-last-3-months-exited-employees?limit=10&page=${page}`;
  if (month || month === 0) {
    baseUrl += `&month=${month}`;
  }
  if (typeId) {
    baseUrl += `&typeId=${typeId}`;
  }
  const response = await axios.get(baseUrl);
  return response?.data?.responseData;
};

export const getAllExitEmployeeReport = async (limit, page, month, fnfStatusId, employeeId) => {
  let baseUrl =
    url +
    `/get-all-exit-employee-report?limit=${limit}&month=${month}&page=${page}&fnfStatusId=${fnfStatusId}&employeeId=${employeeId}`;

  const response = await axios.get(baseUrl);
  return response?.data?.responseData;
};

export const getPartnerListForFilter = async () => {
  const response = await axios.get(url + `/get-partner-list-for-filter`);
  return response?.data?.responseData;
};
export const bgvStatusReport = async (page, bgvStatus, empId, empTypeId) => {
  bgvStatus = bgvStatus ? bgvStatus?.map((e) => e?.value) : bgvStatus
  empTypeId = empTypeId ? empTypeId?.map((e) => e?.value) : empTypeId
  const response = await axios.get(url + `/bgv-status-report?limit=10&page=${page}&bgvStatus=${bgvStatus}&empId=${empId}&empTypeId=${empTypeId}`);
  return response?.data?.responseData;
};
export const getReviseSalaryHistory = async (limit, empId, page) => {
  const response = await axios.get(url + `/get-revise-salary-history?empId=${empId}&limit=${limit}&page=${page}`);
  return response?.data?.responseData?.responseData
    ;
};
export const downloadEmployeeBgvList = async () => {
  const response = await axios.get(url + `/downloadEmployeeBgvList`);
  return response?.data?.responseData
    ;
};

export const getAddCountsForDashboard = async () => {
  const response = await axios.get(url + `/getAddCountsForDashboard`);
  return response?.data?.responseData;
}

export const getfaSkillDashboard = async (value) => {
  const response = await axios.get(url + `/get-fa-skill-dashboard?value=${value}`);
  return response?.data?.responseData;
}
export const getEmployeeHistoryDetails = async (id) => {
  const response = await axios.get(url + `/getEmployeeHistoryDetails?empId=${id}&limit=15`
  );
  return response.data.responseData;
};
export const getAllFnFStatus = async (id) => {
  const response = await axios.get(url + `/getAllFnFStatus`
  );
  return response.data.responseData;
};
export const getCountByFnfStatus = async (id) => {
  const response = await axios.get(url2 + `/getCountByFnfStatus`
  );
  return response.data.responseData;
};

export const getInActiveEmployee = async (id) => {
  const response = await axios.get(url2 + `/getAllInactiveEmployees`
  );
  return response.data.responseData;
};


export const addFnFStatus = async (data) => {
  const response = await axios.put(url + `/addFnFStatus`, data);
  return response?.data?.responseData;
};
export const editExitEmployees = async (data) => {
  const response = await axios.put(url + `/editExitEmployees`, data);
  return response?.data;
};
export const findCountByGender = async (year) => {
  const response = await axios.get(url + `/findCountByGender`);
  return response?.data?.responseData;
};
export const getAttritionReport = async (year) => {
  const response = await axios.get(url + `/getAttritionReport?openingBalance=0&year=${year}`);
  return response?.data?.responseData;
};
export const getSalaryReport = async () => {
  const response = await axios.get(url + `/getSalaryReport`);
  return response?.data?.responseData;
};
export const getHierarchy = async () => {
  const response = await axios.get(url + `/getHierarchy`);
  return response?.data?.responseData;
};
export const getAllSalaryMasterWithFilter = async (limit, page, empId, empTypeId, startDate, endDate, sortBy, variableComponentFilter, isPagination) => {
  const response = await axios.get(
    url + `/get-salary-master-for-active-employees?empTypeId=${empTypeId}&employeeId=${empId}&isPagination=${isPagination}&limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}&variableComponentFilter=${variableComponentFilter}`);
  return response.data.responseData;
};

export const getAllSalaryMasterWithFilterDownload = async (limit, page, empId, empTypeId, startDate, endDate, sortBy, isPagination) => {
  const response = await axios.get(
    url + `/get-salary-master-for-active-employees?empTypeId=${empTypeId}&employeeId=${empId}&isPagination=${isPagination}&limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}`);
  return response.data.responseData;
};