import React from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../ViewPartner/ViewPartner.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import secureLocalStorage from "react-secure-storage";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import BusinessIcon from "@mui/icons-material/Business";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useState, useRef } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import {
  getViewPartner,
  getOverAllPartnerRating,
  addPartnerRating,
  validateEmployeeAccess,
  createNote,
  getNoteList,
  deleteNote,
  deleteVendorById,
  getLeadEmployeeList,
  changeRecruiterAllocation,
  getPartnerOnBoardingHistoryList,
} from "../../../../Utils/axios/PortalManagement";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import { Toastify, ToastifyError } from "../../../../App.js";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  checkIfEmpty,
  employeeId,
  errorToast,
  successToast,
} from "../../../../Utils/constants";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";
import CopyEmail from "../../../../GenericComponent/CopyEmailFeature/CopyEmail.js";

const ViewPartner = ({ setNextScreen, prevData, setPrevData }) => {
  const bottomRef = useRef(null);
  const queryClient = useQueryClient();
  const [showComment, setShowComment] = useState(false);
  const [editId, setEditId] = useState(null);
  const [note, setNote] = useState("");
  const [show, setShow] = useState(false);
  const [RatingChange, setRatingChange] = useState(false);
  const [isNoteEdit, setIsNoteEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showRecruiter, setShowRecruiter] = useState(false);
  const [leadDetails, setLeadDetails] = useState({
    leadId: "",
    leadName: "",
  });
  const [formData, setFormData] = useState({
    note: "",
    partnerId: prevData?.partnerId,
    partnerRatingId: "",
    reviewerId: Number(employeeId),
  });

  const { data: PartnerDataById, refetch } = useQuery(
    ["getViewPartner1"],
    () => getViewPartner(prevData?.partnerId),
    {
      onSuccess: (res) => {
        setPrevData((prevData) => ({
          ...prevData,
          status: res?.statusId,
          specializationExistId: res?.specializationExist,
        }));
      },
    }
  );

  const { data: getNoteListData } = useQuery(["getNoteList"], () =>
    getNoteList(prevData?.partnerId)
  );

  const { data: getLeadEmployeeListData } = useQuery(
    ["getLeadEmployeeList"],
    () => getLeadEmployeeList(employeeId)
  );

  const getLeadEmployeeOptions = getLeadEmployeeListData?.map((item) => {
    return {
      id: item?.id,
      label: item?.name,
    };
  });

  const {
    data: OverAllPartnerRating,
    isLoading,
    isError,
    refetch: RatingRefetch,
  } = useQuery(
    ["getOverAllPartnerRating"],
    () => getOverAllPartnerRating(prevData?.partnerId),
    {}
  );

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  const { data: getOnBoardingHistory, isLoading1, isError1, refetch: onBoardingRefetch } = useQuery(
    ["getPartnerOnBoardingHistoryList"],
    () => getPartnerOnBoardingHistoryList(prevData?.partnerId),
    {}
  );
  const onBoardingDate = getOnBoardingHistory && getOnBoardingHistory[0]?.status === 'Active' ? moment(getOnBoardingHistory[0].effectiveFrom)?.format("DD-MMM-YY") : '';

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  useEffect(() => {
    refetch();
  }, [prevData?.partnerId]);

  const handlerRating = () => {
    setShow(true);
  };

  const data = [
    {
      id: 1,
      Rating: "1",
    },
    {
      id: 2,
      Rating: "2",
    },
    {
      id: 3,
      Rating: "3",
    },
    {
      id: 4,
      Rating: "4",
    },
    {
      id: 5,
      Rating: "5",
    },
  ];
  const HandleRatingColor = (id) => {
    setFormData({
      ...formData,
      partnerRatingId: id,
    });
  };

  const handleRatingChange = () => {
    setRatingChange(!RatingChange);
  };

  const createNoteMutation = useMutation(createNote, {
    onSuccess: (res) => {
      isNoteEdit
        ? Toastify("Note Updated Successfully")
        : Toastify("Note Created Successfully");
      setShowComment(false);
      setNote("");
      queryClient.invalidateQueries("getNoteList");
    },
  });

  const mutation = useMutation(addPartnerRating, {
    onSuccess: (res) => {
      RatingRefetch();
      setFormData((prevValue) => ({
        note: "",
        partnerId: prevData?.partnerId,
        partnerRatingId: "",
        reviewerId: Number(employeeId),
      }));
    },
  });

  const deleteNoteMutation = useMutation(deleteNote, {
    onSuccess: (res) => {
      Toastify("Note Deleted Successfully");
      queryClient.invalidateQueries("getNoteList");
    },
    onError: (error) => { },
  });

  const handleRatingDone = () => {
    if (
      formData?.partnerRatingId === 1 ||
      formData?.partnerRatingId === 2 ||
      formData?.partnerRatingId === 3 ||
      formData?.partnerRatingId === 4 ||
      formData?.partnerRatingId === 5
    ) {
      setRatingChange(false);
      setShow(false);
      mutation.mutate(formData);
    } else {
      ToastifyError("Please Select Rating");
    }
  };
  const handleClose = () => setShow(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };
  const BlackTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#fff",
      fontSize: "13px",
      whiteSpace: "pre-line",
      padding: "0.5rem",
    },
  }))(Tooltip);
  const mutationPartner = useMutation(deleteVendorById, {
    onSuccess: (res) => {
      Toastify("Partner Deleted Successfully");
      setNextScreen("PartnerList");
      setPrevData((prevData) => ({
        ...prevData,
        page2: "EditVendor",
        page1: "ViewPartner",
        page3: null,
        page4: null,
        active: "EditVendor",
      }));
    },
    onError: (res) => {
      ToastifyError("Something Went Wrong");
    },
  });

  const handleCloseRecruiter = () => {
    setShowRecruiter(!showRecruiter);
  };

  const changeRecruiterMutation = useMutation(changeRecruiterAllocation, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        errorToast(res?.responseData?.message);
        return;
      }
      successToast(res);
      setShowRecruiter(false);
    },
    onError: (err) => {
      errorToast("Something Went Wrong");
    },
  });

  return (
    <>
      <Container>
        <div>
          <BreadCrum
            prevData={prevData}
            setPrevData={setPrevData}
            setNextScreen={setNextScreen}
          ></BreadCrum>
        </div>
        {OverAllPartnerRating?.currentRating ? (
          <div className={classes.currentRating}>
            <h6>
              <span className={classes.currentRatingView}>Current Rating</span>
              <span
                className={classes.circle}
                onClick={toggleOpen}
                style={{ cursor: "pointer" }}
              >
                {OverAllPartnerRating?.currentRating}
              </span>
            </h6>
          </div>
        ) : (
          ""
        )}

        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Company Size </p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.companySize}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Address 1</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine1}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Address 2</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine2}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Country</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.country}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>City</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>State</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.state}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>PinCode</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.pinCode}</p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Payment Due Days (As per Agreement)
              </p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.pamentDueDays}
              </p>
            </Col>

            <Col style={{ width: "36.5%" }}>
              <div>
                <p className={classes.label1}>
                  Payment Due Days (As per Partner Requested)
                </p>
                <p className={classes.partnerlabal}>
                  {PartnerDataById?.paymentDueDaysAsPrPartnerRequest}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <p className={classes.label1}>LinkedIn Url</p>
              <a
                href={PartnerDataById?.linkedinLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon className={classes.linkedin_icon} />
              </a>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>Company Url</p>
              <a
                href={PartnerDataById?.companyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BusinessIcon className={classes.company_icon} />
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label1}>MSA</p>
              <div style={{ paddingLeft: "12px" }}>
                <a
                  className={classes.msaopen}
                  href={PartnerDataById?.msafileUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PictureAsPdfIcon
                    className={classes.pdf_icon}
                    style={{ color: "red" }}
                  />
                </a>
              </div>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>NDA</p>
              <div style={{ paddingLeft: "12px" }}>
                <a
                  className={classes.msaopen}
                  href={PartnerDataById?.ndafileUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PictureAsPdfIcon
                    className={classes.pdf_icon}
                    style={{ color: "red" }}
                  />
                </a>
              </div>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Work Mode</p>
              <div style={{ paddingLeft: "12px" }}>
                <p>{PartnerDataById?.workMode}</p>
              </div>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Partner Type</p>
              <div style={{ paddingLeft: "12px" }}>
                <p>{PartnerDataById?.partnerType}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label1}>On Boarding Date</p>
              <div style={{ paddingLeft: "12px" }}>
                <p style={{ color: 'green', fontWeight: '500' }}>{onBoardingDate}</p>
              </div>
            </Col>
            <Col md={3}>

            </Col>
            <Col md={3}>

            </Col>
            <Col md={3}>

            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContectEmail} <CopyEmail email={PartnerDataById?.primaryContectEmail} />
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryMobileNumber}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Secondary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContectEmail} 9899898 <CopyEmail email={PartnerDataById?.seconderyContectEmail} />
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyMobileNumber}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>

      <div className={classes.lastBtn_item}>
        <InvoiceButton
          onClick={() => {
            handleCloseRecruiter();
          }}
        >
          Change Recruiter
        </InvoiceButton>
        <InvoiceButton
          onClick={() => {
            mutationPartner.mutate(prevData?.partnerId);
          }}
        >
          Delete
        </InvoiceButton>
        <InvoiceButton
          onClick={() => {
            setShowComment(!showComment);
            setIsNoteEdit(false);
            setNote("");
          }}
        >
          + Add Note
        </InvoiceButton>

        {getVData?.some((e) => {
          return e?.label === "867I3038N27051PP";
        }) && (
            <InvoiceButton
              onClick={() => {
                setNextScreen("OnBoarding");
                setPrevData((prevData) => ({
                  ...prevData,
                  page4: "OnBoarding",
                  
                  active: "OnBoarding",
                  OnBoardId: prevData?.partnerId,
                }));
              }}
            >
              On Boarding
            </InvoiceButton>
          )}

        {getVData?.some((e) => {
          return e?.label === "737Y7815S82601NR";
        }) && (
            <InvoiceButton
              onClick={() => {
                setNextScreen("Specialization");
                setPrevData((prevData) => ({
                  ...prevData,
                  page1: prevData.page1,
                  page2: prevData.page2,
                  page3: prevData.page3,
                  page4: "Specialization",
                  page5: null,
                  active: "Specialization",
                }));
              }}
            >
              Specialization
            </InvoiceButton>
          )}
        {console.log(prevData)}
        {getVData?.some((e) => {
          return e?.label === "951Z7737U32178WJ";
        }) && (
            <InvoiceButton
              onClick={() => {
                setNextScreen("Agreement");
                setPrevData((prevData) => ({
                  ...prevData,
                  page1: prevData.page1,
                  page2: prevData.page2,
                  page3: prevData.page3,
                  page4: "Agreement",
                  page5: null,
                  active: "Agreement",
                }));
              }}
            >
              Agreement
            </InvoiceButton>
          )}

        {getVData?.some((e) => {
          return e?.label === "655D2978V59219OP";
        }) && (
            <InvoiceButton
              // type={"cancel"}
              onClick={() => {
                setNextScreen("TDS");
                setPrevData((prevData) => ({
                  ...prevData,
                  page1: prevData.page1,
                  page2: prevData.page2,
                  page3: prevData.page3,
                  page4: "TDS",
                  page5: null,
                  active: "TDS",
                  TDSID: prevData?.partnerId,
                }));
              }}
            >
              TDS
            </InvoiceButton>
          )}

        {getVData?.some((e) => {
          return e?.label === "559J3261U54243TU";
        }) && (
            <InvoiceButton
              onClick={() => {
                handlerRating();
              }}
            >
              Add Rating
            </InvoiceButton>
          )}

        {getVData?.some((e) => {
          return e?.label === "131D1775O71917QR";
        }) && (
            <InvoiceButton
              onClick={() => {
                setNextScreen("EditPartner");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "EditPartner",
                  page1: "ViewPartner",
                  page3: null,
                  page4: null,
                  active: "EditPartner",
                }));
              }}
            >
              Edit
            </InvoiceButton>
          )}
      </div>

      {showComment && (
        <>
          <div className={classes.add_comment_div}>
            <p className={classes.comment_para}>Add Note</p>
            <textarea
              value={note}
              onChange={(e) => setNote(e?.target?.value)}
              rows={5}
              className={classes.textarea}
            />
          </div>
          <div className={classes.submit_btn_div}>
            <InvoiceButton
              onClick={() => {
                if (note === "") {
                  ToastifyError("Please Enter Note");
                  return;
                }

                {
                  isNoteEdit
                    ? createNoteMutation.mutate({
                      commentedById: Number(
                        secureLocalStorage.getItem("employeeId")
                      ),
                      comments: note,
                      id: editId,
                      partnerId: prevData?.partnerId,
                    })
                    : createNoteMutation.mutate({
                      commentedById: Number(
                        secureLocalStorage.getItem("employeeId")
                      ),
                      comments: note,
                      partnerId: prevData?.partnerId,
                    });
                }
              }}
            >
              {isNoteEdit ? "Update" : "Submit"}
            </InvoiceButton>
          </div>
        </>
      )}

      {getNoteListData && getNoteListData?.length > 0 ? (
        <div ref={bottomRef} className={classes.add_comment_div}>
          <p className={classes.comment_para}>Note History</p>
          <table borderless className={classes.note_table}>
            <thead className={classes.table_head}>
              <tr
                style={{
                  borderBottom: "2px solid #F0F0F0",
                  textAlign: "start",
                }}
              >
                <th style={{ padding: "10px" }} className={classes.table_head}>
                  Date
                </th>
                <th style={{ padding: "10px" }} className={classes.table_head}>
                  Added By
                </th>
                <th style={{ padding: "10px" }} className={classes.table_head}>
                  Note
                </th>
                <th
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className={classes.table_head}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {getNoteListData?.map((item) => (
                <tr
                  style={{
                    borderBottom: "2px solid #F0F0F0",
                    textAlign: "start",
                    fontSize: "13px",
                  }}
                >
                  <td style={{ padding: "10px", width: "14%" }}>
                    {item?.commentedDate
                      ? moment(item?.commentedDate)?.format("DD-MMM-YY")
                      : "-"}
                  </td>
                  <td style={{ padding: "10px", width: "15%" }}>
                    {item?.commentedBy}
                  </td>
                  <td style={{ padding: "10px", width: "60%" }}>
                    {item?.comments.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index !== item.comments.split("\n").length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10%",
                    }}
                  >
                    {
                      <>
                        <EditIcon
                          onClick={() => {
                            if (
                              Number(
                                secureLocalStorage.getItem("employeeId")
                              ) === Number(item?.commentedById)
                            ) {
                              setShowComment(true);
                              setIsNoteEdit(true);
                              setNote(item?.comments);
                              setEditId(item?.commentId);
                            }
                          }}
                          className={
                            Number(secureLocalStorage.getItem("employeeId")) ===
                              Number(item?.commentedById)
                              ? classes.edit_icon
                              : classes?.edit_icon_blur
                          }
                        />
                        <DeleteIcon
                          onClick={() => {
                            if (
                              Number(
                                secureLocalStorage.getItem("employeeId")
                              ) === Number(item?.commentedById)
                            ) {
                              deleteNoteMutation.mutate({
                                commentId: item?.commentId,
                                commentById: item?.commentedById,
                              });
                            }
                          }}
                          className={
                            Number(secureLocalStorage.getItem("employeeId")) ===
                              Number(item?.commentedById)
                              ? classes.edit_icon
                              : classes?.edit_icon_blur
                          }
                        />
                      </>
                    }
                    <AddCircleOutlineIcon
                      onClick={() => {
                        setShowComment(true);
                        setIsNoteEdit(false);
                      }}
                      className={classes.edit_icon}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}

      {isOpen ? (
        <Container>
          <InvoiceContainer>
            <div className={classes.table}>
              <table className={classes.table}>
                <thead>
                  <tr className={classes.th}>
                    <th style={{ width: "13%" }}>Rating Date</th>
                    <th style={{ width: "17%" }}>Reviewer Name</th>
                    <th style={{ width: "15%" }}>Rating</th>
                    <th style={{ width: "65%", padding: "7px" }}>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan="4" className={classes.no_data}>
                        Loading .....
                      </td>
                    </tr>
                  )}
                  {!isLoading && !isError ? (
                    OverAllPartnerRating?.getAllList?.length > 0 ? (
                      typeof OverAllPartnerRating?.getAllList !== "string" &&
                      OverAllPartnerRating?.getAllList?.map((item, index) => {
                        return (
                          <tr className={classes.td} key={item?.positionId}>
                            <td style={{ width: "13%", padding: "5px" }}>
                              {moment(item?.ratingDate).format("DD MMM YY")}
                            </td>
                            <td style={{ width: "17%", padding: "5px" }}>{item?.reviewerName}</td>
                            <td style={{ width: "15%", padding: "5px" }}>{item?.rating}</td>
                            {/* <td style={{width:"65%"}}>{item?.note}</td> */}
                            <td style={{ padding: "10px", width: "65%" }}>
                              {item?.note.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  {index !== item.note.split("\n").length - 1 && (
                                    <br />
                                  )}
                                </React.Fragment>
                              ))}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" className={classes.no_data}>
                          No Data Found
                        </td>
                      </tr>
                    )
                  ) : null}
                </tbody>
              </table>
            </div>

          </InvoiceContainer>
        </Container>
      ) : (
        ""
      )}

      <Modal show={show} centered size="md" onHide={handleClose}>
        <h6 className={classes.TDSRate}>Rating</h6>
        <div className={classes.OverallRating}>
          <p className={classes.RatingOverall}>Overall Rating:</p>
          <p className={classes.ratingValue}>
            {data.map((elem, index) => {
              return (
                <div onClick={() => HandleRatingColor(elem?.id)}>
                  <div
                    key={index}
                    className={
                      formData?.partnerRatingId === index + 1
                        ? classes.circle1
                        : classes.circle2
                    }
                  >
                    {elem.Rating}
                  </div>
                </div>
              );
            })}
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <p className={classes.RatingOverall1}>Skill Rating(1-5):</p>
          <p className={classes.SkillRating}>
            1- Poor, 2- Average, 3- Good, 4- Very Good, 5- Excellent
          </p>
        </div>
        {RatingChange ? (
          <div>
            <p className={classes.addNote}>Add Note</p>
            <Form.Control
              as="textarea"
              rows={7}
              className={classes.RatingTextArea}
              style={{ width: "95%" }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  note: e.target.value,
                });
              }}
            />
          </div>
        ) : (
          ""
        )}

        <div>
          <button
            className={classes.buttonAdd4}
            onClick={() => {
              handleRatingDone();
            }}
          >
            Done
          </button>
          <button
            className={classes.buttonAdd4}
            onClick={() => {
              handleRatingChange();
            }}
          >
            Add Note
          </button>
        </div>
      </Modal>

      <Modal
        show={showRecruiter}
        centered
        size="sm"
        onHide={handleCloseRecruiter}
      >
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#c3b14b" }}>
            Change Recruiter
          </h6>
        </Modal.Header>
        <div className={classes.modal_para_div}>
          <div className={classes.dropdown_div}>
            <CommonDropdown
              color="#c3b14b"
              options={getLeadEmployeeOptions}
              placeholder="Select Recruiter"
              value={checkIfEmpty(leadDetails?.leadId, {
                id: leadDetails?.leadId,
                label: leadDetails?.leadName,
              })}
              onChange={(e) => {
                setLeadDetails({
                  ...leadDetails,
                  leadId: e?.id,
                  leadName: e?.label,
                });
              }}
              customInputOnClick={() =>
                setLeadDetails({
                  ...leadDetails,
                  leadId: "",
                  leadName: "",
                })
              }
            />
          </div>
          <div className={classes.button_div}>
            <InvoiceButton
              styles={
                prevData?.partnerId && leadDetails?.leadId
                  ? { marginRight: "0px" }
                  : { marginRight: "0px", opacity: "0.5", cursor: "default" }
              }
              onClick={() => {
                if (prevData?.partnerId && leadDetails?.leadId) {
                  changeRecruiterMutation.mutate({
                    partnerId: prevData?.partnerId,
                    recruiterId: leadDetails?.leadId,
                  });
                }
              }}
            >
              Done
            </InvoiceButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ViewPartner;
