import React, { useState, useEffect } from "react";
import style from "./AddComment.module.css";
import { useMutation } from "react-query";
import { addCommentsForCandidate, addCommentsForPosition } from "../../../../../Utils/axios/api";
import { notifyError, notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";

function AddComment({
  candidateId,
  setShowAddComment,
  obj,
  setObj,
  setShowCommentModal,
  positionId,
  closeModal,
  handleAddComment,
  getCommentForPosition
}) {
  const [commentData, setCommentData] = useState({
    comment: obj ? obj.comment : getCommentForPosition(positionId),
    commentId: obj ? obj.commentId : null,
    candidateId: candidateId,
    employeeId: employeeId,
    id: positionId,
  });

  useEffect(() => {
    setCommentData(prev => ({
      ...prev,
      comment: obj ? obj.comment : getCommentForPosition(positionId),
      commentId: obj ? obj.commentId : null,
    }));
  }, [obj, positionId, getCommentForPosition]);

  const addCommentsForCandidateMutation = useMutation(addCommentsForPosition, {
    onMutate: () => {},
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (commentData.comment.trim()) {
      addCommentsForCandidateMutation.mutate({
        comment: commentData?.comment,
        candidateId: candidateId,
        employeeId: employeeId,
        commentId: commentData?.commentId ? commentData.commentId : null,
        id: positionId,
      });
      setShowAddComment(false);
      closeModal(false);
      setShowCommentModal(false);

      // Optionally, clear the comment from unsavedComments state after successful submission
      handleAddComment(positionId, "");
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setObj(null);
    setShowAddComment(false);
  };

  const handleChange = (e) => {
    const updatedComment = e.target.value;
    setCommentData((prev) => {
      const newCommentData = { ...prev, comment: updatedComment };
      handleAddComment(positionId, updatedComment);
      return newCommentData;
    });
  };

  return (
    <div className={style.main_container}>
      <p className={style.heading}>Add Comment</p>

      <form>
        <textarea
          name="description"
          id="com"
          cols="50"
          rows="10"
          className={style.textArea}
          value={commentData?.comment}
          onChange={(e) => handleChange(e)}
        ></textarea>
        <br />
        <div className={style.btn_container}>
          <button type="cancel" className={style.btn} onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className={style.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddComment;
