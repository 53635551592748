import React, { useState } from "react";
import style from "./createuser.module.css";
import Select from "react-select";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import calendarIcon from "../../../../assets/calendarIcon.png";
import DatePicker from "react-datepicker";
import SideBar from "../../sidebar/SideBar";
import CreatableSelect from "react-select/creatable";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import "react-toastify/dist/ReactToastify.css";
import {
  createUser,
  getRole,
  getAllTechnology,
  getAllType,
  getSalaryRange,
  getAllFunctionalArea,
  getAllPartnerList,
  addSkill,
} from "../../../../Utils/axios/umsApi";
import {
  getAcademicsByEmail,
  getUserCodeByType,
} from "../../../../Utils/axios/api";
import {
  uploadResume,
  deleteResume,
  getAllDegrees,
  addDegree,
  getCandidateById,
  getWorkExperienceByEmail,
} from "../../../../Utils/axios/api";
import moment from "moment";

import { useQuery, useMutation } from "react-query";
import { useEffect } from "react";
import { ImageProfileView } from "../commonComponent/ImageProfileView";
import { emailExp } from "../../../atsDashboard/utils/validation";
import { employeeId } from "../../../../Utils/constants";
import { path } from "../../../../routes/PathNames";
import { notifyError } from "../../../atsDashboard/utils/notify";
import { validateMobileNumber } from "./validation";
import { validateTotalExperience } from "../../utils/validation";
import { GrPowerReset } from "react-icons/gr";

const CreateUser = () => {
  const [checked, setChecked] = React.useState(false);
  const [totalExpYear, setTotalExpYear] = useState("");
  const [totalExpMonths, setTotalExpMonths] = useState("");
  const [relevantExpYear, setRelevantExpYear] = useState("");
  const [relevantExpMonths, setRelevantExpMonths] = useState("");
  const [userName, setUsername] = useState("");
  const [emailValidation, setEmailValidation] = useState("Please Enter Email");
  const [personalEmailValidation, setPersonalEmailValidation] = useState(
    "Please Enter Personal Email"
  );
  const [validateEmail, setValidateEmail] = useState(false);
  const [personalValidateEmail, setPersonalValidateEmail] = useState(false);
  const [disableSub, setDisabledSub] = useState(false);
  const [totalEx, setTotalEx] = useState("Please Enter Total Experience");
  const [validatetotalEx, setValidatetotalEx] = useState(false);
  const [validatetotalRe, setValidatetotalRe] = useState(false);
  const [totalRe, setTotalRe] = useState("Please Enter Relevant Experience");
  const [emptype, setEmpType] = useState(null);
  const [resumeName, setResumeName] = useState("");
  const [acadamicsArray, setAcadamicsArray] = useState([1, 2]);
  const [showImageProfile, setShowImageProfile] = useState(false);
  const [workExperienceArray, setWorkExperienceArray] = useState([1]);
  const [salaryDataRange, setSalaryDataRange] = useState(null);
  const [salaryDataKey, setSalaryDataKey] = useState(null);
  const [salaryRangeId, setSalaryRangeId] = useState(null);
  const [isRelGreater, setIsRelGreater] = useState(false);
  const [unpaid, setUnpaid] = useState(false);
  const [salaryKey, setSalaryKey] = useState(null);
  const [presentWorkIndex, setPresentWorkIndex] = useState(null);
  const [jobValidate, setJobValidate] = useState(false);
  
  const gradeStatusVal = [
    {value: 1, label: "CGPA"},
    {value: 2, label: "Percentage"},
    {value: 1, label: "Grade"}
  ]

  const [formData, setFormData] = useState({
    type: "",
    code: null,
    name: "",
    emergencyContactName: "",
    email: "",
    personalEmail: "",
    number: "",
    emergencyContactNumber: "",
    employeeRole: "",
    careerStartDate: "",
    dateOfJoining: "",
    relevantExperience: 0,
    technology: "",
    totalExperience: 0,
    empId: employeeId,
    bond: false,
    gender: "",
    maritalStatus: "",
    isBGVeligible: false,
    pfApplicable: false,
    salary: null,
    sa1: null,
    sa2: null,
    incentives: null,
    variableBonus: null,
    joiningBonus: null,
    retentionBonus: null,
    others: null,
    hra: null,
    oa: null,
    basic: null,
    grossSalary: null,
    employeePF: null,
    employerPF: null,
    conveyance: null,
    bonus: null,
    // currentMonthlyCoreCTC: null,
    currentYearlyCoreCTC: null,
    currentMonthlyFullCTC: null,
    currentYearlyFullCTC: null,
    salaryAsProjectCosting: null,
    salaryComment: "",
    educationRequestModels: [
      {
        board: "",
        degree: "",
        grade: null,
        passingYear: 0,
        gradeStatus: "",
      },
      {
        board: "",
        degree: "",
        grade: null,
        passingYear: 0,
        gradeStatus: "",
      },
    ],
    workExpRequestModels: [
      {
        companyName: "",
        reasonForChange: "",
        role: "",
        endDate: null,
        startDate: null,
        present: 0,
      },
    ],
  });
  const bgvOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const genderOption = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const maritalStatusOption = [
    { value: "Married", label: "Married" },
    { value: "Unmarried", label: "Unmarried" },
  ];

  const getGradeStatusVal = (label)=>{
    let val = gradeStatusVal.find(obj=>obj.label === label);
    return val ? val.value : "";
  };

  const handleDateChange = (date, index, type) => {
    let temp = [...formData?.workExpRequestModels];
    if (type === 'start') {
      temp[index].startDate = moment(date).format();
      if (moment(date).isAfter(temp[index].endDate)) {
        temp[index].endDate = null;
      }
    } else {
      if (moment(date).isBefore(temp[index].startDate)) {
        notify1("End date cannot be before start date");
      } else {
        temp[index].endDate = moment(date).format();
      }
    }
    setFormData({
      ...formData,
      workExpRequestModels: temp,
    });
  };

  const location = useLocation();
  const candidateId = location?.state?.candidateId;
  const dropJoinDate = location?.state?.dropJoinDate;
  const { data } = useQuery(
    ["getCandidateById"],
    () => getCandidateById(candidateId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: currUserCodeData, isSuccess: userCodeSuccess } = useQuery(
    ["getUserCodeByType", formData?.type, checked],
    () =>
      (checked || formData?.type) &&
      getUserCodeByType(checked ? 2 : formData?.type),
    {
      onError: () => {
        notifyError("Candidate Code not generated");
        setDisabledSub(true);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setFormData({ ...formData, code: currUserCodeData });
  }, [userCodeSuccess, checked, formData.type]);
  useEffect(() => {
    const tempGS =
      (formData?.basic ? parseFloat(formData?.basic) : 0) +
      (formData?.hra ? parseFloat(formData?.hra) : 0) +
      (formData?.oa ? parseFloat(formData?.oa) : 0) +
      (formData?.conveyance ? parseFloat(formData?.conveyance) : 0) +
      (formData?.bonus ? parseFloat(formData?.bonus) : 0);
    setFormData((previous) => ({ ...previous, grossSalary: tempGS }));
    setSalaryRangeId(
      (tempGS ? parseFloat(tempGS) : 0) +
      (formData?.employerPF ? parseFloat(formData?.employerPF) : 0)
    );
  }, [
    formData?.basic,
    formData?.hra,
    formData?.bonus,
    formData?.conveyance,
    formData?.oa,
    formData?.employerPF,
    formData?.sa1,
    formData?.sa2
  ]);

  useEffect(() => {
    if (data && candidateId) {
      console.log(data?.candidateAcademics)
      setFormData({
        ...formData,
        emergencyContactName: data?.emergencyContactName,
        emergencyContactNumber: data?.emergencyContactNumber,
        linkedInUrl: data?.linkedInUrl,
        personalEmail: data?.email,
        number: data?.mobileNo,
        careerStartDate:
          data?.workExperiences[data?.workExperiences?.length - 1]?.startDate,
        dateOfJoining: dropJoinDate,
        technology: data?.coreSkillId,
        educationRequestModels: data?.candidateAcademics,
        partnerId: data?.partnerId,
        partnerName: data?.partnerName,
        type: data?.isPartnerResource ? 2 : "",
        gender: data?.gender,
        maritalStatus: data?.maritalStatus,
        workExpRequestModels: data?.workExperiences?.map((e, index) => {
          if (e.presentlyWorking === true) {
            return { ...e, present: index + 1 };
          } else {
            return { ...e, present: 0 };
          }
        }),
      });
      setUsername(data?.name);
      setTotalExpYear(data?.totalExperienceYear);
      setTotalExpMonths(data?.totalExperienceMonth);
      setRelevantExpMonths(data?.relevantExperienceMonth);
      setRelevantExpYear(data?.relevantExperienceYear);
      setAcadamicsArray([...Array(data?.candidateAcademics?.length).keys()]);
      setChecked(data?.isPartnerResource);
    }
  }, [data]);

  useEffect(() => {
    setFormData({
      ...formData,
      salary: salaryRangeId,
      currentYearlyCoreCTC: salaryRangeId * 12,
      currentMonthlyFullCTC:
        salaryRangeId +
        Number(formData?.sa1) +
        Number(formData?.sa2) +
        Number(formData?.incentives) +
        Number(formData?.variableBonus) +
        Number(formData?.joiningBonus) +
        Number(formData?.retentionBonus) +
        Number(formData?.others),
      currentYearlyFullCTC:
        (salaryRangeId +
          Number(formData?.sa1) +
          Number(formData?.sa2) +
          Number(formData?.incentives) +
          Number(formData?.variableBonus) +
          Number(formData?.joiningBonus) +
          Number(formData?.retentionBonus) +
          Number(formData?.others)) *
        12,
    });
    console.log((salaryRangeId +
      Number(formData?.sa1) +
      Number(formData?.sa2) +
      Number(formData?.incentives) +
      Number(formData?.variableBonus) +
      Number(formData?.joiningBonus) +
      Number(formData?.retentionBonus) +
      Number(formData?.others)) *
      12)
    // Call your API here with Axios
    const url = process.env.REACT_APP_UMS_APP_URL;
    axios
      .get(`${url}/getSalaryRangeBySalary`, {
        params: {
          currentMonthlyFullCTC: (salaryRangeId +
            Number(formData?.sa1) +
            Number(formData?.sa2) +
            Number(formData?.incentives) +
            Number(formData?.variableBonus) +
            Number(formData?.joiningBonus) +
            Number(formData?.retentionBonus) +
            Number(formData?.others)) *
            12,
        },
      })
      .then((response) => {
        // Handle API response if needed
        setSalaryDataRange(response.data.responseData.label);
        setSalaryDataKey(response.data.responseData.key);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error:", error); // Log the error to the console
      });

  }, [salaryRangeId,
    formData?.sa1,
    formData?.sa2,
    formData?.incentives,
    formData?.variableBonus,
    formData?.joiningBonus,
    formData?.retentionBonus,
    formData?.others]);

  useEffect(() => {


  }, [salaryKey])


  useEffect(() => {
    setFormData({
      ...formData,
      salaryRangeId: salaryDataKey,
    });
  }, [salaryDataKey]);

  // Function to get the salary label based on salary range ID
  const getSalaryLabel = (salaryRangeId, salaryDataKey) => {
    // console.log("getSalaryLabel")
    let label = "";
    switch (salaryRangeId) {
      case "Normal":
        return "Normal";
        break;
      case "High":
        return "High";
        break;
      case "Very High":
        return "Very High";
        break;
      default:
        return null;
        break;
    }
    return label;
  };

  const { data: getCoreSkillData } = useQuery(
    ["getAllTechnology"],
    getAllTechnology,
    {
      refetchOnWindowFocus: false,
    }
  );
  const academicsMutation = useMutation(getAcademicsByEmail, {
    onSuccess: (res) => {
      let newData = res.map((e) => {
        return { ...e, degree: { value: e.degreeId, label: e.degree } };
      });
      setFormData((prevData) => ({
        ...prevData,
        educationRequestModels: newData,
      }));
      let arr = [];
      for (let i = 0; i < res?.length; i++) {
        arr.push(i);
      }
      setAcadamicsArray(arr);
    },
  });
  const experienceMutation = useMutation(getWorkExperienceByEmail, {
    onSuccess: (res) => {
      let newData = res?.map((e, index) => {
        if (e.presentlyWorking === true) {
          return { ...e, present: index + 1 };
        } else {
          return { ...e, present: 0 };
        }
      });
      setFormData((prev) => ({
        ...prev,
        workExpRequestModels: newData,
      }));
    },
  });

  const mutationDegree = useMutation(addDegree, {
    onSuccess: (res) => {
      let academics = formData?.educationRequestModels;
      for (let i = 0; i < academics.length; i++) {
        if (academics[i].degree.label === res?.name) {
          let obj = {
            label: res?.name,
            value: res?.id,
          };
          academics[i].degree = obj;
          break;
        }
      }
    },
  });
  const CustomInputClgPY = React.forwardRef((props, ref) => {
    let temp = [...formData?.educationRequestModels];
    return (
      <>
        {temp[props?.index]?.passingYear ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              width="15px"
              height="15px"
              alt="cancel"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].passingYear = null;
                setFormData({
                  ...formData,
                  educationRequestModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div onClick={props.onClick}>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const { data: getAllTypeData } = useQuery(["getAllType"], getAllType, {
    refetchOnWindowFocus: false,
  });
  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const { data: getAllDegreesData } = useQuery(
    ["getAllDegrees"],
    getAllDegrees,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getAllDegreesOption = getAllDegreesData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const { data: getSalaryRangeData } = useQuery(
    ["getSalaryRange"],
    getSalaryRange,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getAllPartnerListData } = useQuery(
    ["getAllPartnerList"],
    getAllPartnerList,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getAllFunctionalData } = useQuery(
    ["getAllFunctionalArea"],
    getAllFunctionalArea,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getSalaryRangeOption = getSalaryRangeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const getFunctionalOption = getAllFunctionalData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  let index = getAllTypeOption?.findIndex((e) => e.label === "Partner");

  getAllTypeOption?.splice(index, 1);

  const handleChange = () => {
    setChecked(!checked);
    setFormData({
      ...formData,
      type: null,
      partnerId: null,
      partnerName: null,
    });
  };

  useEffect(() => {
    if (unpaid) {
      setFormData({
        ...formData,
        salary: "",
        sa1: "",
        sa2: "",
        incentives: "",
        variableBonus: "",
        joiningBonus: "",
        retentionBonus: "",
        others: "",
        hra: "",
        oa: "",
        basic: "",
        grossSalary: "",
        employeePF: "",
        employerPF: "",
        conveyance: "",
        bonus: "",
        currentYearlyCoreCTC: "",
        currentMonthlyFullCTC: "",
        currentYearlyFullCTC: "",
        salaryAsProjectCosting: "",
        salaryNotes: "",
        gratuity: "",
      });
    }
  }, [unpaid]);

  const handleUnpaidChange = () => {
    setUnpaid(!unpaid);
  };
  useEffect(() => {
    setResumeName(formData?.resumeUrl);
  }, [formData?.resumeUrl]);
  formData.totalExperience = Number(totalExpYear * 12) + Number(totalExpMonths);
  formData.relevantExperience =
    Number(relevantExpYear * 12) + Number(relevantExpMonths);
  formData.name = userName;

  const history = useHistory();
  const [validate, setValidate] = useState(false);

  const { data: role } = useQuery(["getRole"], () => getRole(), {
    refetchOnWindowFocus: false,
  });

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="dropdownIcon"
          style={{
            position: "absolute",
            top: "32%",
            right: "6%",
            width: "18px",
          }}
        />
      </span>
    );
  };
  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ padding: "8px", fontWeight: 600, cursor: "pointer" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "30%", zIndex: "0" }}
          src={calendarIcon}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label ref={ref} style={{ padding: "8px", fontWeight: 600 }}>
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "30%",
            zIndex: "0",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const getpartnerlist = getAllPartnerListData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getRoleList = role?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getTechnologyList = getCoreSkillData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_createconfirm, { id });
    }, 0);
  };
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setFormData({ ...formData, resumeUrl: data.toString() });
    },
  });
  const mutation = useMutation(createUser, {
    onSuccess: (res) => {
      setDisabledSub(false);
      if (res?.responseStatus?.statusCode === 1) {
        notify1(res?.responseData?.message);
      } else {
        // notifySuccess();

        handleRedirect(res.responseData.id);
      }
    },
    onError: (res) => {
      setDisabledSub(false);
      
    },
  });
  const checkPartner = () => {
    if (true) {
      return false;
    } else if (formData?.partnerId) {
      return false;
    } else {
      return true;
    }
  };
  const checkbond = () => {
    if (formData?.bond && !formData?.bondDate) {
      return true;
    } else {
      return false;
    }
  };
  const removeFieldFromAcademics = (data) => {
    const newData = data?.filter((e) => e?.degreeId && e?.passingYear !== "");
    return newData;
  };
  const removeFieldFromArray = (data) => {
    const newData = data?.filter((e) => e?.companyName !== "");
    return newData;
  };
  const handleImage = (e) => {
    const fileName = e.target.files[0].name;
    if (
      fileName?.split(".")[1]?.toLowerCase() === "png" ||
      fileName?.split(".")[1]?.toLowerCase() === "jpg" ||
      fileName?.split(".")[1]?.toLowerCase() === "jpeg"
    ) {
      uploadResumeMutation.mutate(e.target.files[0]);
    } else {
      notifyError("Please select png, jpg or jpeg file only");
    }
  };

  const setSalaryRange = () => { };

  const handleUnpaidValidation = () => {
    if (unpaid) {
      return false;
    } else {
      if (
        formData?.hra === null ||
        formData?.hra === undefined ||
        formData?.oa === null ||
        formData?.oa === undefined ||
        formData?.basic === null ||
        formData?.basic === undefined ||
        formData?.conveyance === null ||
        formData?.conveyance === undefined ||
        !formData.salaryAsProjectCosting ||
        !formData.salary
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkJobValidate = ()=>{
    formData.workExpRequestModels.forEach((item, index)=>{
      if(item.present === index+1 && (!item?.startDate || !item.role || !item.reasonForChange)){

        return true
      }
    })
    return false
  }
  const onCreateHandler = () => {
    let filter = emailExp();

    if (!formData?.personalEmail) {
      setValidate(true);
    }
    if(checkJobValidate){
      setJobValidate(true);
    } else {
      console.log("nah")
    }
    if (formData.email === "") {
      setEmailValidation("Please Enter Email ");
      setValidateEmail(true);
    } else if (!filter.test(formData.email)) {
      setEmailValidation("Please Enter Valid Email ");
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
    if (formData.personalEmail === "") {
      setPersonalEmailValidation("Please Enter Personal Email ");
      setPersonalValidateEmail(true);
    } else if (!filter.test(formData.personalEmail)) {
      console.log(formData?.personalEmail)
      setPersonalEmailValidation("Please Enter Valid Email ");
      setPersonalValidateEmail(true);
    } else {
      setPersonalValidateEmail(false);
    }
    if (totalExpYear === "" || totalExpMonths === "") {
      setTotalEx("Please Enter Total Experience");
      setValidatetotalEx(true);
    } else if (parseInt(totalExpMonths) > 12) {
      setTotalEx("Max 11 months allowed");
      setValidatetotalEx(true);
    } else {
      setValidatetotalEx(false);
    }
    if (relevantExpYear === "" || relevantExpMonths === "") {
      setTotalRe("Please Enter Relevant Experience");
      setValidatetotalRe(true);
    } else if (parseInt(relevantExpMonths) > 12) {
      setTotalRe("Max 11 months allowed");
      setValidatetotalRe(true);
    } else {
      setValidatetotalRe(false);
    }
    if (
      !validatetotalEx &&
      !validatetotalRe &&
      !parseInt(relevantExpMonths) > 12 &&
      !parseInt(totalExpMonths) > 12 &&
      !validateTotalExperience(
        totalExpYear,
        totalExpMonths,
        relevantExpYear,
        relevantExpMonths
      )
    ) {
      setIsRelGreater(true);
    } else {
      setIsRelGreater(false);
    }
    if (
      !formData.type ||
      // !formData.code?.trim() ||
      !formData.name?.trim() ||
      !filter.test(formData.email) ||
      // formData?.number.length < 10 ||
      // formData?.number.length > 14 ||
      // formData?.number == "" ||
      validateMobileNumber(formData?.number) ||
      validateMobileNumber(formData?.emergencyContactNumber) ||
      validateMobileNumber(formData?.whatsappNumber) ||
      formData.email === "" ||
      formData.personalEmail === "" ||
      !formData.careerStartDate ||
      !formData.dateOfJoining ||
      !(formData.relevantExperience !== "") ||
      !(relevantExpYear !== "") ||
      !(relevantExpMonths !== "") ||
      !(relevantExpYear !== "") ||
      !(relevantExpMonths !== "") ||
      !(formData.totalExperience !== "") ||
      !(formData.maritalStatus !== "") ||
      !(formData.gender !== "") ||
      checkPartner() ||
      checkbond() ||
      !formData?.employeeRole ||
      !formData?.functionalAreaId ||
      checkJobValidate() ||
      // formData?.employeePF === null ||
      // formData?.employeePF === undefined ||
      !validateTotalExperience(
        totalExpYear,
        totalExpMonths,
        relevantExpYear,
        relevantExpMonths
      ) ||
      handleUnpaidValidation() || 
      !formData?.location
      // ||
      // formData?.bonus === null ||
      // formData?.bonus === undefined ||
      // formData?.employerPF === null ||
      // formData?.employerPF === undefined
    ) {
      setValidate(true);
    } else {
      setDisabledSub(true);
      
      let newFormData = {
        ...formData,
        additionalSkills: formData?.additionalSkills?.map((e) => e.id),
        educationRequestModels: removeFieldFromAcademics(
          formData?.educationRequestModels
        )?.map((e) => ({
          board: e?.board,
          degreeId:
            typeof e?.degreeId === "object" ? e?.degreeId?.value : e?.degreeId,
          grade: e?.grade,
          gradeStatus: e?.gradeStatus,
          passingYear: e?.passingYear,
        })),
        uploadImageUrl: formData?.resumeUrl,
        linkedInUrl: formData?.linkedInUrl,
        emergencyContactName: formData?.emergencyContactName,
        emergencyContactNumber: formData?.emergencyContactNumber,
        changeByEmpId: formData?.empId,
        workExpRequestModels: removeFieldFromArray(
          formData?.workExpRequestModels
        ),
      };
      mutation.mutate(newFormData);
    }
    // notifySuccess();
    // handleRedirect();
  };
  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 0%",
      minHeight: "38px",
      fontWeight: 500,
      // width:"90%",
      outline: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(78 206 199)",
      },
      backgroundColor: state.isSelected ? "rgb(78 206 199)" : "white",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };

  const customStylesForType = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 0%",
      height: "38px",
      fontWeight: 500,
      // width:"90%",
      outline: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };

  const notify1 = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const handleBondDateChange = (date) => {
    if (formData.dateOfJoining && date <= formData.dateOfJoining) {
      notifyError("Bond End Date should be greater than Date of Joining");
    } else {
      setFormData({ ...formData, bondDate: date });
    }
  };

  const addSkillMutation = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = formData?.additionalSkills;
      selected.pop();

      selected = [
        ...selected,
        {
          id: res?.additionalSkillId,
          skillName: res?.additionalSkillName,
        },
      ];
      let items = {};
      selected?.forEach((element) => {
        if (!items[element?.id]) {
          items[element?.id] = element;
        }
      });
      let newData = Object.values(items);
      setFormData((prevData) => ({
        ...prevData,
        additionalSkills: newData,
      }));
    },
  });
  const PFApplicationOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const isSameUser = (a, b) => a.id === b?.value && a?.skillName === b?.label;
  const handleCross = (left, right, compareFunction) => {
    if (!left) {
      return;
    }
    return left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  };
  const handleSelect = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      additionalSkills: e.map((i) => ({
        id: i.value,
        skillName: i?.label,
      })),
    }));
    let prevSelect = handleCross(formData?.additionalSkills, e, isSameUser);

    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getTechnologyList?.some((item) => item.label === e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          addSkillMutation.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };
  const bondOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const options = [
    { value: 1, label: "CGPA" },
    { value: 2, label: "Percentage" },
  ];

  const locations = [
    {value: 1, label: "Offshore"},
    {value: 2, label: "Onsite"}
  ]
  const CustomInputClgWXS = React.forwardRef((props, ref) => {
    let temp = [...formData?.workExpRequestModels];
    return (
      <>
        {temp[props?.index]?.startDate ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              alt="cancel"
              width="15px"
              height="15px"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].startDate = null;
                setFormData({
                  ...formData,
                  workExpRequestModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div onClick={props.onClick}>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const CustomInputClgWXEND = React.forwardRef((props, ref) => {
    let temp = [...formData?.workExpRequestModels];
    return (
      <>
        {temp[props?.index]?.endDate ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              width="15px"
              alt="cancel"
              height="15px"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].endDate = null;
                setFormData({
                  ...formData,
                  workExpRequestModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div
            onClick={
              temp[props?.index]?.present === props?.index + 1
                ? null
                : props.onClick
            }
          >
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  return (
    <>
      <ToastContainer />
      {/*<Header />*/}

      <div className={style.main_user_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.breadscrumb} style={{ padding: "0px" }}>
            <div>
              <p className={style.creatUser}>Create User</p>
            </div>
          </div>
          <div className={style.createposition}>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p
                  className={style.cu_label}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    id="demoCheckbox"
                    name="checkbox"
                    value="1"
                    checked={checked}
                    onChange={(e) => handleChange()}
                    style={{
                      visibility: "visible",
                      height: "20px",
                      width: "25px",
                      cursor: "pointer",
                      accentColor: "#289e98"
                    }}
                  />
                  <label
                    for="demoCheckbox"
                    className={style.cu_label}
                    style={{ color: "black" }}
                  >
                    Is Partner Resource? <span style={{ color: "red" }}>*</span>
                  </label>
                </p>
                {!checked ? (
                  <Select
                    styles={customStylesForType}
                    classNamePrefix={"create_user"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        type: e.value,
                        partnerId: null,
                        partnerName: null,
                      });
                      setEmpType(e.label);
                    }}
                    value={{
                      label: formData?.type,
                      label: emptype,
                    }}
                    options={getAllTypeOption}
                  />
                ) : (
                  <Select
                    styles={customStylesForType}
                    classNamePrefix={"create_user"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    // value={
                    //   formData?.partnerId
                    //     ? getpartnerlist?.find(
                    //         (e) => e?.partnerId === formData?.partnerId
                    //       )
                    //     : null
                    // }
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        type: getAllTypeData?.find((e) => e.name === "Partner")
                          .id,
                        partnerId: e.value,
                        partnerName: e.label,
                      });
                      setEmpType(null);
                    }}
                    options={getpartnerlist}
                    value={{
                      label: formData?.partnerName,
                      value: formData?.partnerId,
                    }}
                  />
                )}
                {!checked && (
                  <p
                    className={
                      validate && !formData?.type
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Type
                  </p>
                )}
                {checked && (
                  <p
                    className={
                      validate && !formData?.partnerId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Partner Resource
                  </p>
                )}
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Code
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <input
                  defaultValue={formData?.code?.trim()}
                  className={`${style.cu_field2} ${style.readonly}`}
                  readOnly
                  // placeholder="Search by User Name"
                  disabled
                  type={"text"}
                  min={0}
                  onChange={(e) =>
                    setFormData({ ...formData, code: e.target.value })
                  }
                ></input>
                {/* <p
                  className={
                    validate && !formData?.code?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Code
                </p> */}
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Name
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  defaultValue={formData?.name?.trim()}
                  className={style.cu_field2}
                  value={formData?.name}
                  // placeholder="Search by User Name"
                  onChange={(e) =>
                    // setFormData({...formData, name:e.target.value}),
                    setUsername(e.target.value)
                  }
                ></input>
                <p
                  className={
                    validate && !formData?.name?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Name
                </p>
              </div>
            </div>
            {/* Second */}
            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Email
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  defaultValue={formData?.email}
                  className={style.cu_field2}
                  type="email"
                  onChange={
                    (e) => setFormData({ ...formData, email: e.target.value })
                    //
                  }
                  onKeyDown={() => setValidateEmail(false)}
                ></input>
                <p
                  className={
                    validateEmail ? style.errorText : style.errorTextHidden
                  }
                >
                  {emailValidation}
                </p>
              </div>
              <div className={style.cu_col2} style={{ marginLeft: "11px" }}>
                <p className={style.cu_label}>
                  Personal Email
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="email"
                    value={formData?.personalEmail}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        personalEmail: e.target.value,
                      })
                    }
                    onKeyDown={() => setPersonalEmailValidation(false)}
                  ></input>
                </div>
                <p
                  className={
                    personalValidateEmail
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  {personalEmailValidation}
                </p>
                {/* <p
                  className={
                    validateNumber ? style.errorText : style.errorTextHidden
                  }
                >
                  {numerror}
                </p> */}
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Mobile Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={formData?.phoneNumber}
                    className={style.cu_field2}
                    type={"text"}
                    value={formData?.number}
                    onChange={(e) =>
                      setFormData({ ...formData, number: e.target.value })
                    }
                  // onKeyDown={() => setNumError("")}
                  ></input>
                </div>
                <p
                  className={
                    validate && validateMobileNumber(formData?.number)
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Mobile Number
                </p>
              </div>
            </div>
            {/* Three */}
            <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Marital Status<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      maritalStatus: e.value,
                    });
                  }}
                  options={maritalStatusOption}
                />
                <p
                  className={
                    validate && !formData?.maritalStatus
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Marital Status
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Gender<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      gender: e.value,
                    });
                  }}
                  options={genderOption}
                />
                <p
                  className={
                    validate && !formData?.gender
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Gender
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  WhatsApp Mobile Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={formData?.whatsappNumber}
                    className={style.cu_field2}
                    type={"text"}
                    value={formData?.whatsappNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        whatsappNumber: e.target.value,
                      })
                    }
                  // onKeyDown={() => setNumError("")}
                  ></input>
                </div>
                <p
                  className={
                    validate && validateMobileNumber(formData?.whatsappNumber)
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid WhatsApp Mobile Number
                </p>
              </div>
            </div>
            {/* Four */}
            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Career Start Date
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    // minDate={new Date()}
                    selected={
                      formData.careerStartDate
                        ? moment(formData?.careerStartDate)?.toDate()
                        : null
                    }
                    onChange={(date) =>
                      setFormData({ ...formData, careerStartDate: date })
                    }
                    defaultValue={null}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<CustomInputClg />}
                    style={{ backgroundColor: "#F5F6FA" }}
                  />
                </div>
                <p
                  className={
                    validate && !formData?.careerStartDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Career Start Date
                </p>
              </div>
              <div className={style.cu_col2} style={{ marginLeft: "11px" }}>
                <p className={style.cu_label}>
                  Total Experience
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_row2_col2}>
                  <div style={{ width: "100%", marginRight: "1rem" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      className={style.cu_row_field2}
                      value={totalExpYear}
                      placeholder="Year"
                      onClick={handleInputClick}
                      // onChange={(e) =>
                      //   setFormData({...formData, totalExperience: e.target.value })
                      // }
                      onChange={(e) => setTotalExpYear(e.target.value)}
                      onKeyDown={(e) => { setValidatetotalEx(false); handleKeyDown(e) }}
                    ></input>
                    {!isRelGreater &&
                      totalEx === "Please Enter Total Experience" && (
                        <p
                          style={{ display: isRelGreater && "none" }}
                          className={
                            validatetotalEx
                              ? style.errorText
                              : style.errorTextHidden
                          }
                        >
                          {totalEx}
                        </p>
                      )}
                    {!validatetotalEx && !validatetotalRe && (
                      <p
                        style={{ display: isRelGreater ? "block" : "none" }}
                        className={
                          isRelGreater ? style.errorText : style.errorTextHidden
                        }
                      >
                        Relevant Experience should be less than Total Experience
                      </p>
                    )}
                  </div>

                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      max={12}
                      className={style.cu_row_field2}
                      placeholder="Months"
                      value={totalExpMonths}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                      onChange={(e) => setTotalExpMonths(e.target.value)}
                    // onKeyDown={() => setValidatetotalEx(false)}
                    ></input>
                    {totalEx === "Max 11 months allowed" || totalExpMonths <= 0 && (
                      <p
                        className={
                          validatetotalEx
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        {totalEx}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Relevant Experience
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.cu_row2_col2}>
                  <div style={{ width: "100%", marginRight: "1rem" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      className={style.cu_row_field2}
                      placeholder="Year"
                      value={relevantExpYear}
                      onChange={(e) => {
                        setRelevantExpYear(e.target.value);
                      }}
                      min={0}
                      onKeyDown={(e) => {
                        setValidatetotalRe(false);
                        handleKeyDown(e);
                      }}
                      onClick={handleInputClick}
                    />

                    {totalRe === "Please Enter Relevant Experience" && (
                      <p
                        className={
                          validatetotalRe
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        {totalRe}
                      </p>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      max={12}
                      value={relevantExpMonths}
                      onChange={(e) => setRelevantExpMonths(e.target.value)}
                      className={style.cu_row_field2}
                      placeholder="Months"
                      onKeyDown={(e) => {
                        setValidatetotalRe(false);
                        handleKeyDown(e);
                      }}
                      onClick={handleInputClick}
                    />

                    {totalRe === "Max 11 months allowed" && (
                      <p
                        className={
                          validatetotalRe
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        {totalRe}
                      </p>
                    )}
                  </div>
                </div>
                {/*monthdError && relevantExpMonths > 11 && (
                  <p className={style.errorText}>Please enter valid months</p>
                )*/}
              </div>
            </div>
            {/* Five */}
            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Date of Joining
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={
                      formData.dateOfJoining
                        ? moment(formData.dateOfJoining)?.toDate()
                        : null
                    }
                    onChange={(date) =>
                      setFormData({ ...formData, dateOfJoining: date })
                    }
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<CustomInputClg />}
                    style={{ backgroundColor: "#F5F6FA" }}
                  />
                </div>
                <p
                  className={
                    validate && !formData?.dateOfJoining
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Date of Joining
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Technology</p>
                <Select
                  styles={customStyles}
                  menuPlacement="top"
                  classNamePrefix={"create_position"}
                  value={
                    formData?.technology
                      ? getTechnologyList?.find(
                        (e) => e?.value === formData?.technology
                      )
                      : null
                  }
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, technology: e.value })
                  }
                  options={getTechnologyList}
                />
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Additional Skill</p>
                <CreatableSelect
                  menuPlacement="top"
                  isMulti
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => handleSelect(e)}
                  options={getTechnologyList}
                  formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                />
              </div>
            </div>
            {/* Six */}

            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Designation<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  // selected={formData?.employeeRole.slice(0,10)}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      employeeRole: e.value,
                    });
                  }}
                  options={getRoleList}
                />
                <p
                  className={
                    validate && !formData?.employeeRole
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Designation
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Salary Range
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div
                  defaultValue={formData?.code?.trim()}
                  className={style.cu_field2}
                  // placeholder="Search by User Name"
                  style={{ paddingTop: "7px" }}
                  type={"text"}
                  min={0}
                  onClick={() => {
                    setFormData({ ...formData, salaryRangeId: salaryDataKey });
                  }}
                >
                  {getSalaryLabel(salaryDataRange, salaryDataKey)}
                </div>
                {/* <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, salaryRangeId: e.value })
                  }
                  options={getSalaryRangeOption}
                /> */}
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Functional Area<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  menuPlacement="top"
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, functionalAreaId: e.value })
                  }
                  options={getFunctionalOption}
                />
                <p
                  className={
                    validate && !formData?.functionalAreaId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Functional Area
                </p>
              </div>
            </div>
            {/* Seven*/}

            <div className={style.Cu_row1} style={{ marginTop: "1.7rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Bond</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    if (!e.value) {
                      setFormData({
                        ...formData,
                        bondDate: "",
                        bond: e.value,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        bond: e.value,
                      });
                    }
                  }}
                  options={bondOption}
                />
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Bond End Date<span style={{ color: "red" }}>*</span></p>
                <div
                  className={style.csd}
                  style={!formData?.bond ? { background: "#8f8b8b69" } : null}
                >
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={formData.bondDate}
                    onChange={handleBondDateChange}
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={
                      formData?.bondDate ? (
                        <CustomInputCross properties="bondDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                    disabled={!formData?.bond}
                    style={{ backgroundColor: "red" }}
                  />
                </div>
                <p
                  className={
                    formData?.bond && validate && !formData?.bondDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Bond End Date
                </p>
              </div>
              <div className={style.cu_col3}>
                <div className={style.Cu_row1}>
                  <div style={{ marginTop: "0.5rem" }}>
                    {!resumeName && (
                      <span className={style.cu_label}>
                        Upload Profile Image
                      </span>
                    )}
                    {resumeName ? (
                      <>
                        <div className={style.imageupload}>
                          <label for="file-input">
                            <img
                              id="image"
                              src={resumeName}
                              alt="resumeName"
                              style={{
                                borderRadius: "50%",
                                width: "4.5rem",
                                height: "4.5rem",
                                border: "1px solid black",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowImageProfile(true)}
                            />
                          </label>
                          <p
                            onClick={() => {
                              setFormData({ ...formData, resumeUrl: "" });
                              setResumeName(null);
                              deleteResume(formData?.resumeUrl);
                            }}
                            className={style.rmpdfcross}
                          >
                            x
                          </p>
                        </div>
                      </>
                    ) : (
                      <input
                        id="file-input"
                        className={style.upload_resume}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <ImageProfileView
                show={showImageProfile}
                image={resumeName}
                okaybtn={() => setShowImageProfile(false)}
              ></ImageProfileView>
            </div>
            {/* Eight */}

            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Date of Birth</p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={formData.dob}
                    onChange={(date) => setFormData({ ...formData, dob: date })}
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={
                      formData?.dob ? (
                        <CustomInputCross properties="dob" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                    style={{ backgroundColor: "red" }}
                  />
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Marriage Anniversary</p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={formData.marriageAnniversary}
                    onChange={(date) =>
                      setFormData({ ...formData, marriageAnniversary: date })
                    }
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={
                      formData?.marriageAnniversary ? (
                        <CustomInputCross properties="marriageAnniversary" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>LinkedIn URL</p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="email"
                    value={formData?.linkedInUrl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        linkedInUrl: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>

            {/*Nine*/}
            <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Folder URL</p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="url"
                    value={formData?.folderUrl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        folderUrl: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>BGV Applicable </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  // value={bgvOption?.find((e) => e.value === formData?.isBGVeligible)}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isBGVeligible: e.value,
                    });
                  }}
                  options={bgvOption}
                />
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>PF Applicable </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  // value={bgvOption?.find((e) => e.value === formData?.pfApplicable)}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      pfApplicable: e.value,
                    });
                  }}
                  options={PFApplicationOption}
                />
              </div>
            </div>
            <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Location <span style={{ color: "red" }}>*</span> </p>
                
                  <Select
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={locations.find(e=> e.label === formData?.location)}
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        location: e.label
                      })
                    }}
                    options={locations}
                  />
                  <p
                  className={
                    !formData?.location && validate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Location
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Emergency Contact Name</p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="text"
                    value={formData?.emergencyContactName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        emergencyContactName: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Emergency Contact Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={formData?.emergencyContactNumber}
                    className={style.cu_field2}
                    type={"Number"}
                    value={formData?.emergencyContactNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        emergencyContactNumber: e.target.value,
                      })
                    }
                  // onKeyDown={() => setNumError("")}
                  ></input>
                </div>
                <p
                  className={
                    validate && validateMobileNumber(formData?.emergencyContactNumber)
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Emergency Contact Number
                </p>
              </div>
            </div>
            {/*Note*/}
            <div className={style.Cu_notes} style={{ marginTop: "1rem" }}>
              <p className={style.cu_label}>Notes</p>
              <div>
                <textarea
                  className={style.notes}
                  type="textarea"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      note: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {/* Academic */}
          </div>
          {/*work Experience*/}
          <div className={style.createposition}>
            <p className={style.title}>Work Experience</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Company Name</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 23%" }}>
                <div className={style.multiinput}>
                  <div className={style.cu_label}>From</div>
                  <div className={style.cu_label} style={{ paddingRight: "34%" }}>To</div>
                </div>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Role</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Reason For Change</p>
              </div>
            </div>
            {formData?.workExpRequestModels?.map((item, index) => {
              return (
                <div className={style.workExperiences} key={index}>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id={`present${index}`}
                      name={`present`}
                      value={index}
                      checked={formData?.workExpRequestModels?.[index].present === index + 1}
                      onChange={() => {
                        let temp = [...formData?.workExpRequestModels];
                        temp = temp.map((e) => ({ ...e, present: 0 }));
                        temp[index].present = index + 1;
                        temp[index].endDate = null;
                        setFormData({
                          ...formData,
                          workExpRequestModels: temp,
                        });
                      }}
                    />
                    <label
                      htmlFor={`present${index}`}
                      style={{ fontSize: "1rem", color: "#000000" }}
                    >
                      Presently working here
                    </label>{" "}
                    {formData?.workExpRequestModels?.[index].present === 
                      index+1 && (
                        <span
                          onClick={()=>{
                            let temp=[
                              ...(formData && formData.workExpRequestModels
                                ? formData.workExpRequestModels
                                : []
                              )
                            ];
                            temp = temp.map((e) => ({ ...e, present: 0 }));
                            temp[index].present = 0;
                            temp[index].presentlyWorking = false;
                            setPresentWorkIndex(0);
                            temp[index].endDate = null;
                            setFormData({
                              ...formData,
                              workExpRequestModels: temp,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              let temp = [
                                ...(formData && formData.workExpRequestModels
                                  ? formData.workExperiences
                                  : []),
                              ];
                              temp = temp.map((e) => ({ ...e, present: 0 }));
                              temp[index].present = 0;
                              temp[index].presentlyWorking = false;
                              setPresentWorkIndex(0);
                              temp[index].endDate = null;
                              setFormData({
                                ...formData,
                                workExpRequestModels: temp,
                              });
                            }
                          }}
                          style={{
                            fontSize: "13px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        >
                          <GrPowerReset/>
                          &nbsp;Reset
                        </span>
                      )
                    }
                  </div>
                  {formData?.workExpRequestModels?.length > 1 && (
                    <button
                      className={style.btn_remove1}
                      onClick={(e) => {
                        setWorkExperienceArray(
                          workExperienceArray.filter((_, i) => i !== index)
                        );
                        setFormData({
                          ...formData,
                          workExpRequestModels: formData?.workExpRequestModels?.filter(
                            (_, i) => i !== index
                          ),
                        });
                      }}
                    >
                      ❌
                    </button>
                  )}
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <input
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.workExpRequestModels];
                          temp[index].companyName = e?.target?.value;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                        value={formData?.workExpRequestModels[index]?.companyName}
                      />
                      <p
                        className={
                          jobValidate &&
                          formData?.workExpRequestModels[index]?.present === index + 1 &&
                            !formData?.workExpRequestModels[index]?.companyName
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Enter Company Name
                      </p>
                    </div>
                    <div className={style.cu_col2_to} style={{ flex: "0 0 23%" }}>
                      <div className={style.multiinput}>
                        <div>
                          <div className={style.cu_field23} style={{ marginTop: "0.25rem" }}>
                            <DatePicker
                              placeholder="MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              selected={
                                formData?.workExpRequestModels[index]?.startDate
                                  ? moment(formData?.workExpRequestModels[index]?.startDate).toDate()
                                  : null
                              }
                              onChange={(date) => handleDateChange(date, index, 'start')}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => e.preventDefault()}
                              clearButton
                              customInput={<CustomInputClgWXS index={index} />}
                            />
                          </div>
                          <p
                            className={
                              jobValidate &&
                              formData?.workExpRequestModels[index]?.present === index + 1 &&
                                !formData?.workExpRequestModels[index]?.startDate
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Start Date
                          </p>
                        </div>
                        <div>
                          <div
                            className={style.cu_field23}
                            style={
                              formData?.workExpRequestModels[index]?.present === index + 1
                                ? {
                                  marginTop: "0.25rem",
                                  backgroundColor: "#b2a6a66b",
                                  flex: "0 0 8%",
                                }
                                : { marginTop: "0.25rem", flex: "0 0 5%" }
                            }
                          >
                            <DatePicker
                              placeholder="DD MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              selected={
                                formData?.workExpRequestModels[index]?.endDate
                                  ? moment(formData?.workExpRequestModels[index]?.endDate).toDate()
                                  : null
                              }
                              onChange={(date) => {
                                handleDateChange(date, index, 'end')}}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => e.preventDefault()}
                              customInput={<CustomInputClgWXEND index={index} />}
                            />
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                      <input
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.workExpRequestModels];
                          temp[index].role = e?.target?.value;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                        value={formData?.workExpRequestModels[index]?.role}
                      />
                      <p
                        className={
                          jobValidate &&
                            formData?.workExpRequestModels[index]?.present === index + 1 &&
                            !formData?.workExpRequestModels[index]?.role
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Enter Role
                      </p>
                    </div>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <textarea
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.workExpRequestModels];
                          temp[index].reasonForChange = e?.target?.value;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                        value={formData?.workExpRequestModels[index]?.reasonForChange}
                      />
                      <p
                        className={
                          jobValidate &&
                          formData?.workExpRequestModels[index]?.present === index + 1 &&
                            !formData?.workExpRequestModels[index]?.reasonForChange
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Enter Reason for Change
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={style.workExpButton} style={{ marginTop: "1.3rem", gap: "1rem" }}>
              <button
                className={style.btn_add}
                onClick={() => {
                  setWorkExperienceArray([
                    ...workExperienceArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setFormData({
                    ...formData,
                    workExpRequestModels: [
                      ...formData?.workExpRequestModels,
                      {
                        companyName: "",
                        reasonForChange: "",
                        role: "",
                        endDate: null,
                        startDate: null,
                        present: 0,
                      },
                    ],
                  });
                }}
              >
                Add
              </button>
              <button
                className={style.btn_add}
                onClick={() =>
                  formData?.personalEmail
                    ? experienceMutation.mutate({ emailId: formData?.personalEmail })
                    : notify1("Please Enter Personal Email")
                }
              >
                Autofill
              </button>
            </div>
          </div>
          <div className={style.createposition}>
            <p className={style.title}>Academics</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1} style={{ flex: "0 0 26%" }}>
                <p className={style.cu_label}>Degree</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 30%" }}>
                <p className={style.cu_label}>Board of Education/University</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 10%" }}>
                <p className={style.cu_label}>Passing Year</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                <p className={style.cu_label}>Grade </p>
              </div>
            </div>

            {acadamicsArray?.map((item, index) => {
              return (
                <div className={style.candidateAcademics}>
                  {acadamicsArray?.length >= 1 && (
                    <button
                      className={style.btn_remove}
                      style={{ top: "-30%" }}
                      onClick={() =>
                      // remove last value from array
                      {
                        let temp = [...formData?.educationRequestModels];
                        temp.splice(index, 1);
                        setFormData((prevData) => ({
                          ...prevData,
                          educationRequestModels: temp,
                        }));
                        acadamicsArray.splice(index, 1);
                      }
                      }
                    >
                      ❌
                    </button>
                  )}

                  <div
                    className={style.Cu_row1}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className={style.cu_col1} style={{ flex: "0 0 26%" }}>
                      {console.log(formData?.educationRequestModels)}
                      <CreatableSelect
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        options={getAllDegreesOption}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        value={typeof formData?.educationRequestModels[index]?.degree=='string' ? getAllDegreesOption?.find((item)=>item.label == formData?.educationRequestModels[index]?.degree) : formData?.educationRequestModels[index]?.degree}
                        onChange={(e) => {
                          let temp = [...formData?.educationRequestModels];

                          temp[index].degree = e;
                          setFormData({
                            ...formData,
                            educationRequestModels: temp,
                          });
                          let indexs = getAllDegreesOption.some(
                            (item) => item.label === e.label
                          );
                          !indexs && mutationDegree.mutate({ degree: e.label });
                        }}
                        formatCreateLabel={(userInput) =>
                          `+ Add "${userInput}"`
                        }
                      ></CreatableSelect>
                    </div>
                    <div
                      className={style.cu_col3}
                      style={{ flex: "0 0 30%", marginTop: "0.5rem" }}
                    >
                      <input
                        name="boardEdu"
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.educationRequestModels];
                          temp[index].board = e?.target?.value;
                          setFormData({
                            ...formData,
                            educationRequestModels: temp,
                          });
                        }}
                        value={formData?.educationRequestModels[index]?.board}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      />
                    </div>
                    <div
                      className={style.cu_col2_ac}
                      style={{ flex: "0 0 10%" }}
                    >
                      <div>
                        <div
                          className={style.cu_fieldAC}
                          style={{ marginTop: "0.5rem" }}
                        >
                          <DatePicker
                            placeholder="YYYY"
                            selected={
                              formData?.educationRequestModels[index]
                                ?.passingYear
                                ? moment(formData?.educationRequestModels[index]?.passingYear, 'YYYY').startOf('year').toDate()
                                : null
                            }

                            dateFormat="yyyy"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            // showYearPicker
                            // showYearDropdown
                            showYearPicker
                            dropdownMode="select"
                            onChange={(date) => {
                              let temp = [...formData?.educationRequestModels];
                              temp[index].passingYear =
                                moment(date).format("YYYY");
                              setFormData({
                                ...formData,
                                educationRequestModels: temp,
                              });
                            }}
                            customInput={<CustomInputClgPY index={index} />}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={style.cu_col3}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: "0 0 27%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Select
                          styles={customStyles}
                          classNamePrefix={"create_position"}
                          options={options}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          value={{label : formData?.educationRequestModels[index]?.gradeStatus, value : getGradeStatusVal(formData?.educationRequestModels[index]?.gradeStatus)}}
                          onChange={(e) => {
                            let temp = [...formData?.educationRequestModels];

                            temp[index].gradeStatus = e.label;
                            setFormData({
                              ...formData,
                              educationRequestModels: temp,
                            });
                          }}
                        ></Select>
                      </div>
                      {formData?.educationRequestModels[index]?.gradeStatus ===
                        "CGPA" && (
                          <div style={{ width: "40%" }}>
                            <input
                              name="grade"
                              type="number"
                              min={0}
                              max={10}
                              style={{ marginTop: "0.5rem" }}
                              value={
                                formData?.educationRequestModels[index]?.grade
                              }
                              onChange={(e) => {
                                let temp = [...formData?.educationRequestModels];
                                temp[index].grade = parseFloat(e?.target?.value);
                                setFormData({
                                  ...formData,
                                  educationRequestModels: temp,
                                });
                              }}
                              className={style.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />
                            <p
                              className={
                                formData?.educationRequestModels[index]?.grade >
                                  10
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                              style={{ fontSize: "10px" }}
                            >
                              CGPA should be less than 10
                            </p>
                          </div>
                        )}
                      {formData?.educationRequestModels[index]?.gradeStatus ===
                        "Percentage" && (
                          <div style={{ width: "40%" }}>
                            <input
                              name="grade"
                              type="number"
                              min={0}
                              max={100}
                              style={{ marginTop: "0.5rem" }}
                              value={
                                formData?.educationRequestModels[index]?.grade
                              }
                              onChange={(e) => {
                                let temp = [...formData?.educationRequestModels];
                                temp[index].grade = parseFloat(e?.target?.value);
                                setFormData({
                                  ...formData,
                                  educationRequestModels: temp,
                                });
                              }}
                              className={style.cu_field233}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputClick}
                            />
                            <p
                              className={
                                formData?.educationRequestModels[index]?.grade >
                                  100
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                              style={{ fontSize: "10px" }}
                            >
                              % should be less than 100
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className={style.workExpButton}
              style={{ marginTop: "1.3rem", gap: "1rem" }}
            >
              <button
                className={style.btn_add}
                onClick={
                  () => {
                    setAcadamicsArray([
                      ...acadamicsArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      educationRequestModels: [
                        ...formData?.educationRequestModels,
                        {
                          degree: "",
                          grade: null,
                          board: "",
                        },
                      ],
                    });
                  }
                  // push new value 2 in array
                }
              >
                Add
              </button>
              <button
                className={style.btn_add}
                onClick={() =>
                  formData?.personalEmail
                    ? academicsMutation.mutate({
                      emailId: formData?.personalEmail,
                    })
                    : notify1("Please Enter Personal Email")
                }
              >
                Autofill
              </button>
            </div>
          </div>
          {/* Salary Details */}
          <div className={style.createposition}>
            <p className={style.title}>Salary Breakup</p>
            <p
              className={style.cu_label}
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="unpaidCheckbox"
                name="checkbox"
                value="1"
                checked={unpaid}
                onChange={(e) => handleUnpaidChange()}
                style={{
                  visibility: "visible",
                  height: "20px",
                  width: "25px",
                  cursor: "pointer",
                  accentColor: "#289e98"
                }}
              />
              <label
                for="unpaidCheckbox"
                className={style.cu_label}
                style={{ color: "black" }}
              >
                Is Unpaid
              </label>
            </p>

            {!unpaid && (
              <>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>
                      Basic<span style={{ color: "red" }}>*</span>{" "}
                    </p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={style.cu_field2}
                        type="text"
                        value={formData?.basic}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            basic: e.target.value
                              ? parseFloat(e.target.value)
                              : 0,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                      ></input>
                    </div>
                    <p
                      className={
                        validate &&
                          (formData?.basic === null ||
                            formData?.basic === undefined)
                          ? style.errorText
                          : style.errorTextHidden2
                      }
                    >
                      Please Enter Basic
                    </p>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      HRA<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.hra}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hra: e.target.value ? parseFloat(e.target.value) : 0,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                    <p
                      className={
                        validate &&
                          (formData?.hra === null || formData?.hra === undefined)
                          ? style.errorText
                          : style.errorTextHidden2
                      }
                    >
                      Please Enter HRA
                    </p>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>
                      OA<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.oa}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          oa: e.target.value ? parseFloat(e.target.value) : 0,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                    <p
                      className={
                        validate &&
                          (formData?.oa === null || formData?.oa === undefined)
                          ? style.errorText
                          : style.errorTextHidden2
                      }
                    >
                      Please Enter OA
                    </p>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Conveyance<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.conveyance}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          conveyance: e.target.value
                            ? parseFloat(e.target.value)
                            : 0,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                    <p
                      className={
                        validate &&
                          (formData?.conveyance === null ||
                            formData?.conveyance === undefined)
                          ? style.errorText
                          : style.errorTextHidden2
                      }
                    >
                      Please Enter Conveyance
                    </p>
                  </div>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>
                      {/* Bonus<span style={{ color: "red" }}>*</span>{" "} */}
                      Bonus
                    </p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={style.cu_field2}
                        type="text"
                        value={formData?.bonus}
                        onChange={(e) => {
                          const tempGS = formData?.basic
                            ? parseFloat(formData?.basic)
                            : 0 + formData?.hra
                              ? parseFloat(formData?.hra)
                              : 0 + formData?.oa
                                ? parseFloat(formData?.oa)
                                : 0 + formData?.conveyance
                                  ? parseFloat(formData?.conveyance)
                                  : 0 + formData?.bonus
                                    ? parseFloat(formData?.bonus)
                                    : 0;
                          setFormData({
                            ...formData,
                            bonus: e.target.value
                              ? parseFloat(e.target.value)
                              : 0,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                      ></input>
                    </div>
                    {/* <p
                  className={
                    validate &&
                    (formData?.bonus === null || formData?.bonus === undefined)
                      ? style.errorText
                      : style.errorTextHidden2
                  }
                >
                  Please Enter Bonus
                </p> */}
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Gross Salary</p>
                    <input
                      readOnly={true}
                      className={`${style.cu_field2} ${style.readonly}`}
                      type="text"
                      value={formData?.grossSalary}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          grossSalary: formData?.basic
                            ? parseFloat(formData?.basic)
                            : 0 + formData?.hra
                              ? parseFloat(formData?.hra)
                              : 0 + formData?.oa
                                ? parseFloat(formData?.oa)
                                : 0 + formData?.conveyance
                                  ? parseFloat(formData?.conveyance)
                                  : 0 + formData?.bonus
                                    ? parseFloat(formData?.bonus)
                                    : 0,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      {/* Employer PF<span style={{ color: "red" }}>*</span> */}
                      Employer PF
                    </p>
                    <input
                      className={`${style.cu_field2}`}
                      type="text"
                      value={formData?.employerPF}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          employerPF: e.target.value
                            ? parseFloat(e.target.value)
                            : 0,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                    {/* <p
                  className={
                    validate &&
                    (formData?.employerPF === null ||
                      formData?.employerPF === undefined)
                      ? style.errorText
                      : style.errorTextHidden2
                  }
                >
                  Please Enter Employer PF
                </p> */}
                  </div>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>
                      {/* Employee PF<span style={{ color: "red" }}>*</span>{" "} */}
                      Employee PF
                    </p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={style.cu_field2}
                        type="text"
                        value={formData?.employeePF}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            employeePF: e.target.value
                              ? parseFloat(e.target.value)
                              : 0,
                          })
                        }
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                      ></input>
                    </div>
                    {/* <p
                  className={
                    validate &&
                    (formData?.employeePF === null ||
                      formData?.employeePF === undefined)
                      ? style.errorText
                      : style.errorTextHidden2
                  }
                >
                  Please Enter Employee PF
                </p> */}
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Gratuity</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.gratuity}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          gratuity: e.target.value
                            ? parseFloat(e.target.value)
                            : 0,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1.5rem",
                    borderBottom: "2px solid rgba(8, 8, 8, 0.1)",
                  }}
                ></div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>SA1</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.sa1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sa1: e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(e.target.value) +
                            Number(formData?.sa2) +
                            Number(formData?.incentives) +
                            Number(formData?.variableBonus) +
                            Number(formData?.joiningBonus) +
                            Number(formData?.retentionBonus) +
                            Number(formData?.others),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(e.target.value) +
                              Number(formData?.sa2) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(formData?.others)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>SA2</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.sa2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sa2: e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(formData?.sa1) +
                            Number(e.target.value) +
                            Number(formData?.incentives) +
                            Number(formData?.variableBonus) +
                            Number(formData?.joiningBonus) +
                            Number(formData?.retentionBonus) +
                            Number(formData?.others),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(e.target.value) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(formData?.others)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Incentives</p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={style.cu_field2}
                        type="text"
                        value={formData?.incentives}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            incentives:
                              e.target.value && Number(e.target.value),
                            currentMonthlyFullCTC:
                              Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(formData?.sa2) +
                              Number(e.target.value) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(formData?.others),
                            currentYearlyFullCTC:
                              (Number(formData?.salary) +
                                Number(formData?.sa1) +
                                Number(formData?.sa2) +
                                Number(e.target.value) +
                                Number(formData?.variableBonus) +
                                Number(formData?.joiningBonus) +
                                Number(formData?.retentionBonus) +
                                Number(formData?.others)) *
                              12,
                          })
                        }
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Variable Bonus</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.variableBonus}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          variableBonus:
                            e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(formData?.sa1) +
                            Number(formData?.sa2) +
                            Number(formData?.incentives) +
                            Number(e.target.value) +
                            Number(formData?.joiningBonus) +
                            Number(formData?.retentionBonus) +
                            Number(formData?.others),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(formData?.sa2) +
                              Number(formData?.incentives) +
                              Number(e.target.value) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(formData?.others)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Joining Bonus</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.joiningBonus}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          joiningBonus:
                            e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(formData?.sa1) +
                            Number(formData?.sa2) +
                            Number(formData?.incentives) +
                            Number(formData?.variableBonus) +
                            Number(e.target.value) +
                            Number(formData?.retentionBonus) +
                            Number(formData?.others),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(formData?.sa2) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(e.target.value) +
                              Number(formData?.retentionBonus) +
                              Number(formData?.others)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Retention Bonus</p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={style.cu_field2}
                        type="text"
                        value={formData?.retentionBonus}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            retentionBonus:
                              e.target.value && Number(e.target.value),
                            currentMonthlyFullCTC:
                              Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(formData?.sa2) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(e.target.value) +
                              Number(formData?.others),
                            currentYearlyFullCTC:
                              (Number(formData?.salary) +
                                Number(formData?.sa1) +
                                Number(formData?.sa2) +
                                Number(formData?.incentives) +
                                Number(formData?.variableBonus) +
                                Number(formData?.joiningBonus) +
                                Number(e.target.value) +
                                Number(formData?.others)) *
                              12,
                          })
                        }
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Others</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.others}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          others: e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(formData?.sa1) +
                            Number(formData?.sa2) +
                            Number(formData?.incentives) +
                            Number(formData?.variableBonus) +
                            Number(formData?.joiningBonus) +
                            Number(formData?.retentionBonus) +
                            Number(e.target.value),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(formData?.sa2) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(e.target.value)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <div
                    className={style.cu_col2}
                    style={{ visibility: "hidden" }}
                  >
                    <p className={style.cu_label}>Others</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.others}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          others: e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(formData?.sa1) +
                            Number(formData?.sa2) +
                            Number(formData?.incentives) +
                            Number(formData?.variableBonus) +
                            Number(formData?.joiningBonus) +
                            Number(formData?.retentionBonus) +
                            Number(e.target.value),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(formData?.sa2) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(e.target.value)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                  <div
                    className={style.cu_col3}
                    style={{ visibility: "hidden" }}
                  >
                    {/* <p className={style.cu_label}>Current Monthly Core CTC</p>
                <input
                  className={style.cu_field2}
                  type="text"
                  value={formData?.currentMonthlyCoreCTC}
                  onKeyDown={(e) => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      currentMonthlyCoreCTC: Number(e.target.value),
                    })
                  }
                ></input> */}
                    <p className={style.cu_label}>Current Yearly Core CTC</p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={`${style.cu_field2} ${style.readonly}`}
                        type="text"
                        value={formData?.currentYearlyCoreCTC}
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            currentYearlyCoreCTC:
                              e.target.value && Number(e.target.value),
                          })
                        }
                        readOnly
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1.5rem",
                    borderBottom: "2px solid rgba(8, 8, 8, 0.1)",
                  }}
                ></div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Current Monthly Core CTC</p>
                    <div className={style.cu_row2_col2}>
                      <input
                        readonly
                        className={`${style.cu_field2} ${style.readonly}`}
                        type="text"
                        value={formData?.salary}
                        onChange={(e) => {
                          console.log("hello");
                          setSalaryRangeId(Number(e.target.value))
                        }
                        }
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                      ></input>
                    </div>
                    <p
                      className={
                        validate && !formData?.salary
                          ? style.errorText
                          : style.errorTextHidden2
                      }
                    >
                      Fixed Salary cannot be 0.
                    </p>
                  </div>
                  <div className={style.cu_col2}>
                    {/* <p className={style.cu_label}>Current Monthly Core CTC</p>
                <input
                  className={style.cu_field2}
                  type="text"
                  value={formData?.currentMonthlyCoreCTC}
                  onKeyDown={(e) => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      currentMonthlyCoreCTC: Number(e.target.value),
                    })
                  }
                ></input> */}
                    <p className={style.cu_label}>Current Yearly Core CTC</p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={`${style.cu_field2} ${style.readonly}`}
                        type="text"
                        value={formData?.currentYearlyCoreCTC}
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            currentYearlyCoreCTC:
                              e.target.value && Number(e.target.value),
                          })
                        }
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>Current Monthly Full CTC</p>
                    <input
                      className={`${style.cu_field2} ${style.readonly}`}
                      type="text"
                      value={formData?.currentMonthlyFullCTC}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          currentMonthlyFullCTC:
                            e.target.value && Number(e.target.value),
                        })
                      }
                      readOnly
                    ></input>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "1rem" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Current Yearly Full CTC</p>
                    <input
                      className={`${style.cu_field2} ${style.readonly}`}
                      type="text"
                      value={formData?.currentYearlyFullCTC}
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        console.log("onchange");
                        setSalaryKey(e.target.value && Number(e.target.value));
                      }
                      }
                      readOnly
                    ></input>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Salary (As per Project & Costing)
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.cu_row2_col2}>
                      <input
                        className={style.cu_field2}
                        type="text"
                        value={formData?.salaryAsProjectCosting}
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57) &&
                          e.preventDefault()
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            salaryAsProjectCosting:
                              e.target.value && Number(e.target.value),
                          })
                        }
                      ></input>
                    </div>
                    <p
                      className={
                        validate && !formData?.salaryAsProjectCosting
                          ? style.errorText
                          : style.errorTextHidden2
                      }
                    >
                      Please Enter Salary ( As per Project & Costing)
                    </p>
                  </div>
                  <div
                    className={style.cu_col3}
                    style={{ visibility: "hidden" }}
                  >
                    <p className={style.cu_label}>SA2</p>
                    <input
                      className={style.cu_field2}
                      type="text"
                      value={formData?.sa2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sa2: e.target.value && Number(e.target.value),
                          currentMonthlyFullCTC:
                            Number(formData?.salary) +
                            Number(formData?.sa1) +
                            Number(e.target.value) +
                            Number(formData?.incentives) +
                            Number(formData?.variableBonus) +
                            Number(formData?.joiningBonus) +
                            Number(formData?.retentionBonus) +
                            Number(formData?.others),
                          currentYearlyFullCTC:
                            (Number(formData?.salary) +
                              Number(formData?.sa1) +
                              Number(e.target.value) +
                              Number(formData?.incentives) +
                              Number(formData?.variableBonus) +
                              Number(formData?.joiningBonus) +
                              Number(formData?.retentionBonus) +
                              Number(formData?.others)) *
                            12,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57) &&
                        e.preventDefault()
                      }
                    ></input>
                  </div>
                </div>

                {/* Notes */}
                <div className={style.Cu_notes} style={{ marginTop: "1rem" }}>
                  <p className={style.cu_label}>Salary Note</p>
                  <div>
                    <textarea
                      className={style.notes}
                      type="text"
                      value={formData?.salaryNotes}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          salaryNotes: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={style.cu_button}>
            <button
              style={
                disableSub
                  ? {
                    padding: "0px 10px",
                    background: "gray",
                    color: "#fff",
                    border: "gray",
                    height: "37px",
                    width: "100px",
                    margin: "5px",
                    // marginTop: "15px",
                    // borderRadius: "5px",
                    cursor: "pointer",
                  }
                  : {
                    padding: "0px 10px",
                    background: "#289e98",
                    color: "#fff",
                    border: "1px solid #4fdf4f",
                    height: "37px",
                    width: "100px",
                    // margin: "5px",
                    // marginTop: "15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }
              }
              disabled={disableSub}
              onClick={onCreateHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
