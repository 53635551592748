import React, { useEffect, useState } from "react";
import style from "./canfirmation.module.css";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import moment from "moment";
import SideBar from "../../../sidebar/SideBar";
import right from "../../../../../assets/checkums.png";
import loaderImg from "../../../../../assets/umsLoader.gif";
import { ImageProfileView } from "../../commonComponent/ImageProfileView";
import { path } from "../../../../../routes/PathNames";
import { MdCurrencyRupee } from "react-icons/md";

const Canfirmation = () => {
  const [userDescription, setUserDescription] = useState();
  const [isLoading, setLoading] = useState(false);
  const url = process.env.REACT_APP_UMS_APP_URL;
  const location = useLocation();
  const history = useHistory();
  const [showImageProfile, setShowImageProfile] = useState(false);

  const { id } = location.state || { id: "none" };
  const getApplicationDetails = (id) => {
    setLoading(true);
    axios
      .get(url + `/getUserById?useId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setUserDescription(res.data.responseData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getApplicationDetails(id);
  }, []);
  return (
    <>
      {/*  <Header />*/}
      <div
        className={style.main_client_body}
        onClick={() => {
          // setConfirmModal(!confirmModal)
        }}
      >
        <SideBar />
        {
          <div className={style.main_create}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px " }}
            ></div>
            <p className={style.vieUser}></p>
            {isLoading ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                />
              </div>
            ) : (
              <div className={style.createposition}>
                {userDescription && (
                  <div className={style.inner_card}>
                    <div className={style.vp_header}>
                      <img className={style.right_img} src={right} alt="img" />
                      <p>Employee has been created</p>
                    </div>

                    <div className={style.rowContainer}>
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Type</p>
                        <div className={style.cp_yoe}>
                          <p className={style.cu_label}>
                            {userDescription?.type}
                          </p>
                        </div>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Code</p>
                        <p className={style.cu_label}>
                          {userDescription?.code}
                        </p>
                      </div>
                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>User Name</p>
                        <p className={style.cu_label}>
                          {userDescription?.name}
                        </p>
                      </div>
                    </div>
                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Email</p>
                        <div className={style.cp_yoe}>
                          <p className={style.cu_label}>
                            {userDescription?.email}
                          </p>
                        </div>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Personal Email</p>
                        <div className={style.cp_yoe}>
                          <p className={style.cu_label}>
                            {userDescription?.personalEmail}
                          </p>
                        </div>
                      </div>
                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>Mobile Number</p>
                        <p className={style.cu_label}>
                          {userDescription?.number}
                        </p>
                      </div>
                    </div>
                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Marital Status </p>
                        <p
                          className={style.cp_value}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {userDescription?.maritalStatus}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Gender </p>
                        <p
                          className={style.cp_value}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {userDescription?.gender}
                        </p>
                      </div>
                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>WhatsApp Mobile Number</p>
                        <p className={style.cu_label}>
                          {userDescription?.whatsappNumber}
                        </p>
                      </div>
                    </div>
                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Career Start Date</p>
                        <p className={style.cu_label}>
                          {moment(userDescription?.careerStartDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Total Experience </p>

                        <p className={style.cp_value}>
                          {~~(userDescription?.totalExperience / 12)} Years{" "}
                          {userDescription?.totalExperience % 12} Months
                        </p>
                      </div>
                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>Relevant Experience </p>
                        <p className={style.cp_value}>
                          {~~(userDescription?.relevantExperience / 12)} Years{" "}
                          {userDescription?.relevantExperience % 12} Months
                        </p>
                      </div>
                    </div>

                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Date of Joining</p>
                        <p className={style.cp_value}>
                          {moment(userDescription?.dateOfJoining).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Technology </p>
                        <p className={style.cp_value}>
                          {userDescription?.technology}
                        </p>
                      </div>

                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>Additional Skill</p>
                        <p className={style.cp_value}>
                          {userDescription?.additionalSkill
                            ?.map((e) => e.additionalSkillName)
                            .join(", ")}
                        </p>
                      </div>
                    </div>
                    {
                      <div
                        className={style.rowContainer}
                        style={{ marginTop: "15px" }}
                      >
                        <div className={style.cp_col1}>
                          <p className={style.cp_label}>Role</p>
                          <p className={style.cp_value}>
                            {userDescription?.employeeDesignation}
                          </p>
                        </div>
                        <div className={style.cp_col2}>
                          <p className={style.cp_label}>Salary Range</p>
                          <p className={style.cp_value}>
                            {userDescription?.salaryRangeName}
                          </p>
                        </div>

                        <div className={style.cp_col3}>
                          <p className={style.cp_label}>Functional Area</p>
                          <p className={style.cp_value}>
                            {userDescription?.functionalAreaName}
                          </p>
                        </div>
                      </div>
                    }
                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Bond</p>
                        <p className={style.cp_value}>
                          {userDescription?.bond ? "Yes" : "No"}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Bond End Date </p>
                        <p className={style.cp_value}>
                          {userDescription?.bondDate
                            ? moment(userDescription?.bondDate).format(
                                "DD MMM YY"
                              )
                            : null}
                        </p>
                      </div>

                      <div className={style.cp_col3}>
                        {userDescription?.uploadImageUrl && (
                          <img
                            id="image"
                            src={userDescription?.uploadImageUrl}
                            alt="dd"
                            style={{
                              borderRadius: "50%",
                              width: "3.5rem",
                              height: "3.5rem",
                              border: "1px solid black",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowImageProfile(true)}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Date of Birth</p>
                        <p className={style.cp_value}>
                          {moment(userDescription?.dob).format("DD MMM YY")}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Marriage Anniversary</p>
                        <p className={style.cp_value}>
                          {userDescription?.marriageAnniversary
                            ? moment(
                                userDescription?.marriageAnniversary
                              ).format("DD MMM YY")
                            : null}
                        </p>
                      </div>

                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>LinkedIn URL</p>

                        <a
                          href={userDescription?.linkedInUrl}
                          target="_black"
                          rel="noreferrer"
                          title="Click here"
                          className={style.cp_value}
                        >
                          {userDescription?.linkedInUrl?.length > 30
                            ? `${userDescription?.linkedInUrl?.slice(0, 30)}...`
                            : userDescription?.linkedInUrl}
                        </a>
                      </div>
                    </div>

                    <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Folder URL</p>

                        <a
                          href={userDescription?.folderUrl}
                          target="_black"
                          rel="noreferrer"
                          title="Click here"
                          className={style.cp_value}
                        >
                          {userDescription?.folderUrl?.length > 30
                            ? `${userDescription?.folderUrl?.slice(0, 30)}...`
                            : userDescription?.folderUrl}
                        </a>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>BGV Applicable</p>
                        <p className={style.cp_value}>
                          {userDescription?.isBGVeligible ? "Yes" : "No"}
                        </p>
                      </div>

                      <div className={style.cp_col3}>
                        {" "}
                        <p className={style.cp_label}>PF Applicable</p>
                        <p className={style.cp_value}>
                          {userDescription?.pfApplicable ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    {/* <div
                      className={style.rowContainer}
                      style={{ marginTop: "15px" }}
                    >
                      <div className={style.cp_col1}>
                      <p className={style.cp_label}>Salary ( As on Date) </p>
                        <p className={style.cp_value} style={{display:"flex",alignItems:"center"}}>
                        <MdCurrencyRupee />{userDescription?.salary}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                      <p className={style.cp_label}>Salary (As per Project & Costing ) </p>
                        <p className={style.cp_value} style={{display:"flex",alignItems:"center"}}>
                        <MdCurrencyRupee />{userDescription?.reviseSalaryAsProjectCosting}
                        </p>
                      </div>

                      <div className={style.cp_col3}>
                        
                      </div>
                    </div> */}
                    <ImageProfileView
                      show={showImageProfile}
                      image={userDescription?.uploadImageUrl}
                      okaybtn={() => setShowImageProfile(false)}
                    ></ImageProfileView>
                    <div className={style.rowContainer} style={{ marginTop: "5px" }}>
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Location</p>
                        <p className={style.cp_value}>
                          {userDescription?.location}
                        </p>
                      </div>
                      <div className={style.cp_col2}>
                        <p className={style.cp_label}>Emergency Contact Name</p>
                        <p className={style.cp_value}>
                          {userDescription?.emergencyContactName}
                        </p>
                      </div>
                      <div className={style.cp_col3}>
                        <p className={style.cp_label}>Emergency Contact Number</p>
                        <p className={style.cp_value}>
                          {userDescription?.emergencyContactNumber}
                        </p>
                      </div>
                    </div>
                    <div className={style.rowContainer} style={{ marginTop: "5px" }}>
                      <div className={style.cp_col1}>
                        <p className={style.cp_label}>Note</p>
                        <p className={style.cp_value}>
                          {userDescription?.note}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*Experience*/}
            <div
              className={style.createposition}
              style={{ marginBottom: "2rem" }}
            >
              <p className={style.title}>Work Experience</p>
              <div className={style.Cu_row1} style={{ paddingBottom: "1rem" }}>
                <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                  <p className={style.cp_label}>Company Name</p>
                </div>
                <div className={style.cu_col2_to} style={{ flex: "0 0 17%" }}>
                  {
                    <div className={style.multiinput}>
                      <div
                        className={style.cp_label}
                        style={{ flex: "0 0 55%" }}
                      >
                        From
                      </div>
                      <div
                        className={style.cp_label}
                        style={{ flex: "0 0 55%" }}
                      >
                        To
                      </div>
                    </div>
                  }
                </div>
                <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                  <p className={style.cp_label}>Dur</p>
                </div>
                <div className={style.cu_col3} style={{ flex: "0 0 19%" }}>
                  <p className={style.cp_label}>Role</p>
                </div>

                <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                  <p className={style.cp_label}>Reason For Change</p>
                </div>
              </div>
              {userDescription?.workExpResponseModels?.map((item, index) => {
                return (
                  <div key={item?.id}>
                    <div className={style.Cu_row1}>
                      <div
                        className={style.cu_col1}
                        style={{ flex: "0 0 23%" }}
                      >
                        <p className={style.cu_field2}>{item?.companyName}</p>
                      </div>
                      <div
                        className={style.cu_col2}
                        style={{ flex: "0 0 17%" }}
                      >
                        <div
                          className={style.multiinput}
                          style={{ width: "inherit" }}
                        >
                          <div
                            className={style.cu_field2}
                            style={{ flex: "0 0 55%" }}
                          >
                            <p className={style.cu_field2}>
                              {item?.startDate &&
                                moment(item?.startDate).format("MMM YYYY")}
                            </p>
                          </div>

                          <div
                            className={style.cu_field23}
                            style={{ flex: "0 0 55%" }}
                          >
                            <p className={style.cu_field2}>
                              {item?.endDate &&
                                moment(item?.endDate).format("MMM YYYY")}
                              {item?.presentlyWorking && "Till Now"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                        <p className={style.cu_field2}>
                          {(function () {
                            let year1 = Math.floor(item.expDurationYear);
                            let month = item.expDurationMonth;
                            let year = year1 > 0 ? year1 + " Y  " : "";
                            month =
                              year1 > 0 && month > 0
                                ? ` - ${month}  M `
                                : month > 0
                                ? `${month}  M `
                                : "";
                            return `   ${year}  ${month}`;
                          })()}
                        </p>
                      </div>
                      <div
                        className={style.cu_col3}
                        style={{ flex: "0 0 19%" }}
                      >
                        <p className={style.cu_field2}>{item?.role}</p>
                      </div>
                      <div
                        className={style.cu_col1Reasone}
                        style={{ flex: "0 0 27%" }}
                      >
                        <p className={style.cu_fieldReasone}>
                          {item?.reasonForChange}
                        </p>
                      </div>
                    </div>
                    {/* Second */}
                    <div className={style.Cu_row11Reasone}>
                      {/* <div className={style.cu_col1Reasone}>
                    <p className={style.cu_label}>Reason For Change</p>
                    <p className={style.cu_fieldReasone}>
                      {item?.reasonForChange}
                    </p>
                            </div>*/}
                      {/* <div className={style.cu_col2}></div>
                  <div className={style.cu_col4}></div> */}
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
            <div
              className={style.createposition}
              style={{ marginBottom: "1rem" }}
            >
              <p className={style.title}>Academics</p>
              <div className={style.Cu_row1} style={{ paddingBottom: "1rem" }}>
                <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                  <p className={style.cp_label}>Degree</p>
                </div>
                <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                  <p className={style.cp_label}>Passing Year</p>
                </div>
                <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                  <p className={style.cp_label}>Grade</p>
                </div>
                <div className={style.cu_col3} style={{ flex: "0 0 35%" }}>
                  <p className={style.cp_label}>
                    Board of Education/University
                  </p>
                </div>
              </div>
              {userDescription?.educationResponseModels?.map((item, index) => {
                return (
                  <div key={item?.id}>
                    <div className={style.Cu_row1}>
                      <div
                        className={style.cu_col1}
                        style={{ flex: "0 0 23%" }}
                      >
                        <p className={style.cp_value}>{item?.degree}</p>
                      </div>
                      <div
                        className={style.cu_col2}
                        style={{ flex: "0 0 20%" }}
                      >
                        <p className={style.cp_value}>{item?.passingYear}</p>
                      </div>
                      <div
                        className={style.cu_col2}
                        style={{ flex: "0 0 15%" }}
                      >
                        <p className={style.cp_value}>
                          {item?.grade}
                          {item?.gradeStatus === "CGPA" && " CGPA"}
                          {item?.gradeStatus === "Percentage" && "%"}
                        </p>
                      </div>
                      <div
                        className={style.cu_col3}
                        style={{ flex: "0 0 35%" }}
                      >
                        <p className={style.cp_value}>{item?.board}</p>
                      </div>
                    </div>
                    {/* Second */}
                    <div
                      className={style.Cu_row1}
                      style={{ marginTop: "30px" }}
                    ></div>
                    <hr />
                  </div>
                );
              })}
            </div>
            <div
              className={style.createposition}
              style={{ marginBottom: "1rem" }}
            >
              <p className={style.title}>Salary Breakup</p>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                  <p className={style.cp_label}>Basic</p>
                  <p className={style.cp_value}>{userDescription?.basic}</p>
                </div>
                <div className={style.cp_col2}>
                  <p className={style.cp_label}>HRA</p>
                  <p className={style.cp_value}>{userDescription?.hra}</p>
                </div>
                <div className={style.cp_col3}>
                  <p className={style.cp_label}>OA</p>
                  <p className={style.cp_value}>{userDescription?.oa}</p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                <p className={style.cp_label}>Conveyance</p>
                  <p className={style.cp_value}>
                    {userDescription?.conveyance}
                  </p>
                </div>
                <div className={style.cp_col2}>
                <p className={style.cp_label}>Bonus</p>
                  <p className={style.cp_value}>{userDescription?.bonus}</p>
                </div>
                <div className={style.cp_col3}>
                  <p className={style.cp_label}>Gross Salary</p>
                  <p className={style.cp_value}>
                    {userDescription?.grossSalary}
                  </p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                <p className={style.cp_label}>Employer PF</p>
                  <p className={style.cp_value}>
                    {userDescription?.employerPF}
                  </p>
                </div>
                <div className={style.cp_col2}>
                <p className={style.cp_label}>Employee PF</p>
                  <p className={style.cp_value}>
                    {userDescription?.employeePF}
                  </p>
                </div>
                <div className={style.cp_col3}>
                <p className={style.cp_label}>Gratuity</p>
                  <p className={style.cp_value}>
                    {userDescription?.gratuity}
                  </p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                <p className={style.cp_label}>SA1</p>
                  <p className={style.cp_value}>{userDescription?.sa1}</p>
                </div>
                <div className={style.cp_col2}>
                <p className={style.cp_label}>SA2</p>
                  <p className={style.cp_value}>{userDescription?.sa2}</p>
                </div>
                <div className={style.cp_col3}>
                <p className={style.cp_label}>Incentives</p>
                  <p className={style.cp_value}>
                    {userDescription?.incentives}
                  </p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                <p className={style.cp_label}>Variable Bonus</p>
                  <p className={style.cp_value}>
                    {userDescription?.variableBonus}
                  </p>
                </div>
                <div className={style.cp_col2}>
                <p className={style.cp_label}>Joining Bonus</p>
                  <p className={style.cp_value}>
                    {userDescription?.joiningBonus}
                  </p>
                </div>
                <div className={style.cp_col3}>
                <p className={style.cp_label}>Retention Bonus</p>
                  <p className={style.cp_value}>
                    {userDescription?.retentionBonus}
                  </p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                <p className={style.cp_label}>Others</p>
                  <p className={style.cp_value}>{userDescription?.others}</p>
                </div>
                <div className={style.cp_col2}>
                <p className={style.cp_label}>Current Monthly Core CTC</p>
                  <p className={style.cp_value}>{userDescription?.salary}</p>
                </div>
                <div className={style.cp_col3}>
                  <p className={style.cp_label}>Current Yearly Core CTC</p>
                  <p className={style.cp_value}>
                    {userDescription?.currentYearlyCoreCTC}
                  </p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                  <p className={style.cp_label}>Current Monthly Full CTC</p>
                  <p className={style.cp_value}>
                    {userDescription?.currentMonthlyFullCTC}
                  </p>
                </div>
                <div className={style.cp_col2}>
                  <p className={style.cp_label}>Current Yearly Full CTC</p>
                  <p className={style.cp_value}>
                    {userDescription?.currentYearlyFullCTC}
                  </p>
                </div>
                <div className={style.cp_col3}>
                  <p className={style.cp_label}>
                    Salary (As per Project & Costing)
                  </p>
                  <p className={style.cp_value}>
                    {userDescription?.reviseSalaryAsProjectCosting}
                  </p>
                </div>
              </div>
              <div className={style.rowContainer} style={{ marginTop: "15px" }}>
                <div className={style.cp_col1}>
                <p className={style.cp_label}>Salary Note</p>
                  <p className={style.cp_value}>
                    {userDescription?.salaryNotes}
                  </p>
                </div>
                <div className={style.cp_col2}>
                  
                </div>
                <div className={style.cp_col3}>
                
                </div>
              </div>
            </div>
            {userDescription ? (
              <div className={style.buttonD} style={{ marginBottom: "1rem" }}>
                <button
                  className={style.edit_btn}
                  onClick={() => history.push(path.ums_userList)}
                >
                  Done
                </button>
              </div>
            ) : null}
          </div>
        }
      </div>
    </>
  );
};

export default Canfirmation;
